import React, { useState } from "react";
import { useFormik } from "formik";
import {
  ADToBSConvert,
  EngNowDate,
  FormatDate,
  GetMonthNumber,
  GetYearNumber,
} from "../widgets/DateFormat";
import { postDataToTheServer } from "../widgets/api";
import AppURL from "../widgets/AppURL";
import { useDispatch, useSelector } from "react-redux";
import {
  BillingListView,
  ContraVoucherInfo,
  ContraVoucherLedgerList,
} from "../store/accountSlice";
import {
  ToastError,
  ToastSuccess,
  ToastWarning,
} from "../widgets/ToastMessage";
export const VoucherContext = React.createContext();
const VoucherEntryContext = ({ children }) => {
  const [voucherData, setOnVoucherData] = useState({
    bill_print: "",
    single_entry: "",
  });

  const [isLoading, setOnLoading] = useState(false);
  const EnTodayDate = EngNowDate();
  const dispatch = useDispatch();
  const { menu_list } = useSelector((state) => state?.software);
  const FiscalYearDate = menu_list?.current_fiscal?.from_date_n;
  const bill_no = localStorage.getItem("bill_no");
  const initialValues = {
    date_en: EnTodayDate || "",
    fiscal_year_date: FiscalYearDate || "",
    date_ne: "",
    debitamt: "",
    creditamt: "",
    program: "",
    year_semester: "",
    acdmc_year: "",
    user_no: 1,
    chequeno: "",
    bearername: "",
    bank_type: 1,
    bank_dep_ret: "",
    billing_no: 1,
    voucher_type: "1",
    trancomment: "",
    single_entry: "",
    ledger_start_date: FiscalYearDate || "",
    ledger_end_date: menu_list?.np_date || "",
    view_std_leader: false,
    view_full_leader: false,
    bill_print: voucherData?.bill_print || "",
    glid: "",
    com_ments: "",
    gl_type: "",
    is_bank_debit: "",
    cash_type_is_debit: "",
    sglsn: "",
    member_sn: "",
    bill_print_date: "",
    particularid: "",
    particular_name: "",
    cash_type: "2",
    voucher_no: "",
    voucher_no_1: "",
    trans_counter_code: "",
    voucher_kind: "0",
    depositmonth: "",
    bank_list_id: "",
    library_fine_amt: "0"
  };
  const { values, handleChange, errors, touched, handleBlur, handleSubmit, setFieldValue, isSubmitting } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values, action) => {

        try {
          const formData = new FormData();
          formData.append("date_en", FormatDate(values.date_en));
          formData.append("fiscal_start", values?.fiscal_year_date);
          formData.append("date_ne", ADToBSConvert(values.date_en));
          formData.append("clr_date_ne", ADToBSConvert(values.date_en));
          formData.append("user_id", 1);
          formData.append("trancomment", values.trancomment || "--");
          formData.append("voucher_kind", values.voucher_kind || "0");
          formData.append("voucher_type", values?.voucher_type || "1");
          formData.append("library_fine_amt", values?.library_fine_amt || "0");

          formData.append("com_ments", values.com_ments || "");
          formData.append(
            "particularname",
            values?.particularname || ""
          );
          formData.append("bill_print", parseInt(values?.bill_print) === 1 ? 1 : 0);
          formData.append(
            "cash_type",
            values?.cash_type ||
            values?.bank_dep_ret ||
            0
          );
          formData.append(
            "bank_list_id",
            values?.bank_list_id || 18
          );
          formData.append(
            "program",
            values.program !== null && values?.member_sn
              ? values.program
              : 0
          );
          formData.append(
            "continue_bill",
            localStorage.getItem("bill_no") !== null &&
              parseInt(values?.bill_print) === 1
              ? bill_no
              : 0
          );
          formData.append(
            "year_semester",
            values?.year_semester !== null &&
              values?.member_sn
              ? values?.year_semester
              : 0
          );
          formData.append(
            "acdmc_year",
            values?.acdmc_year !== null &&
              values?.member_sn
              ? values?.acdmc_year
              : 0
          );
          formData.append("member_sn", values?.member_sn || 1);
          formData.append("sglsn", values?.sglsn || "");
          formData.append(
            "particularid",
            values?.particularid || ""
          );
          formData.append(
            "particular_name",
            values?.particular_name || ""
          );
          formData.append("chequeno", values.chequeno || 0);
          formData.append("bearername", values.bearername || "0");
          formData.append(
            "depositmonth",
            GetMonthNumber(ADToBSConvert(values.date_en))
          );
          formData.append(
            "deposityear",
            GetYearNumber(ADToBSConvert(values.date_en))
          );
          formData.append("debitamt", values.debitamt || 0);
          formData.append("creditamt", values.creditamt || 0);
          if (values?.voucher_type === "1") {
            handleSingleVoucherType(values, formData);
          } else {
            handleContraVoucherType(values, formData);
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      },
    });

  const handleSingleVoucherType = async (values, formData) => {
    const creditamt = Number(values?.creditamt);
    const debitamt = Number(values?.debitamt);
    if (creditamt === 0 && debitamt === 0) {
      ToastWarning("Please enter either a debit or credit amount");
      return;
    }
    if (voucherData?.single_entry !== 1) {
      // Check if either cash_type or bank_dep_ret is selected
      if (
        !values?.cash_type &&
        !values?.bank_dep_ret
      ) {
        ToastWarning("Please Select Cash Or Bank");
        return;
      }
      if (
        (values?.cash_type_is_debit ??
          values?.is_bank_debit) ===
        values?.is_debit
      ) {
        ToastWarning("Please Check Debit Credit");
        return;
      }
    }
    setOnLoading(true);
    setFieldValue("bill_print_date", values.date_ne);
    try {
      const res = await postDataToTheServer(
        `${AppURL.AccountsURL}account-single-voucher-create-list-view/`,
        formData
      );
      dispatch(BillingListView(res));
      if (res && res?.message) {
        setOnLoading(false);
        ToastSuccess(res?.message);
        resetFormFields();
        saveBillNo(res?.bill_no);
      } else {
        setOnLoading(false);
        ToastError("Invalid server response");
      }
    } catch (error) {
      setOnLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const handleContraVoucherType = (values, formData) => {
    // Ensure creditamt and debitamt are numbers for proper comparison
    const creditamt = Number(values?.creditamt);
    const debitamt = Number(values?.debitamt);

    // Validate creditamt and debitamt
    if (creditamt === 0 && debitamt === 0) {
      ToastWarning("Please enter either a debit or credit amount");
      return;
    }

    try {
      dispatch(
        ContraVoucherInfo({
          date_en: FormatDate(values.date_en),
          date_ne: ADToBSConvert(values.date_en),
          fiscal_start: values?.fiscal_year_date,
          year_semester: values?.year_semester,
          acdmc_year: values?.acdmc_year,
          program: values?.program,
          com_ments: values?.com_ments,
          voucher_type: 2,
          user_id: 1,
          voucher_kind: values.voucher_kind || "0",
          bill_print: values?.bill_print === 1 ? 1 : 0,
          member_sn: values?.member_sn || 1,
        })
      );
      dispatch(ContraVoucherLedgerList(Object.fromEntries(formData)));
      resetFormFields();
    } catch (error) {
      console.log(error);
    }
  };

  const resetFormFields = () => {
    handleChange("debitamt")("");
    handleChange("creditamt")("");
    handleChange("chequeno")("");
    handleChange("bearername")("");
    handleChange("trancomment")("");
    handleChange("com_ments")("");
    handleChange("cash_type")("2");
    handleChange("bank_dep_ret")("");
    handleChange("bank_list_id")("");
    handleChange("library_fine_amt")("0");
  };

  const saveBillNo = (bill_no) => {
    if (bill_no !== undefined && bill_no !== null) {
      localStorage.setItem("bill_no", bill_no);
    } else {
      console.error("bill_no is undefined or null");
    }
  };

  return (
    <div>
      <VoucherContext.Provider
        value={{
          voucherData,
          setOnVoucherData,
          isSubmitting,
          initialValues,
          values,
          handleChange,
          errors,
          isLoading,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue
        }}
      >
        {children}
      </VoucherContext.Provider>
    </div>
  );
};

export default VoucherEntryContext;
