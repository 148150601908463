import { Grid } from '@mui/material';
import React, { Fragment, useState } from 'react';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { useFormik } from 'formik';
import { useLanguage } from '../../../../widgets/LangContext';
import ReportHeading from '../../../../widgets/ReportHeading';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { SidebarReportClose } from '../../../../store/softSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AcademicsYearOptions, ProgramOptions } from '../../../../widgets/Options';
import { useNavigate } from 'react-router-dom';
import { postDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { PassedOutListTranscriptListView } from '../../../../store/emis_rptSlice';

const PassedListTransacriptFormView = () => {
    const { language } = useLanguage();
    const dispatch = useDispatch();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }
    const navigate = useNavigate();
    const { emis_rpt_options_list } = useSelector((state) => state.emis_rpt);
    const [selectValues, setOnSelectValues] = useState({
        program: "",
        acdmc_year: "",

    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };
    const ProgramOption = ProgramOptions(emis_rpt_options_list?.program);
    const AcademicsYearOption = AcademicsYearOptions(emis_rpt_options_list?.acdmc_year);
    const initialValues = {
        program: "",
        acdmc_year: "",
    };
    const { errors, touched, handleBlur, handleChange, isSubmitting, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append('program', selectValues?.program?.value);
                formData.append('acdmc_year', selectValues?.acdmc_year?.value);
                await postDataToTheServer(AppURL.EMISRPT + "emis-passout-list-transcript-report-create-list-view/", formData).then((res) => {
                    dispatch(PassedOutListTranscriptListView(res));
                    CloseDispatch();
                    navigate("/emis-list-transcript-of-report-page-list-view");
                  });
            }
        });
    return (
        <Fragment>
            <ReportHeading heading="Passout List / Transcript" />
            <form onSubmit={handleSubmit} className='mt-5'>
                <Grid container rowSpacing={1}>
                    <Grid item xl={12} xs={12} md={12} sm={12} lg={12}>
                        <SelectOptionBox label={language ? "Program" : "स्तर"} value={selectValues.program} options={ProgramOption} name="program" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.program} errors={errors.program} required={true} />
                    </Grid>
                    <Grid item xl={12} xs={12} md={12} sm={12} lg={12}>
                        <SelectOptionBox label={language ? "Academics Year" : "शैक्षिक वर्ष"} value={selectValues.acdmc_year} options={AcademicsYearOption} name="acdmc_year" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.acdmc_year} errors={errors.acdmc_year} required={true} />
                    </Grid>
                </Grid>
                <div className='flex justify-between mt-4'>
                    <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn isSubmitting={isSubmitting} type="submit" />
                </div>
            </form>
        </Fragment>
    )
}

export default PassedListTransacriptFormView



