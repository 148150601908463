import React, { Fragment } from 'react'
import { Atom } from 'react-loading-indicators'

const Loading = ({ isSubmitting }) => {
    return (
        <Fragment>
            <div
                className={`fixed inset-0 z-[99999] bg-white dark:bg-slate900 bg-opacity-90 transition-opacity ${isSubmitting ? "opacity-100 visible" : "opacity-0 invisible"
                    } flex items-center justify-center`}
            >
                <div className="font-public-sans text-slate-200 flex gap-2">
                    <Atom color="#0480b9" size="small" text="" textColor="" />
                    <p className="text-sm left-[80px] text-slate700 my-auto">Please Wait...</p>
                </div>
            </div>
        </Fragment>
    )
}

export default Loading