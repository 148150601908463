import React from 'react'
import { NepaliInput } from 'nepali-input-react';

const NepaliInputTextBox = ({ name,
  label,
  value,
  onCustomValueChange,
  touched, handleBlur,
  errors, required, placeholder }) => {
  const handleNepaliInputChange = (event) => {
    const newValue = event.target.value;
    if (onCustomValueChange) {
      onCustomValueChange(name, newValue);
    }
  };
  return (
    <div className='block'>
      {label ? <div className='flex flex-row'><label className="block text-[12px] font-medium mb-0.5 text-gray700 dark:text-gray-200 font-public-sans" htmlFor="username">{label}</label> {required ? <span className='text-red-500 pl-2'>*</span> : ""}</div> : ""}
      <NepaliInput placeholder={placeholder} name={name}
        value={value} onBlur={handleBlur}
        onChange={handleNepaliInputChange} className={`py-1 px-3 placeholder:text-[11.5px] placeholder:select-none placeholder:text-gray-400 text-[13px] rounded-[3px] mt-[2px] ring-[1px] ${errors ||(errors && touched) ? "ring-red-600" : "ring-gray-400"} font-public-sans w-full focus:outline-0 focus:ring-[2px] focus:ring-green-600`} required={required} />
      {errors && <div className='text-red-500 text-[12px] mt-0.5 font-public-sans'>{errors}</div>}
    </div>
  )
}

export default NepaliInputTextBox