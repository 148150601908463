// ThemeSelectBox.js
export const SelectErrorTheme = (theme) => ({
    ...theme,
    
    colors: {
      ...theme.colors,
      primary25: '#FFC7CE', // Lighter background color for the error state
      primary: '#FF5A5F', // Border color for the error state
    },
  });
  
    
    export const SelectSuccessTheme = (theme) => ({
      ...theme,
      border:"2px solid red",
      colors: {
        ...theme.colors,
        primary25: '#b7dced',
        primary: '#128cc6',
      },
    });
    