import { createSlice } from '@reduxjs/toolkit';

export const emis_rptSlice = createSlice({
    name: 'emis_rpt',
    initialState: {
        emis_rpt_options_list: {},
        program_wise_year_sem_list: {},
        program_wise_year_sem_major_list: {},
        annual_year_wise_student_report: {},
        annual_year_wise_student_report_option_list: {},
        level_and_faculty_wise_program: {},
        drop_out_report_list_view: {},
        emis_inrollment_list_view: {}
    },
    reducers: {
        EMIS_RPT_Options_List: (state, action) => {
            state.emis_rpt_options_list = action.payload;
        },
        ProgramWiseYearSemesterList: (state, action) => {
            state.program_wise_year_sem_list = action.payload;
        },
        ProgramWiseYearSemesterListMajorSubject: (state, action) => {
            state.program_wise_year_sem_major_list = action.payload;
        },
        AnnualYearWiseStudentReportList: (state, action) => {
            state.annual_year_wise_student_report = action.payload;
        },
        AnnualYearWiseStudentReportOptionsList: (state, action) => {
            state.annual_year_wise_student_report_option_list = action.payload;
        },
        LevelAndFacultyWiseProgramSearchList: (state, action) => {
            state.level_and_faculty_wise_program = action.payload;
        },
        DropOutReportSearchList: (state, action) => {
            state.drop_out_report_list_view = action.payload;
        },
        PassedOutListTranscriptListView: (state, action) => {
            state.passed_out_list_transcript_view = action.payload;
        },
        EMISPasssoutReportListView: (state, action) => {
            state.passed_out_list_rpt_view = action.payload;
        },
        EMISReportInrollmentListView: (state, action) => {
            state.emis_inrollment_list_view = action.payload;
        },
        EMISMarksLedgerListView: (state, action) => {
            state.emis_marks_ledger_list_view = action.payload;
        },
        EMISExamResultsAnalysisReportListView: (state, action) => {
            state.exam_results_analysis_report_list_view = action.payload;
        }
    }
});

export const { EMIS_RPT_Options_List, EMISPasssoutReportListView, EMISExamResultsAnalysisReportListView, EMISMarksLedgerListView, EMISReportInrollmentListView, PassedOutListTranscriptListView, DropOutReportSearchList, AnnualYearWiseStudentReportOptionsList, LevelAndFacultyWiseProgramSearchList, AnnualYearWiseStudentReportList, ProgramWiseYearSemesterListMajorSubject, ProgramWiseYearSemesterList } = emis_rptSlice.actions
export default emis_rptSlice.reducer