import React, { Fragment, useEffect } from "react";
import ResultsAnalysisFormView from "../../../../components/exam/report/results_analysis/ResultsAnalysisFormView";
import { useDispatch } from "react-redux";
import { fetchDataFromApi } from "../../../../widgets/api";
import AppURL from "../../../../widgets/AppURL";
import { EMIS_RPT_Options_List } from "../../../../store/emis_rptSlice";

const ResultsAnalysisReportFormView = () => {
  const dispatch = useDispatch();
  const EMISReportListView = async () => {
    try {
      await fetchDataFromApi(
        AppURL.EMISRPT + "emis-rpt-options-serach-list-view/"
      ).then((res) => {
        dispatch(EMIS_RPT_Options_List(res));
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    EMISReportListView();
  }, []);
  return (
    <Fragment>
      <ResultsAnalysisFormView />
    </Fragment>
  );
};

export default ResultsAnalysisReportFormView;
