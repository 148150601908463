import { createSlice } from '@reduxjs/toolkit';

export const softSlice = createSlice({
    name: 'software',
    initialState: {
        title: {},
        generalInfo: {},
        menu_list: {},
        sidebarclose:{},

    },
    reducers: {
        pageTitle: (state, action) => {
            state.title = action.payload;
          },
        SoftGeneralInformation: (state, action) => {
            state.generalInfo = action.payload;
          },
        SidebarMenuList:(state, action)=>{
            state.menu_list = action.payload;
        },
        SidebarReportClose:(state, action)=>{
            state.sidebarclose = action.payload;
        },
    }
});

// Action creators are generated for each case reducer function
export const { pageTitle, SidebarReportClose, SoftGeneralInformation, SidebarMenuList } = softSlice.actions
export default softSlice.reducer