import * as Yup from 'yup';

export const StudentDetailsValidate = Yup.object().shape({
    first_name: Yup.string()
        .min(2, "First name must be at least 2 characters")
        .max(55, "First name must be at most 55 characters")
        .required("Enter First Name"),
    middle_name: Yup.string()
        .min(2, "Middle name must be at least 2 characters")
        .max(55, "Middle name must be at most 55 characters")
        .notRequired(),
    last_name: Yup.string()
        .min(2, "Last name must be at least 2 characters")
        .max(55, "Last name must be at most 55 characters")
        .required("Please enter Last Name"),
    dobn: Yup.string()
        .required("Please enter DOB (BS)"),
    name_nep: Yup.string()
        .required("Please enter Nepali Name"),
    per_add: Yup.string()
        .required("Please enter Address"),
    mother_qualification: Yup.string()
        .required("Please select Mother's Qualification"),
    father_qualification: Yup.string()
        .required("Please select Father's Qualification"),
    wardno: Yup.string()
        .required("Please enter Ward No"),
    gender: Yup.string()
        .required("Please enter Gender"),
    marital_status: Yup.string()
        .required("Please enter Marital Status"),
    father_name: Yup.string()
        .min(2, "Father's name must be at least 2 characters")
        .max(60, "Father's name must be at most 60 characters")
        .required("Please enter Father's Name"),
    mother_name: Yup.string()
        .min(2, "Mother's name must be at least 2 characters")
        .max(60, "Mother's name must be at most 60 characters")
        .required("Please enter Mother's Name"),
    // sms_mob_no: Yup.string()
    //     .matches(/^\d{10}$/, "Phone number must be a 10-digit number")
    //     .required("Please enter a Phone Number"),
    phone: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be a 10-digit number")
        .required("Please enter a Phone Number"),
    nationality: Yup.string()
    .min(2, "Nationality must be at least 2 characters")
    .max(30, "Nationality must be at most 30 characters")
    .required("Please enter Nationality"),
    province: Yup.string()
    .required("Please select province"),
    district: Yup.string()
    .required("Please select District"),
    municipality: Yup.string()
    .required("Please select Municipality"),
    caste: Yup.string()
    .required("Please select Ethnicity"),
    sub_caste: Yup.string()
    .required("Please select Caste"),
    religion: Yup.string()
    .required("Please select Religion"),
});

