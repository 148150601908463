import React from 'react';

const Container = ({children}) => {
  return (
    <div className='bg-white p-3 rounded-md border border-slate300 dark:bg-slate800 dark:border-slate700'>
      {children}
    </div>
  )
}

export default Container
