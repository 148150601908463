import React from 'react';
import ReportHeading from '../../../../widgets/ReportHeading';
import { Grid } from "@mui/material";
import { useFormik } from 'formik';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import InputNumber from '../../../../widgets/InputNumberBox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LibraryIssueReturnReport } from '../../../../store/librarySlice';
import { SidebarReportClose } from '../../../../store/softSlice';
import { BSToADUSNPFormat } from '../../../../widgets/DateFormat';
import { ToastWarning } from '../../../../widgets/ToastMessage';
const IssueReturnForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }
    const initialValues = {
        from_date: "",
        to_date: "",
        radio_button: "1",
    }

    const { values, handleChange, handleSubmit } = useFormik({
        initialValues,
        onSubmit: async (values, action) => {
            let url = `${AppURL.LibraryURL + "library-book-transaction-today-date-wise-list-view/?radio_button="}${values.radio_button}`;
            if (values.from_date === undefined && values.to_date === undefined) {
                ToastWarning("Both 'from_date' and 'to_date' are undefined. Skipping API call.");
                return; // Stop form submission if both dates are undefined
            }
            if (values.from_date && values.to_date) {
                url += `&from_date=${BSToADUSNPFormat(values.from_date)}`;
                url += `&to_date=${BSToADUSNPFormat(values.to_date)}`;
            } else if (values.from_date && !values.to_date) {
               ToastWarning("Please Enter To Date")
                return; 
            }
            else if (!values.from_date && values.to_date) {
               ToastWarning("Please Enter From Date")
                return; // Stop execution if to_date is missing
            }else if (values.from_date && !values.to_date) {
                ToastWarning("Please enter a 'to date' to complete the date range.");
                return; // Stop form submission if to_date is missing
            } else if (!values.from_date && values.to_date) {
                ToastWarning("Please enter a 'from date' to complete the date range.");
                return; // Stop form submission if from_date is missing
            }
            try {
                await fetchDataFromApi(url).then((res) => {
                    dispatch(LibraryIssueReturnReport(res));
                    CloseDispatch();
                    navigate("/library-return-report-page-list-view");
                });
            } catch (error) {
                console.log(error);
            }
        },
    });


    return (
        <div className=''>
            <ReportHeading heading="Issue Return Report" />
            <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                    <Grid item xl={12} sm={12} lg={12} md={12} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xl={5.5} sm={5.5}>
                                <InputNumber name="from_date" value={values.from_date} disabled={false} handleChange={handleChange} mask="9999/99/99" required={false} label={"From Date(BS)"} />

                            </Grid>
                            <Grid item xl={1} sm={1} container justify="center" alignItems="center">
                                <div className='mt-4 text-center'>
                                    -
                                </div>
                            </Grid>
                            <Grid item xl={5.5} sm={5.5}>
                                <InputNumber name="to_date" value={values.to_date} disabled={false} handleChange={handleChange} mask="9999/99/99" required={false} label={"To Date(BS)"} />
                            </Grid>
                            <Grid item xl={12} sm={12} lg={12} md={12} xs={12}>
                                <RadioGroup
                                    className='mt-0'
                                    name='radio_button'
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={values.radio_button} // Convert value to string as RadioGroup expects string values
                                    onChange={handleChange}
                                    row
                                >
                                    <FormControlLabel
                                        value="1"
                                        control={<Radio size='small' className='dark:text-gray-400 !text-customPrimary' sx={{
                                            "&, & + .MuiFormControlLabel-label": {
                                                color: "#242424",
                                                fontFamily: 'Public Sans, sans-serif',
                                                fontSize: "13px",
                                                fontWeight: 600
                                            }
                                        }} />}
                                        label="Today's Issues"
                                        xs={{ fontSize: "14px" }}
                                    />
                                    <FormControlLabel
                                        value="2"
                                        control={<Radio size='small' className='dark:text-gray-400 !text-customPrimary' sx={{
                                            "&, & + .MuiFormControlLabel-label": {
                                                color: "#242424",
                                                fontFamily: 'Public Sans, sans-serif',
                                                fontSize: "12px",
                                                fontWeight: 600
                                            }
                                        }} />}
                                        label="Today's Returned"
                                        xs={{ fontSize: "12px" }}
                                    />
                                    <FormControlLabel
                                        value="3"
                                        control={<Radio size='small' className='dark:text-gray-400 !text-customPrimary' sx={{
                                            "&, & + .MuiFormControlLabel-label": {
                                                color: "#242424",
                                                fontFamily: 'Public Sans, sans-serif',
                                                fontSize: "12px",
                                                fontWeight: 600
                                            }
                                        }} />}
                                        label="Delequent Books"
                                        xs={{ fontSize: "12px" }}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className='flex justify-between mt-4'>
                      <ClearBtn onClick={CloseDispatch} />  <SearchBtn type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default IssueReturnForm