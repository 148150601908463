import React, { Fragment } from 'react'
import ProfitLossRptForm from '../../../../components/account/report/profit_loss_rpt/ProfitLossRptForm'

const AccountProfitLossReportFormView = () => {
  return (
    <Fragment>
        <ProfitLossRptForm />
    </Fragment>
  )
}

export default AccountProfitLossReportFormView


