import React, { Fragment } from 'react'
import SalesRptForm from '../../../../components/inventory/sales_rpt/SalesRptForm'

const InventorySalesReportListView = () => {
  return (
    <Fragment>
        <SalesRptForm />
    </Fragment>
  )
}

export default InventorySalesReportListView