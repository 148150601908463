import React, { Fragment, useEffect, useState } from "react";
import ReportHeading from "../../../../widgets/ReportHeading";
import { Grid } from "@mui/material";
import SelectOptionBox from "../../../../widgets/SelectOptionBox";
import { ClearBtn, SearchBtn } from "../../../../widgets/AppBtn";
import { useLanguage } from "../../../../widgets/LangContext";
import { useDispatch, useSelector } from "react-redux";
import { SidebarReportClose } from "../../../../store/softSlice";
import { useNavigate } from "react-router-dom";
import {
  AcademicsYearOptions,
  ProgramOptions,
  YearSemesterOptions,
} from "../../../../widgets/Options";
import {
  AnnualYearWiseStudentReportOptionsList,
  EMISExamResultsAnalysisReportListView,
} from "../../../../store/emis_rptSlice";
import { postDataToTheServer } from "../../../../widgets/api";
import AppURL from "../../../../widgets/AppURL";
import { useFormik } from "formik";
import { ProgramWiseSemesterMajorSubject } from "../../../../widgets/ProgramWiseSemester";

const ResultsAnalysisFormView = () => {
  const { language } = useLanguage();
  const dispatch = useDispatch();
  const { program_wise_year_sem_major_list } = useSelector(
    (state) => state.emis_rpt
  );

  const CloseDispatch = () => {
    dispatch(SidebarReportClose({ action: false }));
  };
  const [selectValues, setOnSelectValues] = useState({
    program: "",
    acdmc_year: "",
    year_semester: "",
  });
  const fetchYearSemesterMajorSubList = async () => {
    if (selectValues?.program) {
      await ProgramWiseSemesterMajorSubject(
        selectValues?.program?.value,
        dispatch
      );
    }
  };
  useEffect(() => {
    fetchYearSemesterMajorSubList();
  }, [selectValues?.program]);
  const navigate = useNavigate();
  const { emis_rpt_options_list } = useSelector((state) => state.emis_rpt);

  const optionHandleSelect = (fieldName, data) => {
    handleChange(fieldName)(data.value);
    setOnSelectValues((prevValues) => ({
      ...prevValues,
      [fieldName]: data,
    }));
    if (fieldName === "program") {
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        year_semester: "",
      }));
    }
  };

  const ProgramOption = ProgramOptions(emis_rpt_options_list?.program);
  const AcademicsYearOption = AcademicsYearOptions(
    emis_rpt_options_list?.acdmc_year
  );
  const YearSemesterOption = YearSemesterOptions(
    program_wise_year_sem_major_list?.year_semester
  );

  const initialValues = {
    program: "",
    acdmc_year: "",
  };
  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, action) => {
      const formData = new FormData();
      formData.append("program", selectValues?.program?.value);
      formData.append("acdmc_year", selectValues?.acdmc_year?.value);
      formData.append("year_semester", selectValues?.year_semester?.value);

      await postDataToTheServer(
        AppURL.EMISRPT + "emis-exam-results-analysis-reports-view/",
        formData
      ).then((res) => {
        dispatch(EMISExamResultsAnalysisReportListView(res));
        CloseDispatch();
        dispatch(
          AnnualYearWiseStudentReportOptionsList({
            program: selectValues?.program?.label,
            acdmc_year: selectValues?.acdmc_year?.label,
            year_semester: selectValues?.year_semester?.label,
          })
        );
        navigate("/results-analysis-reports-create-list-view");
      });
    },
  });
  return (
    <div>
      <Fragment>
        <ReportHeading heading="Results Analysis Reports" />
        <form onSubmit={handleSubmit} className="mt-5">
          <Grid container rowSpacing={1}>
            <Grid item xl={12} xs={12} md={12} sm={12} lg={12}>
              <SelectOptionBox
                label={language ? "Academics Year" : "शैक्षिक वर्ष"}
                value={selectValues.acdmc_year}
                options={AcademicsYearOption}
                name="acdmc_year"
                optionSelect={optionHandleSelect}
                handleBlur={handleBlur}
                touched={touched.acdmc_year}
                errors={errors.acdmc_year}
                required={true}
              />
            </Grid>
            <Grid item xl={12} xs={12} md={12} sm={12} lg={12}>
              <SelectOptionBox
                label={language ? "Program" : "स्तर"}
                value={selectValues.program}
                options={ProgramOption}
                name="program"
                optionSelect={optionHandleSelect}
                handleBlur={handleBlur}
                touched={touched.program}
                errors={errors.program}
                required={true}
              />
            </Grid>
            <Grid item xl={12} xs={12} md={12} sm={12} lg={12}>
              <SelectOptionBox
                label={language ? "Year/Semester" : "वर्ष/सेमेस्टर"}
                value={selectValues.year_semester}
                clear={true}
                options={YearSemesterOption}
                name="year_semester"
                optionSelect={optionHandleSelect}
                handleBlur={handleBlur}
                touched={touched.year_semester}
                errors={errors.year_semester}
                required={false}
              />
            </Grid>
          </Grid>
          <div className="flex justify-between mt-4">
            <ClearBtn type="button" onClick={CloseDispatch} />{" "}
            <SearchBtn isSubmitting={isSubmitting} type="submit" />
          </div>
        </form>
      </Fragment>
    </div>
  );
};

export default ResultsAnalysisFormView;
