import React, { useState } from "react";

const nepaliMonths = [
  "बैशाख", "जेठ", "असार", "श्रावण", "भदौ", "असोज",
  "कार्तिक", "मंसिर", "पुष", "माघ", "फागुन", "चैत"
];

const daysInNepaliMonths = [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 30];

const getNepaliCalendar = (year, month) => {
  const days = [];
  for (let day = 1; day <= daysInNepaliMonths[month]; day++) {
    const date = new Date(year, month, day);
    const dayOfWeek = date.getDay();
    days.push({
      date: `${year}-${month + 1}-${day}`,
      day_of_week: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][dayOfWeek],
      is_saturday: dayOfWeek === 6,
      is_holiday: dayOfWeek === 6,
    });
  }
  return { year, month: month + 1, days };
};

const NepaliDatePicker = () => {
  const currentYear = 2082;
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(11);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const calendar = getNepaliCalendar(year, month);

  const selectDate = (date) => {
    setSelectedDate(date);
    setShowCalendar(false);
  };

  return (
    <div className="relative w-64">
      <label className="block text-sm font-medium">Select Date</label>
      <input
        type="text"
        value={selectedDate || ""}
        onClick={() => setShowCalendar(!showCalendar)}
        readOnly
        className="w-full p-2 border border-gray-300 rounded cursor-pointer"
      />
      {showCalendar && (
        <div className="absolute mt-2 w-full z-50 bg-white shadow-lg rounded-lg p-4">
          <div className="flex justify-between items-center mb-2">
            <select value={year} onChange={(e) => setYear(Number(e.target.value))}>
              {Array.from({ length: 50 }, (_, i) => 2050 + i).map((yr) => (
                <option key={yr} value={yr}>{yr}</option>
              ))}
            </select>
            <select value={month} onChange={(e) => setMonth(Number(e.target.value))}>
              {nepaliMonths.map((m, i) => (
                <option key={i} value={i}>{m}</option>
              ))}
            </select>
          </div>
          <div className="grid grid-cols-7 text-center">
            {["आ", "सो", "मं", "बु", "बि", "श्रा", "श"].map((d, i) => (
              <div key={i} className="text-gray-700">{d}</div>
            ))}
          </div>
          <div className="grid grid-cols-7 mt-2">
            {calendar.days.map(({ date, day_of_week, is_saturday }) => (
              <div
                key={date}
                onClick={() => selectDate(date)}
                className={`p-2 rounded-lg cursor-pointer text-center hover:bg-gray-200 ${is_saturday ? "text-red-500" : ""}`}
              >
                {date.split("-")[2]}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NepaliDatePicker;