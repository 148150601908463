import React, { Fragment } from 'react';
import AccountTransRptFormView from '../../../../components/account/report/trans_rpt/AccountTransRptFormView';

const AccountTransactionReportFormView = () => {
  return (
    <Fragment>
        <AccountTransRptFormView />
    </Fragment>
  )
}

export default AccountTransactionReportFormView