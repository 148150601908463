import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { SidebarReportClose } from '../../../../store/softSlice';
import AppURL from '../../../../widgets/AppURL';
import { postDataToTheServer } from '../../../../widgets/api';
import { FormatDate, NepaliACDateFormat, NepNowDateFormat } from '../../../../widgets/DateFormat';
import ReportHeading from '../../../../widgets/ReportHeading';
import InputNumber from '../../../../widgets/InputNumberBox';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { Account_Day_BookII_Date, Account_Day_BookII_ListView } from '../../../../store/accountSlice';
import Loading from '../../../../widgets/Loading';

const DayBookFormView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }
    const { menu_list } = useSelector((state) => state?.software);

  
    const initialValues = {
        from_date: menu_list?.np_date,
        branch_id: "",
    }
    const { values, handleChange, handleSubmit, isSubmitting} =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                try {
                        const formData = new FormData();
                        formData.append("from_date", values.from_date);
                        await postDataToTheServer(AppURL.AccountsURL + `account-day-book-create-list-view/`, formData).then((res) => {
                            CloseDispatch();
                            dispatch(Account_Day_BookII_ListView(res));
                            navigate("/account-day-book-page-list-view");
                            dispatch(Account_Day_BookII_Date({date: values.from_date }))
                        });
                } catch (error) {
                    console.log(error);
                }
            },
        });

    return (
        <div className=''>
            <ReportHeading heading="Day Book" />
            <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <InputNumber name="from_date" value={values.from_date} disabled={false} handleChange={handleChange} mask="9999/99/99" required={true} label={"Date"} />
                        </Grid>
                    </Grid>
                    <div className='flex justify-between mt-4'>
                        <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn type="submit" />
                    </div>
                </form>
            </div>
            <Loading isSubmitting={isSubmitting} />
        </div>
    )
}

export default DayBookFormView