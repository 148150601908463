import { useSelector } from "react-redux";

const WatermarkWrapper = ({ children, text = "CONFIDENTIAL" }) => {
    const { menu_list } = useSelector((state) => state?.software);
    const Org_Details = menu_list?.structure;
    return (
        <div className="relative">
            <div className="absolute z-[999] w-full flex items-center justify-center pointer-events-none">
                <div className="flex flex-col text-center">
                    <span className="text-customPrimary abril-fatface-regular underline underline-offset-2 text-lg font-bold select-none">
                        {Org_Details?.org_name}
                    </span>
                    <span className="text-slate900 mt-1 dark:text-slate400 font-public-sans text-xs font-light opacity-90 select-none">
                    {Org_Details?.org_add}
                    </span>
                </div>
            </div>
            <div className="relative">{children}</div>
        </div>
    );
};

export default WatermarkWrapper;
