import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { pageTitle } from '../../../../store/softSlice';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import ItemEditFormView from '../../../../components/inventory/item_list_rpt/ItemEditFormView';
import ItemListView from '../../../../components/inventory/item_list_rpt/ItemListView';
import { AddPlusBtn } from '../../../../widgets/AppBtn';

const InventoryItemListCreateListView = ({ PageTitle, setIconsButton }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }));
    setIconsButton(null);
    document.title = `Nexapp - ${PageTitle}`;
  }, []);

  const [onProductEditID, setOnProductEditID] = useState(false);
  const [onProductList, setOnProductList] = useState([]);
  const URLS = AppURL.InventoryURL + "inventory-product-list-relation-view/";
  // const []
  const AllProductListView = async (urls) => {
    try {
      await fetchDataFromApi(urls).then((res) => {
        setOnProductList(res);
      });
    } catch (error) {
      console.log(error);
    }
  }
  const [masterCategoryList, setOnMasterCategoryList] = useState([]);
  const CategoryMasterListView = async () => {
    try {
      await fetchDataFromApi(AppURL.InventoryURL + "inventory-product-master-category-active-list/").then((res) => {
        setOnMasterCategoryList(res);
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    CategoryMasterListView();
    AllProductListView(URLS);
  }, []);

  return (
    <Fragment>
      {onProductEditID ? <ItemEditFormView masterCategoryList={masterCategoryList} setOnProductEditID={setOnProductEditID} onProductEditID={onProductEditID} /> : <ItemListView setOnProductEditID={setOnProductEditID} onProductList={onProductList} />}
    </Fragment>
  )
}

export default InventoryItemListCreateListView