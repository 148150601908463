import { useEffect } from "react";

const MenuItem = ({menu_title, menu_icon, onClick, active_menu}) => {
    useEffect(()=>{
    },[active_menu]);
    return (
        <div className='flex flex-row cursor-pointer select-none pr-[0.5px]' onClick={onClick}>
            <div className={`flex justify-center items-center h-full w-full text-slate600 dark:text-slate300 hover:text-white group  duration-300 transform hover:bg-green-500 transition ease-linear py-2 ${active_menu?"bg-customPrimary hover:opacity-90":""} rounded-sm`}>
                <div className='flex flex-col items-center'>
                    <div className={`px-2 text-[32px] group-hover:text-white text-customPrimary ${active_menu? "text-white":""} `}>{menu_icon}</div>
                    <h2 className={`mt-1 font-semibold text-[9px] group-hover:text-white drop-shadow-lg font-public-sans ${active_menu?"text-white":""} uppercase `}>{menu_title}</h2>
                </div>
            </div>
        </div>
    )
}

export default MenuItem