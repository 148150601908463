import React, { Fragment, useEffect } from 'react'
import ExamMarksFormView from '../../../../components/exam/report/marks_report/ExamMarksFormView'
import { useDispatch } from 'react-redux';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { EMIS_RPT_Options_List } from '../../../../store/emis_rptSlice';

const ExamMarksEntryFormView = () => {
  const dispatch = useDispatch();
  const EMISReportListView = async ()=>{
      try{
          await fetchDataFromApi(AppURL.EMISRPT+"emis-rpt-options-serach-list-view/").then((res)=>{
              dispatch(EMIS_RPT_Options_List(res));
          });
      }catch(error){
          console.log(error);
      }
  }
  useEffect(()=>{
      EMISReportListView();
  },[]);
  return (
    <Fragment>
        <ExamMarksFormView />
    </Fragment>
  )
}

export default ExamMarksEntryFormView