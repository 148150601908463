import { configureStore } from '@reduxjs/toolkit';
import softSlice from './softSlice';
import  academicSlice from './academicsSlice';
import accountSlice from './accountSlice';
import inventorySlice from './inventorySlice';
import librarySlice from './librarySlice';
import examSlice from './examSlide';
import emis_rptSlice from './emis_rptSlice';
import lmsSlice from './lmsSlice';
export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable state check
    }),
  reducer: {
    software: softSlice,
    emis_rpt: emis_rptSlice,
    academics: academicSlice,
    accounts: accountSlice,
    inventory: inventorySlice,
    library: librarySlice,
    exam: examSlice,
    lms: lmsSlice
  },
});
