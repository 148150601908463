import React, { Fragment, Suspense } from 'react';
import AppLoading from './AppLoading';
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { ImportComponent } from '../../widgets/import_com/ImportComponent';

const MainContent = ({ setIconsButton }) => {
  const { menu_list } = useSelector((state) => state?.software);

  return (
    <Fragment>
      <Suspense fallback={<AppLoading />} >
        <Routes>
          {Array.isArray(menu_list?.data) &&
            menu_list?.data?.map((submenu) =>
              Array.isArray(submenu?.submenus) &&
              submenu?.submenus?.map((subsubmenu) =>
                Array.isArray(subsubmenu?.subsubmenus) &&
                subsubmenu?.subsubmenus?.map((route, idx) => {
                  const ComponentData = route?.component
                    ? ImportComponent(route?.component)
                    : null;
                  return (

                    <Route
                      path={route?.link}
                      exact
                      name={route?.name}
                      element={
                        ComponentData ? (
                          <ComponentData
                            setIconsButton={setIconsButton}
                            PageTitle={route?.title}
                          />
                        ) : null
                      }
                    />

                  );
                })
              )
            )}
        </Routes>
      </Suspense>
    </Fragment>
  )
}

export default React.memo(MainContent);