
import AcademicsStudentReportFormView from "../../views/academics/report/student_report/AcademicsStudentReportFormView";
import AdvancedReportFormView from "../../views/accounts/report/advan_voucher_rpt/AdvancedReportFormView";
import AccountBalanceSheetRptFormView from "../../views/accounts/report/balance_sheet/AccountBalanceSheetRptFormView";
import AccountDayBookSearchFormView from "../../views/accounts/report/day_book/AccountDayBookSearchFormView";
import AccountProfitLossReportFormView from "../../views/accounts/report/profit_loss_rpt/AccountProfitLossReportFormView";
import StudentFeeReportsFormCreateView from "../../views/accounts/report/student_fee_rpt/StudentFeeReportsFormCreateView";
import AccountTransactionReportFormView from "../../views/accounts/report/trans_rpt/AccountTransactionReportFormView";
import AccountTrialReportFormView from "../../views/accounts/report/trial_rpt/AccountTrialReportFormView";
import AccountVoucherReportForm from "../../views/accounts/report/voucher_rpt/AccountVoucherReportForm";
import AdmissionYearStudentFormView from "../../views/exam/reports/admission_rpt/AdmissionYearStudentFormView";
import ExamAnnualYearWiseReportFormView from "../../views/exam/reports/annual_exam_rpt/ExamAnnualYearWiseReportFormView";
import AnnualYearStudentReportFormView from "../../views/exam/reports/annual_year_std_rpt/AnnualYearStudentReportFormView";
import EMISEnrollmentFormView from "../../views/exam/reports/enrollments/EMISEnrollmentFormView";
import EMISGraduateOfYearFormView from "../../views/exam/reports/graduate_of_year/EMISGraduateOfYearFormView";
import MarksLedgerFormView from "../../views/exam/reports/marks_ledger/MarksLedgerFormView";
import ExamMarksEntryFormView from "../../views/exam/reports/marks_rpt/ExamMarksEntryFormView";
import EMISPassoutReportFormView from "../../views/exam/reports/passout/EMISPassoutReportFormView";
import ResultsAnalysisReportFormView from "../../views/exam/reports/results_analysis/ResultsAnalysisReportFormView";
import ExamSubjectListFormView from "../../views/exam/reports/subj_list/ExamSubjectListFormView";
import EMISPassedListTranscriptFormView from "../../views/exam/reports/transcript_emis/EMISPassedListTranscriptFormView";
import IssueReportFormView from "../../views/inventory/report/issue_rpt/IssueReportFormView";
import PurchaseReportFormView from "../../views/inventory/report/purchase_rpt/PurchaseReportFormView";
import InventorySalesReportListView from "../../views/inventory/report/sales_rpt/InventorySalesReportListView";
import StockReportFormView from "../../views/inventory/report/stock_rpt/StockReportFormView";
import LibraryIssueReturnRptFormView from "../../views/library/report/issue_rtn_rpt/LibraryIssueReturnRptFormView";
import LibrarySearchAndInfoFormView from "../../views/library/report/search_info/LibrarySearchAndInfoFormView";

const ImportDrawerCom = (route) => {
    switch (route) {
        case 'AccountVoucherReportForm':
            return <AccountVoucherReportForm />;
        case 'AccountTrialReportFormView':
            return <AccountTrialReportFormView />;
        case 'AccountProfitLossReportFormView':
            return <AccountProfitLossReportFormView />;
        case 'AccountBalanceSheetRptFormView':
            return <AccountBalanceSheetRptFormView />;
        case 'IssueReportFormView':
            return <IssueReportFormView />;
        case 'InventorySalesReportListView':
            return <InventorySalesReportListView />;
        case 'PurchaseReportFormView':
            return <PurchaseReportFormView />;
        case 'StockReportFormView':
            return <StockReportFormView />;
        case 'ExamSubjectListFormView':
            return <ExamSubjectListFormView />;
        case 'EMISPassoutReportFormView':
            return <EMISPassoutReportFormView />;
        case 'LibrarySearchAndInfoFormView':
            return <LibrarySearchAndInfoFormView />;
        case 'LibraryIssueReturnRptFormView':
            return <LibraryIssueReturnRptFormView />;
        case 'EMISEnrollmentFormView':
            return <EMISEnrollmentFormView />;
        case 'EMISGraduateOfYearFormView':
            return <EMISGraduateOfYearFormView />;
        case 'ExamMarksEntryFormView':
            return <ExamMarksEntryFormView />;
        case 'EMISPassedListTranscriptFormView':
            return <EMISPassedListTranscriptFormView />;
        case 'AnnualYearStudentReportFormView':
            return <AnnualYearStudentReportFormView />;
        case 'ExamAnnualYearWiseReportFormView':
            return <ExamAnnualYearWiseReportFormView />;
        case 'AdmissionYearStudentFormView':
            return <AdmissionYearStudentFormView />;
        case 'AcademicsStudentReportFormView':
            return <AcademicsStudentReportFormView />;
        case 'AccountDayBookSearchFormView':
            return <AccountDayBookSearchFormView />;
        case 'AccountTransactionReportFormView':
            return <AccountTransactionReportFormView />;
        case 'MarksLedgerFormView':
            return <MarksLedgerFormView />;
        case 'ResultsAnalysisReportFormView':
            return <ResultsAnalysisReportFormView />;
        case 'AdvancedReportFormView':
            return <AdvancedReportFormView />;
        case 'StudentFeeReportsFormCreateView':
            return <StudentFeeReportsFormCreateView />;
        default:
            return null;
    }
};
export default ImportDrawerCom