import React, { Fragment } from 'react';
import ExamSubjectSearchView from '../../../../components/exam/report/subject_list/ExamSubjectSearchView';

const ExamSubjectListFormView = () => {
  return (
    <Fragment>
        <ExamSubjectSearchView />
    </Fragment>
  )
}

export default ExamSubjectListFormView