import { Grid } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import SelectOptionBox from '../../../../widgets/SelectOptionBox'
import { useFormik } from 'formik';
import { useLanguage } from '../../../../widgets/LangContext';
import ReportHeading from '../../../../widgets/ReportHeading';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { SidebarReportClose } from '../../../../store/softSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AcademicsYearOptions, ProgramOptions, YearSemesterOptions } from '../../../../widgets/Options';
import { ProgramWiseSemester } from '../../../../widgets/ProgramWiseSemester';
import { useNavigate } from 'react-router-dom';
import AppURL from '../../../../widgets/AppURL';
import { fetchDataFromApi, postDataToTheServer } from '../../../../widgets/api';
import { EMISPasssoutReportListView } from '../../../../store/emis_rptSlice';

const PassoutSearchFormView = () => {
    const { language } = useLanguage();
    const dispatch = useDispatch();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }
    const navigate = useNavigate();

    const { emis_rpt_options_list, program_wise_year_sem_list } = useSelector((state) => state.emis_rpt);

    // const {emis_rpt_options_list} = useSelector((state)=>state.emis_rpt);
    const [selectValues, setOnSelectValues] = useState({
        program: "",
        acdmc_year: "",
        year_semester: "",
        medium: "",
        sec: "",
        team: "",
        shift: "",
        maj_subj: "",
    });

    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };
    const ProgramOption = ProgramOptions(emis_rpt_options_list?.program);
    const AcademicsYearOption = AcademicsYearOptions(emis_rpt_options_list?.acdmc_year);
    const fetchYearSemesterMajorSubList = async () => {
        if (selectValues?.program) {
            ProgramWiseSemester(selectValues?.program?.value, dispatch);
        }
    }
    useEffect(() => {
        fetchYearSemesterMajorSubList();
    }, [selectValues?.program]);
    const YearSemesterOption = YearSemesterOptions(program_wise_year_sem_list);
    const initialValues = {
        acdmc_year: "",
    };
    // /emis-passout-report-page-list-view
    const { errors, touched, handleBlur, handleChange, isSubmitting, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append('acdmc_year', selectValues?.acdmc_year?.value);
                await postDataToTheServer(AppURL.EMISRPT + "emis-passout-report-list-view/", formData).then((res) => {
                    dispatch(EMISPasssoutReportListView(res));
                    CloseDispatch();
                    navigate("/emis-passout-report-page-list-view");
                });
            }
        });
    return (
        <Fragment>
            <ReportHeading heading="Passout Report" />
            <form onSubmit={handleSubmit} className='mt-5'>
                <Grid container rowSpacing={1}>
                    <Grid item xl={12} xs={12} md={12} sm={12} lg={12}>
                        <SelectOptionBox label={language ? "Academics Year" : "शैक्षिक वर्ष"} value={selectValues.acdmc_year} options={AcademicsYearOption} name="acdmc_year" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.acdmc_year} errors={errors.acdmc_year} required={true} />
                    </Grid>
                </Grid>
                <div className='flex justify-between mt-4'>
                    <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn isSubmitting={isSubmitting} type="submit" />
                </div>
            </form>
        </Fragment>
    )
}

export default PassoutSearchFormView