import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { SidebarReportClose } from '../../../../store/softSlice';
import { ProgramOptions, YearSemesterOptions } from '../../../../widgets/Options';
import ReportHeading from '../../../../widgets/ReportHeading';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { EMISOptionsSelectList, SubjectListViewClassWiseList } from '../../../../store/examSlide';


const ExamSubjectSearchView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }
    const [programList, setOnProgramList] = useState([]);
    const fetchDataBalanceSheet = async () => {
        try {
            await fetchDataFromApi(AppURL.ExamURL+"exam-student-program-semester-wise-subject-list-view/").then((res)=>{
                setOnProgramList(res?.program_list);
            })
        } catch (error) {
            console.log(error);
        }
    }
    const ProgramOption =ProgramOptions(programList);
    useEffect(() => {
        fetchDataBalanceSheet();
    }, []);

    const [selectValues, setOnSelectValues] = useState({
        program: "",
        year_semester: ""
    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };

    const [yearSemMajSub, setOnYearSemMajorSub] = useState([]);
    const fetchYearSemesterMajorSubList = async () => {
      if (selectValues?.program) {
        try {
          await fetchDataFromApi(AppURL.AcademicsURL + "academic-student-year-semester-filter-list/?program_id=" + selectValues?.program?.value).then((res) => {
            setOnYearSemMajorSub(res);
          })
        } catch (error) {
          console.log(error);
        }
      }
    }
    useEffect(() => {
      fetchYearSemesterMajorSubList();
    }, [selectValues?.program]);
  
    const YearSemesterOption = YearSemesterOptions(yearSemMajSub?.year_semester);

    const initialValues = {
        fiscal_year_name: selectValues?.fiscal_year_name || "",
        from_date: '',
        to_date: '',
        branch_id: "",
    }
    const { values, handleChange, handleBlur, isSubmitting, handleSubmit, errors, touched } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                try{
                    await fetchDataFromApi(AppURL.ExamURL+"exam-student-class-wise-subject-list-view/?program="+selectValues?.program?.value+'&&year_semester='+selectValues?.year_semester?.value).then((res)=>{
                        dispatch(SubjectListViewClassWiseList(res));
                        dispatch(EMISOptionsSelectList({
                            "program": selectValues?.program?.label,
                            "year_semester": selectValues?.year_semester?.label
                        }));
                        CloseDispatch();
                        navigate("/exam-subject-list-view-search");
                    });
                }catch(error){
                    console.log(error);
                }
            },
        });


    return (
        <div className=''>
            <ReportHeading heading="Subject Report" />
            <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <SelectOptionBox name="program" label="Program" options={ProgramOption} value={selectValues.program} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.program} errors={errors.program} required={true} />
                        </Grid>

                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <SelectOptionBox name="year_semester" label="Year/Semester" options={YearSemesterOption} value={selectValues.year_semester} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.year_semester} errors={errors.year_semester} required={true} />
                        </Grid>
                    </Grid>
                    <div className='flex justify-between mt-4'>
                        <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn isSubmitting={isSubmitting} type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ExamSubjectSearchView