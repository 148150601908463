import { Grid } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { useFormik } from 'formik';
import { useLanguage } from '../../../../widgets/LangContext';
import ReportHeading from '../../../../widgets/ReportHeading';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { SidebarReportClose } from '../../../../store/softSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AcademicsYearOptions, CasteOptions, DistrictOptions, FacultyOptions, GenderOptions, LevelOptions, MajorSubjectOptions, MunOptions, ProgramOptions, ProvinceOptions, StudentProfileOptions, YearSemesterOptions } from '../../../../widgets/Options';
import { LevelAndFacultySearchView, ProgramWiseSemesterMajorSubject } from '../../../../widgets/ProgramWiseSemester';
import { useNavigate } from 'react-router-dom';
import { gender_lists } from '../../../../widgets/json';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { AnnualYearWiseStudentReportOptionsList } from '../../../../store/emis_rptSlice';
import { StudentSearchReportList } from '../../../../store/academicsSlice';
import InputTextBox from '../../../../widgets/InputTextBox';
import Loading from '../../../../widgets/Loading';
// import { StudentSearchReportListView } from '../../../../store/academicsSlice';

const StudentSearchRptFormView = ({ onReportOptions }) => {
  const { language } = useLanguage();
  const dispatch = useDispatch();
  const CloseDispatch = () => {
    dispatch(SidebarReportClose({ "action": false }));
  }
  const navigate = useNavigate();
  const GenderOption = GenderOptions(gender_lists);
  const { program_wise_year_sem_major_list, level_and_faculty_wise_program } = useSelector((state) => state.emis_rpt);

  const [selectValues, setOnSelectValues] = useState({
    program: "",
    acdmc_year: "",
    year_semester: "",
    maj_subj: "",
    gender: "",
    caste: "",
    std_profile: "",
    level_id: "",
    faculty_id: "",
    province: "",
    mun: "",
    district: ""
  });
  const optionHandleSelect = (fieldName, data) => {
    if (data === null || data.value === "") {
      // If data is null or empty, clear the input value
      handleChange(fieldName)("");
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
    } else {
      // Otherwise, update the input value as usual
      handleChange(fieldName)(data.value);
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        [fieldName]: data,
      }));
    }
    if (fieldName === "program") {
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        year_semester: "",
        maj_subj: ""
      }));
    }
    if (fieldName === "province") {
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        mun: "",
        district: ""
      }));
    }
    if (fieldName === "district") {
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        mun: "",
      }));
    }
    if (fieldName === "level_id") {
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        program: "",
      }));
    }
    if (fieldName === "faculty_id") {
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        program: "",
      }));
    }
  };
  const ProgramOption = ProgramOptions(level_and_faculty_wise_program?.program);
  const FacultyOption = FacultyOptions(onReportOptions?.faculty);
  const LevelOption = LevelOptions(onReportOptions?.level);
  const CasteOption = CasteOptions(onReportOptions?.caste);
  const AcademicsYearOption = AcademicsYearOptions(onReportOptions?.academics_year);
  const ProvinceOption = ProvinceOptions(onReportOptions?.province);

  const fetchYearSemesterMajorSubList = async () => {
    if (selectValues?.program) {
      await ProgramWiseSemesterMajorSubject(selectValues?.program?.value, dispatch);
    }
    if (selectValues?.faculty_id && selectValues?.level_id) {
      await LevelAndFacultySearchView(selectValues?.level_id?.value, selectValues?.faculty_id?.value, dispatch);
    }
  }
  useEffect(() => {
    fetchYearSemesterMajorSubList();
  }, [selectValues?.program, selectValues?.faculty_id, selectValues?.level_id]);
  const [onCasteOptionsList, setOnCasteOptionsList] = useState([]);

  useEffect(() => {
    const getSubCasteList = async () => {
      if (selectValues?.province) {
        try {
          await fetchDataFromApi(AppURL.AcademicsURL + "academics-province-wise-district-list/?province_id=" + selectValues?.province?.value).then((res) => {
            setOnCasteOptionsList((prevValues) => ({
              ...prevValues,
              "district": res
            }));
          });
        } catch (error) {
          console.log(error);
        }
      }
      if (selectValues?.district) {
        try {
          await fetchDataFromApi(AppURL.AcademicsURL + "academics-district-wise-municiplity-list/?district_id=" + selectValues?.district?.value).then((res) => {
            setOnCasteOptionsList((prevValues) => ({
              ...prevValues,
              "mun": res
            }));
          });
        } catch (error) {
          console.log(error);
        }
      }
    }

    getSubCasteList();
  }, [selectValues?.province, selectValues?.district]);
  const DistrictOption = DistrictOptions(onCasteOptionsList?.district);
  const MuniOptions = MunOptions(onCasteOptionsList?.mun);
  const YearSemesterOption = YearSemesterOptions(program_wise_year_sem_major_list?.year_semester);
  const MajorSubjectOption = MajorSubjectOptions(program_wise_year_sem_major_list?.major_subject);
  const initialValues = {
    program: "",
    acdmc_year: "",
    year_semester: "",
    maj_subj: "",
    province: "",
    district: "",
    mun: "",
  };
  
  const { errors, touched, values, handleBlur, handleChange, handleSubmit, isSubmitting } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        let urls = AppURL.AcademicsURL + "academics-student-search-report-list-view/?acdmc_year=" + selectValues?.acdmc_year?.value;
        if (selectValues.level_id) {
          urls += `&level_id=${selectValues?.level_id?.value}`;
        }
        if (selectValues.program) {
          urls += `&program=${selectValues?.program?.value}`;
        }
        if (selectValues.gender) {
          urls += `&gender=${selectValues?.gender?.value}`;
        }
        if (selectValues.acdmc_year) {
          urls += `&acdmc_year=${selectValues?.acdmc_year?.value}`;
        }
        if (selectValues.caste_id) {
          urls += `&caste_id=${selectValues?.caste_id?.value}`;
        }
        if (selectValues.province) {
          urls += `&province=${selectValues?.province?.value}`;
        }
        if (selectValues.district) {
          urls += `&district=${selectValues?.district?.value}`;
        }
        if (selectValues.mun) {
          urls += `&mun=${selectValues?.mun?.value}`;
        }
        if (selectValues.year_semester) {
          urls += `&year_semester=${selectValues?.year_semester?.value}`;
        }
        if (selectValues.maj_subj) {
          urls += `&maj_subj=${selectValues?.maj_subj?.value}`;
        }
        if (selectValues.level) {
          urls += `&level=${selectValues?.level?.value}`;
        }
        if (selectValues.faculty) {
          urls += `&faculty=${selectValues?.faculty?.value}`;
        }
        if (values.ward_no) {
          urls += `&ward_no=${values?.ward_no}`;
        }
        await fetchDataFromApi(urls).then((res) => {
          dispatch(StudentSearchReportList(res));
          CloseDispatch();
          navigate("/academics-student-search-report-list-view");
          dispatch(AnnualYearWiseStudentReportOptionsList(
            {
              "program": selectValues?.program?.label,
              "acdmc_year": selectValues?.acdmc_year?.label,
              "gender": selectValues?.gender?.label,
              "mun": selectValues?.mun?.label,
              "caste": selectValues?.caste?.label,
              "level": selectValues?.level_id?.label,
              "faculty": selectValues?.faculty_id?.label,
              "year_semester": selectValues?.year_semester?.label,
              "maj_subj": selectValues?.maj_subj?.label,
              "ward_no": values.ward_no
            }
          ));
        });
      }
    });
  return (
    <Fragment>
      <ReportHeading heading="Search Student Report" />
      <form onSubmit={handleSubmit} className='mt-5'>
        <Grid container rowSpacing={0.5} columnSpacing={0.5}>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={language ? "Academics Year" : "शैक्षिक वर्ष"} value={selectValues.acdmc_year} clear={true} options={AcademicsYearOption} name="acdmc_year" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.acdmc_year} errors={errors.acdmc_year} required={true} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={language ? "Level" : "स्तर"} value={selectValues.level_id} clear={true} options={LevelOption} name="level_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.level_id} errors={errors.level_id} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={language ? "Faculty" : "संकाय"} value={selectValues.faculty_id} clear={true} options={FacultyOption} name="faculty_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.faculty_id} errors={errors.faculty_id} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={language ? "Program" : "कार्यक्रम"} value={selectValues.program} clear={true} options={ProgramOption} name="program" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.program} errors={errors.program} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={language ? "Year/Semester" : "वर्ष/सेमेस्टर"} value={selectValues.year_semester} clear={true} options={YearSemesterOption} name="year_semester" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.year_semester} errors={errors.year_semester} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={language ? "Province" : "प्रान्त"} value={selectValues.province} clear={true} options={ProvinceOption} name="province" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.province} errors={errors.province} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={language ? "District" : "जिल्ला"} value={selectValues.district} clear={true} options={DistrictOption} name="district" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.district} errors={errors.district} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={language ? "Municipality" : "नगरपालिका"} value={selectValues.mun} clear={true} options={MuniOptions} name="mun" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.mun} errors={errors.mun} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={language ? "Major Subject" : "मुख्य विषय"} value={selectValues.maj_subj} clear={true} options={MajorSubjectOption} name="maj_subj" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.maj_subj} errors={errors.maj_subj} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={language ? "Gender" : "लिङ्ग"} value={selectValues.gender} clear={true} options={GenderOption} name="gender" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.gender} errors={errors.gender} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={language ? "Caste" : "जाति"} value={selectValues.caste} clear={true} options={CasteOption} name="caste" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.caste} errors={errors.caste} required={false} />
          </Grid>

          <Grid item xl={6} xs={12} md={6} sm={12} lg={6}>
            <div className='mt-0.5'>
              <Grid container rowSpacing={2} columnSpacing={0.5}>
                <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                  <InputTextBox
                    name="ward_no"
                    type="text"
                    label={language ? "Ward No" : "वडा नं"}
                    value={values.ward_no}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.ward_no}
                    errors={errors.ward_no}
                    required={false}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div className='flex justify-between mt-4'>
          <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn type="submit" />
        </div>
      </form>
      <Loading isSubmitting={isSubmitting} />
    </Fragment>
  )
}

export default StudentSearchRptFormView