import axios from "axios";

export const fetchDataFromApi = async (url, params) => {
    try {
        const { data } = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            params,
        });
        console.log(data);
        return data
    } catch (error) {
        console.log(error);
        return error;
    }
}

export const postDataToTheServer = async (url, params) => {
    try {
        const { data } = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            }, params,
        });

        return data;
    } catch (error) {
        console.log(error.response.data);
        return error.response.data;
    }
}

export const postFileToTheServer = async (url, params) => {
    try {
        const { data } = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                'Content-Type': 'multipart/form-data',
            }, params,
        });

        return data;
    } catch (error) {
        console.log(error.response.data);
        return error.response.data;
    }
}
export const putDataToTheServer = async (url, params) => {
    try {
        const { data } = await axios.put(url, params, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            }, params,
        })
        return data;
    } catch (error) {
        console.log(error.response);
        return error.response;
    }
}

export const deleteDataToTheServer = async (url, params) => {
    try {
        const { data } = await axios.delete(url, params, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            }, params,
        })
        return data;
    } catch (error) {
        console.log(error.response.data);
        return error.response.data;
    }
}