import React, { Fragment, useEffect, useState } from 'react'
import StudentSearchRptFormView from '../../../../components/academics/report/std_search_rpt/StudentSearchRptFormView'
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';

const AcademicsStudentReportFormView = () => {
  const [onReportOptions, setOnReportOptions] = useState([]);
  const StdReportOptionView = async () => {
      try {
          await fetchDataFromApi(AppURL.EMISRPT + "exam-marks-emis-report-options-list-view/").then((res) => {
              setOnReportOptions(res);
          });
      } catch (error) {
          console.log(error);
      }
  }
  useEffect(() => {
    StdReportOptionView();
  }, [])
  return (
    <Fragment>
      <StudentSearchRptFormView onReportOptions={onReportOptions} />
    </Fragment>
  )
}

export default AcademicsStudentReportFormView