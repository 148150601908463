import React from 'react';
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SidebarReportClose } from '../../../store/softSlice';
import { fetchDataFromApi } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { useFormik } from 'formik';
import { ClearBtn, SearchBtn } from '../../../widgets/AppBtn';
import { ItemPurchaseRptList } from '../../../store/inventorySlice';
import ReportHeading from '../../../widgets/ReportHeading';
import InputNumberBox from '../../../widgets/InputNumberBox';
import { useLanguage } from '../../../widgets/LangContext';


const PurchaseReportForm = () => {
    const { language } = useLanguage();
      const { menu_list } = useSelector((state) => state?.software);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }

    const initialValues = {
        from_date: menu_list?.np_date||"",
        to_date:  menu_list?.np_date||"",
        branch_id: "",
    }

    const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                try {
                    await fetchDataFromApi(AppURL.InventoryURL + `inventory-product-purchase-report-list-view/?FromDateN=${values?.from_date}&ToDateN=${values?.to_date}`).then((res) => {
                        dispatch(ItemPurchaseRptList(res));
                        CloseDispatch();
                        navigate("/inventory-purchase-report-page-list-view");
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });


    return (
        <div className=''>
            <ReportHeading heading="Product Purchase Report" />
            <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <InputNumberBox name="from_date" value={values.from_date} disabled={false} handleChange={handleChange} mask="9999/99/99" required={true} label={"From Date"} errors={errors.from_date} handleBlur={handleBlur} />
                        </Grid>

                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <InputNumberBox name="to_date" value={values.to_date} disabled={false} handleChange={handleChange} mask="9999/99/99" required={true} label={"To Date"} errors={errors.to_date} handleBlur={handleBlur} />
                        </Grid>
                    </Grid>
                    <div className='flex justify-between mt-4'>
                        <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PurchaseReportForm