import { useEffect, useState } from 'react';

const DarkTheme = () => {
    // Initialize theme from local storage or set a default theme

    const [theme, setTheme] = useState('light');
  
    useEffect(() => {
      // Update the class on the root element to apply the theme
      const root = window.document.documentElement;
      root.classList.remove(theme === 'dark' ? 'light' : 'dark');
      root.classList.add(theme);
  
      // Save the theme to local storage
      localStorage.setItem('theme', theme);
    }, [theme]);
  
    // Return a function to toggle the theme
    const toggleTheme = () => {
      setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
    };
  
    return [theme, toggleTheme];
  };
  
  export default DarkTheme;