import React from 'react';
import { TiEye } from "react-icons/ti";
import { BsQrCode } from "react-icons/bs";
import { Button } from "@material-tailwind/react";
import { MdOutlineKeyboardReturn, MdSkipPrevious } from "react-icons/md";
import { BiDownload, BiSkipNext } from "react-icons/bi";
import { FaSave, FaSearch } from "react-icons/fa";
import { MdClear } from "react-icons/md";
import { IconButton } from "@material-tailwind/react";
import { MdLockPerson } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";
import { FaFilePdf } from "react-icons/fa";
import { BsFiletypeXls } from "react-icons/bs";
import { IoMdCard } from "react-icons/io";
import { MdPrint } from "react-icons/md";
import { MdOutlineModeEdit } from "react-icons/md";
import { IoAddOutline } from 'react-icons/io5';
import { MdOutlineAutoMode } from "react-icons/md";
import { GrUpgrade } from 'react-icons/gr';
import { IoClose } from "react-icons/io5";
import { IoSearchOutline } from "react-icons/io5";
import { MdOutlineDelete } from "react-icons/md";
import { RiAiGenerate } from "react-icons/ri";
import { IoAdd } from "react-icons/io5";

export const ViewEyeBtn = ({ onClick, bg_color, wh }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-gray-600"} w-${wh ? wh : 7} h-${wh ? wh : 7} rounded-full`}>
            <TiEye />
        </IconButton>
    )
}

export const QRBtn = ({ onClick, bg_color }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-gray-600"} w-7 h-7 rounded-full`}>
            <BsQrCode />
        </IconButton>
    )
}
export const AddPlusBtn = ({ onClick, bg_color }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-gray-600"} w-7 h-7 rounded-full`}>
            <IoAddOutline />
        </IconButton>
    )
}
export const ViewPremissionBtn = ({ onClick, bg_color }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-gray-600"} w-7 h-7 rounded-full`}>
            <MdLockPerson />
        </IconButton>
    )
}
export const PdfExportBtn = ({ onClick, bg_color, wh }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-gray-600"} w-${wh ? wh : 7} h-${wh ? wh : 7} rounded-full`}>
            <FaFilePdf />
        </IconButton>
    )
}


export const ExportExcelBtn = ({ onClick, bg_color }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-gray-600"} w-7 h-7 rounded-full`}>
            <BsFiletypeXls />
        </IconButton>
    )
}


export const CardPrintBtn = ({ onClick, bg_color }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-gray-600"} w-7 h-7 rounded-full relative `}>
            <IoMdCard />
        </IconButton>
    )
}
export const EditPenBtn = ({ onClick, bg_color, wh }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-slate600 dark:bg-slate200"} w-${wh ? wh : 7} h-${wh ? wh : 7} relative rounded-full`}>
            <MdOutlineModeEdit className='dark:text-slate700' />
        </IconButton>

    )
}

export const DeleteBtn = ({ onClick, bg_color, wh }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-red-500" : "bg-slate600 dark:bg-slate200"} w-${wh ? wh : 7} h-${wh ? wh : 7} relative rounded-full`}>
            <MdOutlineDelete className='dark:text-slate700' />
        </IconButton>

    )
}

export const PrevBtn = ({ onClick, disabled }) => {
    return (
        <Button size="sm" onClick={onClick} color="white" className="flex text-white items-center capitalize gap-2 bg-gray-700 rounded-[3px] px-2 py-1" disabled={disabled}>
            <MdSkipPrevious className='text-[19px]' />
            <span>Prev</span>
        </Button>
    )
}

export const NextBtn = ({ onClick, disabled }) => {
    return (
        <Button size="sm" onClick={onClick} color="white" className="flex text-white items-center capitalize gap-2 bg-gray-700 rounded-[3px] px-2 py-1" disabled={disabled}>
            <BiSkipNext className='text-[19px]' />
            <span>Next</span>
        </Button>
    )
}


export const SaveBtn = ({ onClick, type, disabled, id, block }) => {
    return (
        <Button type={type} onClick={onClick} id={id||"save"}size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-sm py-1.5 px-3" disabled={disabled || block || false}>
            <FaSave className='text-[19px]' />
            <span className='text-[13px] font-normal'>{disabled ? "Submitting.." : "Save"}</span>
        </Button>
    )
}

export const GenerateBtn = ({ onClick, type, disabled, block }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-[3px] py-1.5 px-1.5" disabled={disabled || block || false}>
            <RiAiGenerate className='text-[19px]' />
            <span className='text-[13px] font-normal'>{disabled ? "Submitting.." : "Generate"}</span>
        </Button>
    )
}
export const PayBill = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-[3px] py-1.5 px-1.5" disabled={false}>
            <FaSave className='text-[19px]' />
            <span className='text-[13px]'>Update Bill</span>
        </Button>
    )
}

export const AutoClear = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-green-600 rounded-[3px] py-1.5 px-1.5" disabled={false}>
            <MdOutlineAutoMode className='text-[19px]' />
            <span className='text-[13px]'>Auto Bill Extact </span>
        </Button>
    )
}
export const ReturnButton = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-[3px] py-1.5 px-1.5" disabled={false}>
            <MdOutlineKeyboardReturn className='text-[19px]' />
            <span className='text-[13px]'>Return</span>
        </Button>
    )
}
export const ViewBtn = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-[3px] py-1.5 px-1.5" disabled={false}>
            <TiEye className='text-[19px]' />
            <span className='text-[13px]'>View</span>
        </Button>
    )
}
export const PrintBtn = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-gray600 rounded-[3px] py-1.5 px-1.5" onClick={onClick} disabled={false}>
            <MdPrint className='text-[19px]' />
            <span className='text-[13px]'>Print</span>
        </Button>
    )
}
export const SearchBtn = ({ onClick, type = "button", disabled = false, isSubmitting = false }) => {
    return (
        <Button
            type={type}
            onClick={onClick}
            size="sm"
            color="white"
            className={`flex items-center gap-2 px-2 py-1.5 text-white capitalize rounded-md ${isSubmitting || disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-customPrimary'
                }`}
            disabled={isSubmitting || disabled}
        >
            <IoSearchOutline className="text-[19px] font-normal" />
            <span className="text-[13px] font-normal">
                {isSubmitting ? "Loading..." : "Search"}
            </span>
        </Button>
    );
};
export const CloseBtn = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-orange-500 rounded-[3px] py-1.5 px-1.5" disabled={false}>
            <IoClose className='text-[19px]' />
            <span className='text-[13px]'>Close</span>
        </Button>
    )
}
export const UpgradeBtn = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-[3px] py-1.5 px-1.5" disabled={false}>
            <GrUpgrade className='text-[19px]' />
            <span className='text-[13px]'>Upgrade</span>
        </Button>
    )
}


export const ClearBtn = ({ onClick, type, disabled }) => {
    return (
        <Button type={type || "button"} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-gray-700 rounded-[3px] px-1.5 py-1.5" disabled={disabled || false}>
            <MdClear className='text-[19px]' />
            <span className='text-[13px] my-auto'>Clear</span>
        </Button>
    )
}

export const AddBtn = ({ onClick, type, disabled, label }) => {
    return (
        <Button type={type || "button"} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-[3px] px-1.5 py-1.5" disabled={disabled || false}>
            <IoAdd className='text-[19px]' />
            <span className='text-[13px] my-auto'>{label}</span>
        </Button>
    )
}
export const CardBtn = ({ onClick, type, icon, disabled, label }) => {
    return (
        <Button type={type || "button"} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-md px-3 py-1.5" disabled={disabled || false}>
            <span className='text-[19px]'>{icon}</span>
            <span className='text-[13px] my-auto'>{label}</span>
        </Button>
    )
}

export const UpdateBtn = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" 
        className="flex text-white items-center capitalize gap-2 bg-green-500 rounded-[3px] py-1.5 px-1.5" disabled={disabled || false}>
            <RxUpdate className='text-[19px]' />
            <span className='text-[13px]'>{disabled?"Loading..":"Update"} </span>
        </Button>
    )
}
export const DownloadBtn = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" 
        className="flex text-white items-center capitalize gap-2 bg-green-500 rounded-[3px] py-1.5 px-1.5" disabled={disabled || false}>
            <BiDownload className='text-[19px]' />
            <span className='text-[13px]'>Download</span>
        </Button>
    )
}