import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

const AppLoading = () => {
    return (
        <>
            <div className='w-auto h-screenHeight flex items-center justify-center'>
                <ThreeDots
                    height="50"
                    width="55"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </div>
        </>
    )
}

export default AppLoading