import React from 'react'

const ReportHeading = ({heading}) => {
    return (
        <div className='font-public-sans text-md font-semibold text-slate700 dark:text-slate300'>
            <p>{heading}</p>
            <div className='-mt-2 text-red-500 font-normal'><span className='text-[10px] font-public-sans'>All the fields marked with an asterisk (*) are mandatory.
            </span>
            </div>
        </div>
    )
}

export default ReportHeading