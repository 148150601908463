import React, { Fragment, useEffect } from 'react'
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { useDispatch } from 'react-redux';
import { EMIS_RPT_Options_List } from '../../../../store/emis_rptSlice';
import EnrollmentSearchFormView from '../../../../components/exam/report/enrollments/EnrollmentSearchFormView';

const EMISEnrollmentFormView = () => {
    const dispatch = useDispatch();
    const EMISReportListView = async ()=>{
        try{
            await fetchDataFromApi(AppURL.EMISRPT+"emis-rpt-options-serach-list-view/").then((res)=>{
                dispatch(EMIS_RPT_Options_List(res));
            });
        }catch(error){
            console.log(error);
        }
    }
    useEffect(()=>{
        EMISReportListView();
    },[]);
    return (
        <Fragment>
            <EnrollmentSearchFormView />
        </Fragment>
    )
}

export default EMISEnrollmentFormView