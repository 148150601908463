import React from "react";
import { HiAcademicCap } from "react-icons/hi";
import { MdOutlineMenuBook } from "react-icons/md";
import { MdInventory } from "react-icons/md";
import { SiAlipay } from "react-icons/si";
import { FaUsersViewfinder } from "react-icons/fa6";
import { FaUserTie } from "react-icons/fa";
import { HiBookOpen } from "react-icons/hi2";
import { AiFillAppstore } from "react-icons/ai";
import { RiAccountBoxFill } from "react-icons/ri";
import { IoIosPaper } from "react-icons/io";
import { MdMobileScreenShare } from "react-icons/md";
import { PiStudentFill } from "react-icons/pi";

export const ImportMenuIcon = (icon) => {
    switch (icon) {
        case 'AiFillAppstore':
            return <AiFillAppstore />;
        case 'MdOutlineMenuBook':
            return <MdOutlineMenuBook />
        case 'HiAcademicCap':
            return <HiAcademicCap />
        case 'RiAccountBoxFill':
            return <RiAccountBoxFill />;
        case 'MdInventory':
            return <MdInventory />;
        case 'FaUsersViewfinder':
            return <FaUsersViewfinder />;
        case 'SiAlipay':
            return <SiAlipay />;
        case 'FaUserTie':
            return <FaUserTie />;
        case 'HiBookOpen':
            return <HiBookOpen />;
        case 'IoIosPaper':
            return <IoIosPaper />;
        case 'MdMobileScreenShare':
            return <MdMobileScreenShare />;
        case 'PiStudentFill':
            return <PiStudentFill />;
        default:
            return null;
    }
};





