import React, { Fragment } from 'react'
import DayBookFormView from '../../../../components/account/report/day_book/DayBookFormView'

const AccountDayBookSearchFormView = () => {

  return (
    <Fragment>
        <DayBookFormView />
    </Fragment>
  )
}

export default AccountDayBookSearchFormView