import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { SidebarReportClose } from '../../../../store/softSlice';
import ReportHeading from '../../../../widgets/ReportHeading';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { useLanguage } from '../../../../widgets/LangContext';
import { AcademicsYearOptions, ExamTypeOptions, ProgramOptions, SubjectOptions, YearListOptions, YearSemesterOptions } from '../../../../widgets/Options';
import { ProgramWiseSemester } from '../../../../widgets/ProgramWiseSemester';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { SubjectWiseMarksListView } from '../../../../store/examSlide';

const ExamMarksFormView = () => {
    const { language } = useLanguage();
    const dispatch = useDispatch();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }
    const navigate = useNavigate();
    const [onSubjList, setOnSubjList] = useState([]);
    const { emis_rpt_options_list, program_wise_year_sem_list } = useSelector((state) => state.emis_rpt);

    // const {emis_rpt_options_list} = useSelector((state)=>state.emis_rpt);
    const [selectValues, setOnSelectValues] = useState({
        program: "",
        acdmc_year: "",
        year_semester: "",
        medium: "",
        sec: "",
        team: "",
        shift: "",
        subjects: "",
    });

    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
        if (fieldName === 'program') {
            setOnSelectValues((prevValues) => ({
                ...prevValues,
                subjects: "",
                year_semester: ""
            }));
        }
        if (fieldName === 'year_semester') {
            setOnSelectValues((prevValues) => ({
                ...prevValues,
                subjects: "",
            }));
        }
    };
    const ProgramOption = ProgramOptions(emis_rpt_options_list?.program);
    const AcademicsYearOption = AcademicsYearOptions(emis_rpt_options_list?.acdmc_year);
    const ExamOptions = ExamTypeOptions(emis_rpt_options_list?.exam_type);
    const fetchYearSemesterMajorSubList = async () => {
        if (selectValues?.program) {
            ProgramWiseSemester(selectValues?.program?.value, dispatch);
        }
    }
    const fetchSubjectList = async () => {
        if (selectValues?.program?.value, selectValues?.year_semester?.value) {
            await fetchDataFromApi(AppURL.ExamURL + "exam-student-class-wise-subject-list-view/?program=" + selectValues?.program?.value + '&&year_semester=' + selectValues?.year_semester?.value).then((res) => {
                setOnSubjList(res);
            });
        }
    }
    useEffect(() => {
        fetchYearSemesterMajorSubList();
        fetchSubjectList();
    }, [selectValues?.program, selectValues?.program?.value, selectValues?.year_semester?.value]);

    const YearSemesterOption = YearSemesterOptions(program_wise_year_sem_list);
    const SubjectOption = SubjectOptions(onSubjList?.results);
    const initialValues = {
        program: "",
        acdmc_year: "",
        year_semester: "",
    };
    // /emis-passout-report-page-list-view
    const { errors, touched, handleBlur, isSubmitting, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("acdmc_year", selectValues?.acdmc_year?.value || "0");
                formData.append("program", selectValues?.program?.value || "0");
                formData.append("year_semester", selectValues?.year_semester?.value || "0");
                await fetchDataFromApi(AppURL.ExamURL + "exam-marks-ledger-reports-list-view/?program=" + selectValues?.program?.value + '&&year_semester=' + selectValues?.year_semester?.value + '&&acdmc_year=' + selectValues?.acdmc_year?.value + '&&ex_type_id=' + selectValues?.ex_type_id?.value + '&&subjects=' + selectValues?.subjects?.value).then((res) => {
                    dispatch(SubjectWiseMarksListView(res));
                    CloseDispatch();
                    navigate("/exam-subject-list-view-search");
                });
                CloseDispatch();
                navigate("/exam-marks-report-page-list-view");
            }
        });

    return (
        <div className=''>
            <ReportHeading heading="Marks Report" />
            <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                    <Grid container rowSpacing={1}>
                        <Grid item xl={12} xs={12} md={12} sm={12} lg={12}>
                            <SelectOptionBox label={language ? "Program" : "स्तर"} value={selectValues.program} options={ProgramOption} name="program" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.program} errors={errors.program} required={true} />
                        </Grid>
                        <Grid item xl={12} xs={12} md={12} sm={12} lg={12}>
                            <SelectOptionBox label={language ? "Academics Year" : "शैक्षिक वर्ष"} value={selectValues.acdmc_year} options={AcademicsYearOption} name="acdmc_year" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.acdmc_year} errors={errors.acdmc_year} required={true} />
                        </Grid>
                        <Grid item xl={12} xs={12} md={12} sm={12} lg={12}>
                            <SelectOptionBox label={language ? "Year/Semester" : "वर्ष/सेमेस्टर"} value={selectValues.year_semester} options={YearSemesterOption} name="year_semester" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.year_semester} errors={errors.year_semester} required={true} />
                        </Grid>
                        <Grid item xl={12} xs={12} md={12} sm={12} lg={12}>
                            <SelectOptionBox label={language ? "Exam Type" : "परीक्षा प्रकार"} value={selectValues.ex_type_id} options={ExamOptions} name="ex_type_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.ex_type_id} errors={errors.ex_type_id} required={true} />
                        </Grid>
                        <Grid item xl={12} xs={12} md={12} sm={12} lg={12}>
                            <SelectOptionBox label={language ? "Subjects" : "विषयहरू"} value={selectValues.subjects} options={SubjectOption} name="subjects" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.subjects} errors={errors.subjects} required={true} />
                        </Grid>
                    </Grid>
                    <div className='flex justify-between mt-4'>
                        <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn isSubmitting={isSubmitting} type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ExamMarksFormView