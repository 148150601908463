import React, { useEffect, useState } from 'react'
import TeacherListDetails from '../../../../components/hr/report/teacher_det_list/TeacherListDetails'
import AppURL from '../../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../../widgets/api';
import { pageTitle } from '../../../../store/softSlice';
import { useDispatch } from 'react-redux';
import Container from '../../../../widgets/Container';

const TeacherDetailsReportsListView = ({PageTitle, setIconsButton}) => {
  const dispatch = useDispatch();
    useEffect(() => {
      dispatch(pageTitle({ "title": PageTitle }));
      setIconsButton(null);
      document.title = `Nexapp - ${PageTitle}`;
    }, []);
  const [onEmployeeList, setOnEmployeeList] = useState([]);
  const url = AppURL.HR_URL+"hr-employee-teacher-details-list-view/";
  const fetchAcademicsListView = async (urls) => {
      try {
          await fetchDataFromApi(urls).then((res) => {
              setOnEmployeeList(res);
          });
      } catch (error) {
          console.log(error);
      }
  }
  const FetchData = async () => {
      await fetchAcademicsListView(url);
  }
  useEffect(() => {
      FetchData();
  }, []);
  
  return (
    <Container>
      <TeacherListDetails onEmployeeList={onEmployeeList} />
    </Container>
  )
}

export default TeacherDetailsReportsListView