import { ADToBS, BSToAD } from 'bikram-sambat-js';
import { ToastError } from './ToastMessage';
import { useSelector } from 'react-redux';

// 2024-01-22 - 2080/10/08
export const ADToBSConvert = (date) => {
    const NepaliDate = require('nepali-date');
    const convertedNepaliDate = ADToBS(date ? date : "");
    let NPDate = new NepaliDate(convertedNepaliDate);
    return NPDate.format('YYYY/MM/DD');
}



// 2024-01-22 - 2080/10/08
export const ADToBSConvertClearDate = (date) => {
    const NepaliDate = require('nepali-date');
    const convertedNepaliDate = ADToBS(date ? date : "");
    let NPDate = new NepaliDate(convertedNepaliDate);
    const nepaliDate = NPDate.format('YYYY/MM/DD');

}



// 2080/10/08 - 2080.10.08
export const NepaliACDateFormat = (date) => {
    const NepaliDate = require('nepali-date');
    let NPDate = new NepaliDate(date);
    return NPDate.format('YYYY.MM.DD');
}
// 2024-02-15 - Current Date
export const EngNowDate = () => {
    const date = new Date()
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

// 2024/02/15 - Current Date
export const EngNowDateFormat = () => {
    const date = new Date()
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
};

//2080-02-22 - 2080/02/22
export const FormatDate = (onENSelectDate) => {
    const ENDate = new Date(onENSelectDate ? onENSelectDate : "");
    const year = ENDate.getFullYear();
    const month = String(ENDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(ENDate.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
}

//2080/02/22 - 2080-02-22
export const FormatRevDate = (onENSelectDate) => {
    const ENDate = new Date(onENSelectDate ? onENSelectDate : "");
    const year = ENDate.getFullYear();
    const month = String(ENDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(ENDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}


// 2080/11/03 - Now Nepali Date
export const NepNowDateFormat = () => {
    const { menu_list } = useSelector((state) => state?.software);
    const CurrentDate = menu_list?.np_date;
    return CurrentDate;
}

export const NepNowDateFormatFirst = () => {
    const NepaliDate = require('nepali-date');
    const Years = new NepaliDate().format("YYYY");
    const Month = new NepaliDate().format("MM");
    const Dates = `${Years}/${Month}/01`
    return Dates;
}
export const EngNowDateFormatFirst = () => {
    const NepaliDate = require('nepali-date');
    const Years = new NepaliDate().format("YYYY");
    const Month = new NepaliDate().format("MM");
    const Dates = `${Years}-${Month}-01`;
    const convertedNepaliDate = BSToAD(Dates ? Dates : "");
    const ENDate = new Date(convertedNepaliDate ? convertedNepaliDate : "");
    const en_year = ENDate.getFullYear();
    const en_month = String(ENDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const en_day = String(ENDate.getDate()).padStart(2, '0');
    return `${en_year}/${en_month}/${en_day}`;
}


const NepaliDate = require('nepali-date');
export const BSToADFormat = (dates) => {
    console.log(dates)
    try {
        // Assuming dates input is in the format 'YYYY/MM/DD'
        const parts = dates.split('/');
        if (parts.length !== 3) {
            ToastError("Invalid date format. Please use YYYY/MM/DD.");
            return;
        }
        const year = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10);
        const day = parseInt(parts[2], 10);
        if (year < 1970 || year > 2100) {
            ToastError("BS year should be in range of 1970 to 2100.");
            return;
        }
        // Creating a Nepali date object to see if it throws an error (invalid Nepali date)
        const nepaliDate = new NepaliDate(year, month - 1, day); // Months are 0-based in JavaScript

        // Formatting to AD and checking conversion
        const ENDate = new Date(nepaliDate.toJsDate().toISOString());
        const formattedDate = `${ENDate.getFullYear()}-${String(ENDate.getMonth() + 1).padStart(2, '0')}-${String(ENDate.getDate()).padStart(2, '0')}`;

        // Check if the date actually converts properly
        if (!Date.parse(formattedDate)) {
            throw new Error("Conversion to AD failed.");
        }

        return formattedDate;
    } catch (error) {
        // Handle cases where date is not valid or conversion fails
        ToastError("Invalid Nepali date");
        console.error(error);
        return; // Optionally return null or undefined
    }
};

// 2080/11/03 - 2024/10/20
export const BSToADUSFormat = (dates) => {
    try {
        const NepaliDate = require('nepali-date');
        const NepaliMonth = new NepaliDate(dates);
        const year = NepaliMonth.format("YYYY");
        const month = NepaliMonth.format("MM");
        const day = NepaliMonth.format("DD");
        const NPBSToAD = `${year}-${month}-${day}`;

        const convertedNepaliDate = BSToAD(NPBSToAD);
        const ENDate = new Date(convertedNepaliDate);

        const en_year = ENDate.getFullYear();
        const en_month = String(ENDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const en_day = String(ENDate.getDate()).padStart(2, '0');
        return `${en_year}/${en_month}/${en_day}`;
    } catch (error) {
        // Return a known error state or message
        return "Invalid Date";
    }
}
// 2080/11/03 - 2024-10-20
export const BSToADUSNPFormat = (dates) => {
    try {
        const NepaliDate = require('nepali-date');
        const NepaliMonth = new NepaliDate(dates);
        const year = NepaliMonth.format("YYYY");
        const month = NepaliMonth.format("MM");
        const day = NepaliMonth.format("DD");
        const NPBSToAD = `${year}-${month}-${day}`;

        const convertedNepaliDate = BSToAD(NPBSToAD);
        const ENDate = new Date(convertedNepaliDate);

        const en_year = ENDate.getFullYear();
        const en_month = String(ENDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const en_day = String(ENDate.getDate()).padStart(2, '0');
        return `${en_year}-${en_month}-${en_day}`;
    } catch (error) {
        // Return a known error state or message
        return "Invalid Date";
    }
}
// 2080-11-03 - Now Nepali Date
export const NepNowDateFormatRev = () => {
    const date = new Date()
    const NepaliDate = require('nepali-date');
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const EnglishDate = `${year}-${month}-${day}`;
    const convertedNepaliDate = ADToBS(EnglishDate ? EnglishDate : "");
    let NPDate = new NepaliDate(convertedNepaliDate);
    return NPDate.format('YYYY-MM-DD');
}
// 2080/10/20 - 2024/02/30
export const BSToADConvert = (date) => {
    const DateFormat = FormatRevDate(date ? date : "");
    const ADDated = BSToAD(DateFormat);
    const FormatedDate = FormatDate(ADDated);
    return FormatedDate;
}


// Month Name Number Return 2080/08/24 = 08
export const GetMonthNumber = (date) => {
    const NepaliDate = require('nepali-date');
    const NepaliMonth = new NepaliDate(date || "2080/08/24");
    return NepaliMonth.format("MM");
}

// Month Name Number Return 2080/08/24 = 2080
export const GetYearNumber = (date) => {
    const NepaliDate = require('nepali-date');
    const NepaliMonth = new NepaliDate(date || "2080/08/24");
    return NepaliMonth.format("YYYY");
}


// Default Year Name Nepali
export const NowNepaliYearName = () => {
    const date = new Date()
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const DefaultADDate = `${year}-${month}-${day}`;
    const NepaliDate = require('nepali-date');
    const convertedNepaliDate = ADToBS(DefaultADDate ? DefaultADDate : "");
    let NPDate = new NepaliDate(convertedNepaliDate)
    const NepaliMonth = new NepaliDate(NPDate || "2080/08/24");
    return NepaliMonth.format("YYYY");
}

// Default Month Name Nepali
export const NowNepaliMonthName = () => {
    const date = new Date()
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const DefaultADDate = `${year}-${month}-${day}`;
    const NepaliDate = require('nepali-date');
    const convertedNepaliDate = ADToBS(DefaultADDate ? DefaultADDate : "");
    let NPDate = new NepaliDate(convertedNepaliDate)
    const NepaliMonth = new NepaliDate(NPDate || "2080/08/24");
    return NepaliMonth.format("MM");
}


