import React, { Fragment } from 'react';
import IssueReturnForm from '../../../../components/library/report/issue_return_report/IssueReturnForm';

const LibraryIssueReturnRptFormView = () => {
  return (
    <Fragment>
      <IssueReturnForm />
    </Fragment>
  )
}

export default LibraryIssueReturnRptFormView