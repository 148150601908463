import React, { Fragment } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ImageLazyLoading = ({ image, alt, height, className, width, src }) => {
  return (
    <Fragment>
      <LazyLoadImage
        className={className}
        alt={alt}
        height={height}
        src={src} 
        width={width}
        draggable={false}
      />
    </Fragment>
  );
};

export default ImageLazyLoading;
