// import { Grid } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
// import SelectOptionBox from '../../../../widgets/SelectOptionBox'
import { useFormik } from 'formik';
// import { useLanguage } from '../../../../widgets/LangContext';
import ReportHeading from '../../../../widgets/ReportHeading';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { SidebarReportClose } from '../../../../store/softSlice';
import { useDispatch, useSelector } from 'react-redux';
// import { AcademicsYearOptions, ProgramOptions, YearSemesterOptions } from '../../../../widgets/Options';
// import { ProgramWiseSemester } from '../../../../widgets/ProgramWiseSemester';
import { useNavigate } from 'react-router-dom';
import { fetchDataFromApi, postDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { EMISReportInrollmentListView } from '../../../../store/emis_rptSlice';

const EnrollmentSearchFormView = () => {
    // const { language } = useLanguage();
    const dispatch = useDispatch();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }
    const navigate = useNavigate();

    // const { emis_rpt_options_list, program_wise_year_sem_list } = useSelector((state) => state.emis_rpt);

    // // const {emis_rpt_options_list} = useSelector((state)=>state.emis_rpt);
    // const [selectValues, setOnSelectValues] = useState({
    //     program: "",
    //     acdmc_year: "",
    //     year_semester: "",
    //     medium: "",
    //     sec: "",
    //     team: "",
    //     shift: "",
    //     maj_subj: "",
    // });

    // const optionHandleSelect = (fieldName, data) => {
    //     handleChange(fieldName)(data.value)
    //     setOnSelectValues((prevValues) => ({
    //         ...prevValues,
    //         [fieldName]: data,
    //     }));
    // };
    // const ProgramOption = ProgramOptions(emis_rpt_options_list?.program);
    // const AcademicsYearOption = AcademicsYearOptions(emis_rpt_options_list?.acdmc_year);
    // const fetchYearSemesterMajorSubList = async () => {
    //     if (selectValues?.program) {
    //         ProgramWiseSemester(selectValues?.program?.value, dispatch);
    //     }
    // }
    // useEffect(() => {
    //     fetchYearSemesterMajorSubList();
    // }, [selectValues?.program]);
    // const YearSemesterOption = YearSemesterOptions(program_wise_year_sem_list);
    const initialValues = {
        program: "",
        acdmc_year: "",
        year_semester: "",
        medium: "",
        sec: "",
        team: "",
        shift: "",
        maj_subj: "",
    };
    // /emis-passout-report-page-list-view
    const { errors, touched, handleBlur, isSubmitting, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                await postDataToTheServer(AppURL.EMISRPT + "emis-inrollment-report-create-list-view/").then((res)=>{
                    dispatch(EMISReportInrollmentListView(res));
                    CloseDispatch();
                    navigate("/emis-enrollments-page-list-view");
                });
            }
        });
    return (
        <Fragment>
            <ReportHeading heading="Enrollment Report" />
            <form onSubmit={handleSubmit} className='mt-5'>
  
                <div className='flex justify-between mt-4'>
                    <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn isSubmitting={isSubmitting} type="submit" />
                </div>
            </form>
        </Fragment>
    )
}

export default EnrollmentSearchFormView