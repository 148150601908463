import React, { useState } from 'react';
import {
    Dialog,
    DialogBody,
} from "@material-tailwind/react";
import { useFormik } from 'formik';
import { postDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { Grid } from '@mui/material';
import InputTextBox from '../../../../widgets/InputTextBox';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { TbPasswordUser } from "react-icons/tb";
import { UpdateBtn } from '../../../../widgets/AppBtn';
import { ToastSuccess } from '../../../../widgets/ToastMessage';

const PasswordChangeModel = ({ changePassword, setOnChangePassword }) => {
    const handleOpen = () => setOnChangePassword(!changePassword);

    const initialValues = {
        current_password: "",
        new_password: "",
        confirm_password: ""
    };
    const member_sn = localStorage.getItem("user_id");
    const [message, setOnMessage] = useState("");
    const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
        useFormik({
            initialValues: initialValues,
            validate: values => {
                const errors = {};
                if (!values.current_password) {
                    errors.current_password = 'Required';
                }
                if (!values.new_password) {
                    errors.new_password = 'Required';
                } else if (values.new_password.length < 8) {
                    errors.new_password = 'Password must be at least 8 characters long';
                }
                if (!values.confirm_password) {
                    errors.confirm_password = 'Required';
                } else if (values.confirm_password !== values.new_password) {
                    errors.confirm_password = 'Passwords must match';
                }
                return errors;
            },
            onSubmit: async (values, actions) => {
                const formData = new FormData();
                formData.append("user_id", member_sn);
                formData.append("current_password", values.current_password);
                formData.append("new_password", values.new_password);

                try {
                    const res = await postDataToTheServer(AppURL.ApplicationURL + "app-author-user-retrieve-and-change-password-update/", formData);
                    setOnMessage(res);
                    if (res?.status === 200) {
                        ToastSuccess(res?.message);
                        setOnChangePassword(false);
                    } else {
                        // Handle error
                        console.log('Error:', res);
                    }
                } catch (error) {
                    console.log('Error:', error);
                }
            },
        });

    return (
        <Dialog open={changePassword} size='xs' className='ml-[120px] dark:bg-slate800' handler={handleOpen}>
            <DialogBody>
                <ComponentHeading icon={TbPasswordUser} label={'Change Password'} />
                <form onSubmit={handleSubmit}>
                    <div className='p-2'>
                        {
                            message?.status&& <div></div>
                        }
                        <Grid container spacing={1}>
                            <Grid item xl={12} xs={12} md={12} sm={12}>
                                <InputTextBox
                                    label={'Current Password'}
                                    type={'password'}
                                    name="current_password"
                                    value={values.current_password}
                                    handleChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.current_password && errors.current_password}
                                />
                                {touched.current_password && errors.current_password ? (
                                    <div className="text-red-600 text-[10px] mt-1">{errors.current_password}</div>
                                ) : null}
                            </Grid>
                            <Grid item xl={12} xs={12} md={12} sm={12}>
                                <InputTextBox
                                    label={'New Password'}
                                    type={'password'}
                                    name="new_password"
                                    value={values.new_password}
                                    handleChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.new_password && errors.new_password}
                                />
                                {touched.new_password && errors.new_password ? (
                                    <div className="text-red-600 text-[10px] mt-1">{errors.new_password}</div>
                                ) : null}
                            </Grid>
                            <Grid item xl={12} xs={12} md={12} sm={12}>
                                <InputTextBox
                                    label={'Confirm Password'}
                                    type={'password'}
                                    name="confirm_password"
                                    value={values.confirm_password}
                                    handleChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.confirm_password && errors.confirm_password}
                                />
                                {touched.confirm_password && errors.confirm_password ? (
                                    <div className="text-red-600 text-[10px] mt-1">{errors.confirm_password}</div>
                                ) : null}
                            </Grid>
                            <Grid item xl={12} xs={12} md={12} sm={12}>
                                <div className='flex justify-end mt-3'>
                                    <UpdateBtn type="submit" />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </DialogBody>
        </Dialog>
    );
};

export default PasswordChangeModel;
