import React, { Fragment, Suspense, useEffect } from 'react';
import { Route, Routes, HashRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import "nepali-datepicker-reactjs/dist/index.css";
import DefaultLayouts from './layouts/DefaultLayouts';
import AppLoading from './components/common/AppLoading';
import RequireAuth from './widgets/RequireAuth';
import LoginAuthPageView from "./views/auth/LoginAuthPageView";
import AuthContext from './widgets/AuthContext';
const App = () => {
  const token = localStorage.getItem("access_token");
  useEffect(() => {
  }, [token])
  return (
    <Fragment>
      <HashRouter>
        <Suspense fallback={<AppLoading />}>
          <Routes>
            <Route path="/" element={<LoginAuthPageView />} />
            {/* <Route path="/renew-license" element={<RenewLicenseView />} /> */}
            <Route exact element={<RequireAuth />}>
              <Route path="*" element={<DefaultLayouts />} />
            </Route>
          </Routes>
        </Suspense>
      </HashRouter>
      <Toaster />
    </Fragment>
  );
};

export default App;