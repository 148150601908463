import React, { Fragment, useEffect, useState } from 'react'
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import AdmissionYearStdFormView from '../../../../components/exam/report/admission_year_std_rpt/AdmissionYearStdFormView';

const AdmissionYearStudentFormView = () => {
    const [onReportOptions, setOnReportOptions] = useState([]);
    const ExamReportOptionView=async ()=>{
        try{
            await fetchDataFromApi(AppURL.EMISRPT+"exam-marks-emis-report-options-list-view/").then((res)=>{
                setOnReportOptions(res);
            });
        }catch(error){
            console.log(error);
        }
    }
    useEffect(()=>{
        ExamReportOptionView();
    },[])
  return (
    <Fragment>
        <AdmissionYearStdFormView onReportOptions={onReportOptions} />
    </Fragment>
  )
}

export default AdmissionYearStudentFormView