import React, { useState } from 'react';
export const LoginContext = React.createContext();

const AuthContext = ({ children }) => {
    const [authUser, setOnAuthUser] = useState([]);
    return (
        <div>
            <LoginContext.Provider value={{ authUser, setOnAuthUser }}>
                {children}
            </LoginContext.Provider>
        </div>
    )
}
export default AuthContext