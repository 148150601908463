import React, { Fragment } from 'react'
import BalanceSheetRptFormView from '../../../../components/account/report/balance_sheet_rpt/BalanceSheetRptFormView'

const AccountBalanceSheetRptFormView = () => {
  return (
    <Fragment>
        <BalanceSheetRptFormView />
    </Fragment>
  )
}

export default AccountBalanceSheetRptFormView