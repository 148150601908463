import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import ReportHeading from '../../../../widgets/ReportHeading';
import { FacultyOptions, IssuedForOptions, LevelOptions, MajorSubjectOptions, MediumOptions, ProgramOptions, YearSemesterOptions } from '../../../../widgets/Options';
import { issued_for_list, lanugage_medium } from '../../../../widgets/json';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { useNavigate } from 'react-router-dom';
import { LibrarySearchInformationListView } from '../../../../store/librarySlice';
import { useDispatch } from 'react-redux';
import { SidebarReportClose } from '../../../../store/softSlice';

const SearchInfoFormRptView = ({ onLibraryOptions }) => {
    const languageOptions = MediumOptions(lanugage_medium);
    const IssueForOption = IssuedForOptions(issued_for_list);
    const LevelOption = LevelOptions(onLibraryOptions?.level);
    const FacultyOption = FacultyOptions(onLibraryOptions?.faculty);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }
    const [selectValues, setOnSelectValues] = useState({
        fac_id: "",
        level_id: "",
        program: "",
        AcademicYear: "",
        year_semester: "",
        major_id: "",
        issued_for: "",
        BookKind: "",
        language: "",
    });

    const optionHandleSelect = (fieldName, data) => {
        if (data === null || data.value === "") {
            // If data is null or empty, clear the input value
            handleChange(fieldName)("");
            setOnSelectValues((prevValues) => ({
                ...prevValues,
                [fieldName]: "",
            }));
        } else {
            // Otherwise, update the input value as usual
            handleChange(fieldName)(data.value);
            setOnSelectValues((prevValues) => ({
                ...prevValues,
                [fieldName]: data,
            }));
        }
    };
    const [onProgramList, setOnProgramList] = useState([]);
    useEffect(() => {
        const FetchProgramList = async () => {
            if (selectValues?.level_id && selectValues?.fac_id) {
                try {
                    await fetchDataFromApi(AppURL.AcademicsURL + "academic-student-program-search-fac_id-level_id/?level_id=" + selectValues?.level_id?.value + "&fac_id=" + selectValues?.fac_id?.value).then((res) => {
                        setOnProgramList((prevValues) => ({
                            ...prevValues,
                           "program": res
                        }));
                    });
                } catch (error) {
                    console.log(error);
                }
            }

            if (selectValues?.program) {
                try {
                    await fetchDataFromApi(AppURL.AcademicsURL + "academic-student-year-semester-filter-list/?program_id=" + selectValues?.program?.value).then((res) => {
                        setOnProgramList((prevValues) => ({
                            ...prevValues,
                           "sem_maj_sub": res
                        }));
                    });
                } catch (error) {
                    console.log(error);
                }
            }

        }
        FetchProgramList();
    }, [selectValues?.level_id, selectValues?.fac_id, selectValues?.program])

    const ProgramOption = ProgramOptions(onProgramList?.program);
    const year_semesterOption = YearSemesterOptions(onProgramList?.sem_maj_sub?.year_semester);
    const MajorSubjOption = MajorSubjectOptions(onProgramList?.sem_maj_sub?.major_subject);
    const initialValues = {
        fac_id: "",
        level_id: "",
        program: "",
        year_semester: "",
        major_id: "",
        issued_for: "",
        language: "",
        radio: '1'
    };

    const { errors, touched, values, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues,
        onSubmit: async (values, action) => {
            let url = `${AppURL.LibraryURL+"library-book-search-information-report-list-view/?branch_id=" + 1}`;
            if (selectValues.fac_id) {
                url += `&fac_id=${selectValues?.fac_id?.value}`;
            }
            if (selectValues.level_id) {
                url += `&level_id=${selectValues.level_id.value}`;
            }
            if (selectValues.program) {
                url += `&program=${selectValues.program.value}`;
            }
            if (selectValues.year_semester) {
                url += `&year_semester=${selectValues.year_semester.value}`;
            }
            if (selectValues.major_id) {
                url += `&major_id=${selectValues.major_id.value}`;
            }
            if (selectValues.language) {
                url += `&langauge=${selectValues.language.value}`;
            }
            if (selectValues.issued_for) {
                url += `&issued_for=${selectValues.issued_for.value}`;
            }
            if (values?.radio) {
                url += `&radio=${values?.radio}`;
            }
            try {
                const res = await fetchDataFromApi(url);
                CloseDispatch();
                dispatch(LibrarySearchInformationListView(res));
                navigate("/library-search-information-report-list-view");
              } catch (error) {
                console.log(error);
              }
        },
    });


    return (
        <div>
            <ReportHeading heading="Search Report" />
            <form onSubmit={handleSubmit} className='mt-2'>
                <Grid container rowSpacing={1} columnSpacing={1}>
                    <Grid item xl={6} sm={6} lg={6} md={6} xs={12}>
                        <SelectOptionBox label="Faculty" value={selectValues.fac_id} name="fac_id" options={FacultyOption} clear={true} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.fac_id} errors={errors.fac_id} required={true} />
                    </Grid>
                    <Grid item xl={6} sm={6} lg={6} md={6} xs={12}>
                        <SelectOptionBox label="Level" value={selectValues.level_id} name="level_id" clear={true} options={LevelOption} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.level_id} errors={errors.level_id} required={false} />

                    </Grid>
                    <Grid item xl={12} sm={12} lg={12} md={12} xs={12}>
                        <SelectOptionBox label="Program" value={selectValues.program} name="program" options={ProgramOption} clear={true} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.program} errors={errors.program} required={false} />

                    </Grid>
                    <Grid item xl={12} sm={12} lg={12} md={12} xs={12}>
                        <SelectOptionBox label="Year Semester" value={selectValues.year_semester} name="year_semester" options={year_semesterOption} clear={true} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.year_semester} errors={errors.year_semester} required={false} />

                    </Grid>
                    <Grid item xl={12} sm={12} lg={12} md={12} xs={12}>
                        <SelectOptionBox label="Major Subject" value={selectValues.major_id} name="major_id" options={MajorSubjOption} clear={true} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.major_id} errors={errors.major_id} required={false} />

                    </Grid>
                    <Grid item xl={12} sm={12} lg={12} md={12} xs={12}>
                        <SelectOptionBox label="Staff / Student" value={selectValues.issued_for} name="issued_for" clear={true} options={IssueForOption} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.issued_for} errors={errors.issued_for} required={false} />
                    </Grid>
                    <Grid item xl={12} sm={12} lg={12} md={12} xs={12}>
                        <SelectOptionBox label="language" value={selectValues.language} name="language" clear={true} options={languageOptions} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.language} errors={errors.language} required={false} />

                    </Grid>
                    <Grid item xl={12} sm={12} lg={12} md={12} xs={12}>
                        <FormControl className="sms__sidebar___form__container__style__student d-flex justify-content-between">
                            <RadioGroup
                                value={values.radio}
                                onChange={handleChange}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="radio"
                            >
                                <FormControlLabel
                                    value="3"
                                    control={<Radio size="small" className='dark:text-gray-400 !text-customPrimary' sx={{

                                        "&, & + .MuiFormControlLabel-label": {
                                            color: "#242424",
                                            fontFamily: 'Public Sans, sans-serif',
                                            fontSize: "13px",
                                            fontWeight: 600
                                        }

                                    }} />}
                                    label="All"
                                />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio size="small" className='dark:text-gray-400 !text-customPrimary' sx={{

                                        "&, & + .MuiFormControlLabel-label": {
                                            color: "#242424",
                                            fontFamily: 'Public Sans, sans-serif',
                                            fontSize: "13px",
                                            fontWeight: 600
                                        }

                                    }} />}
                                    label="Issued"
                                />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio size="small" className='dark:text-gray-400 !text-customPrimary' sx={{

                                        "&, & + .MuiFormControlLabel-label": {
                                            color: "#242424",
                                            fontFamily: 'Public Sans, sans-serif',
                                            fontSize: "13px",
                                            fontWeight: 600
                                        }

                                    }} />}
                                    label="Available"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xl={12} sm={12} lg={12} md={12} xs={12}>
                        <div className="flex justify-between w-full mt-3">
                            <ClearBtn type={'button'} onClick={CloseDispatch} /> <SearchBtn type={'buton'} onClick={handleSubmit} />
                        </div>
                    </Grid>
                </Grid>{" "}
            </form>
        </div>
    )
}

export default SearchInfoFormRptView