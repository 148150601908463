import { createSlice } from '@reduxjs/toolkit';

export const academicSlice = createSlice({
    name: 'software',
    initialState: {
        student_lists: {},
        student_card_list: {},
        std_options_list: {},
        one_click_student_details: {}
        // student_search_rpt_list: {}
    },
    reducers: {
        Student_List_Search: (state, action) => {
            state.student_lists = action.payload;
        },
        Student_Card_List: (state, action) => {
            state.student_card_list = action.payload;
        },
        StudentAddOptionsList: (state, action) => {
            state.std_options_list = action.payload;
        },
        StudentListSymbolNoRegNo: (state, action) => {
            state.std_list_symbol_no_reg_no = action.payload;
        },
        StudentSearchReportList: (state, action) => {
            state.student_search_rpt_list = action.payload;
        },
        OneClickStudentDetailsView: (state, action)=>{
            state.one_click_student_details = action.payload;
        },
        OnDetailsStudentFullDetailsView: (state, action)=>{
            state.on_details_student_full_details =  action.payload;
        }

    }
});

// Action creators are generated for each case reducer function
export const { Student_List_Search, OneClickStudentDetailsView, OnDetailsStudentFullDetailsView, StudentSearchReportList, StudentListSymbolNoRegNo, StudentAddOptionsList, Student_Card_List } = academicSlice.actions
export default academicSlice.reducer