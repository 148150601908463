import React, { Fragment, useEffect, useState } from 'react'
import { useLanguage } from '../../../../widgets/LangContext';
import { SidebarReportClose } from '../../../../store/softSlice';
import { useDispatch } from 'react-redux';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import ExamAnnualYearWiseStdReportFormView from '../../../../components/exam/report/annual_year_wise_std_rpt/ExamAnnualYearWiseStdReportFormView';

const AnnualYearStudentReportFormView = () => {
    const [onReportOptions, setOnReportOptions] = useState([]);
    const ExamReportOptionView = async () => {
        try {
            await fetchDataFromApi(AppURL.EMISRPT + "exam-marks-emis-std-report-options-list-view/").then((res) => {
                setOnReportOptions(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        ExamReportOptionView();
    }, [])
    return (
        <Fragment>
            <ExamAnnualYearWiseStdReportFormView onReportOptions={onReportOptions} />
        </Fragment>
    )
}

export default AnnualYearStudentReportFormView


