import { useFormik } from 'formik';
import React, { useState } from 'react';
import { StudentDetailsValidate } from '../components/academics/create/add_std/utls/StudentValidate';
import { BSToADConvert, NepNowDateFormat } from '../widgets/DateFormat';
import { postDataToTheServer } from '../widgets/api';
import AppURL from '../widgets/AppURL';
import { ToastSuccess, ToastWarning } from '../widgets/ToastMessage';

export const StudentAddFormData = React.createContext();

const StudentAddFormProvider = ({ children }) => {
  const [currentStep, setStep] = useState(1);
  const [selectValues, setOnSelectValues] = useState({
    program: "",
    acdmc_year: "",
    year_semester: "",
    medium: "",
    sec: "",
    team: "",
    shift: "",
    maj_subj: "",
    province: "",
    district: "",
    municipality: "",
    marital_status: "",
    gender: "",
    bloodgroup: "",
    religion: "",
    caste: "",
    sub_caste: "",
    bus_facility: "",
    dorm_facility: "",
    father_qualification: "",
    mother_qualification: ""
  });
  const [customValues, setCustomValues] = useState({
    name_nep: "",
    add_nep: "",
  });

  const initialValues = {
    program: "",
    acdmc_year: "",
    year_semester: "",
    medium: "",
    sec: "",
    team: "",
    shift: "",
    maj_subj: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    name_nep: "",
    per_add: "",
    province: "",
    district: "",
    municipality: "",
    wardno: "",
    add_nep: "",
    tempadd: "",
    father_name: "",
    mother_name: "",
    marital_status: "",
    gender: "",
    dobe: "",
    dobn: "",
    phone: "",
    reg_date_n: "",
    photo: "",
    citiz_no: "",
    email: "",
    bloodgroup: "",
    religion: "",
    caste: "",
    sub_caste: "",
    nationality: "Nepalese",
    bus_facility: "",
    dorm_facility: "",
    fath_ph: "",
    moth_ph: "",
    branchid: "",
    remarks: "",
    mem_cate_id: "",
    user: "",
    sglsn: "",
    saving_account_no: "",
    branch: "",
    roll_no: "",
    reg_no: "",
    symbol_no: "",
    division: "",
    passed_year: "",
    obt_marks: "",
    sms_banking: "",
    notification: "",
    sms_mob_no: "",
    father_qualification: "",
    mother_qualification: ""
  };

  const NPNowDate = NepNowDateFormat();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: StudentDetailsValidate,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("first_name", values.first_name);
      if (values.middle_name) {
        formData.append("middle_name", values.middle_name);
      }
      formData.append("last_name", values.last_name);
      formData.append("name_nep", values.name_nep);
      if (selectValues.province) {
        formData.append("province", selectValues.province.value);
      }
      if (selectValues.team) {
        formData.append("team", selectValues.team.value);
      }
      if (selectValues.district) {
        formData.append("district", selectValues.district.value);
      }
      if (selectValues.municipality) {
        formData.append("municipality", selectValues.municipality.value);
      }
      formData.append("wardno", values.wardno);
      formData.append("add_nep", values.add_nep);
      formData.append("per_add", values.per_add);
      formData.append("tempadd", values.tempadd);
      formData.append("father_name", values.father_name);
      formData.append("mother_name", values.mother_name);
      if (selectValues.marital_status) {
        formData.append("marital_status", selectValues.marital_status.value);
      }
      if (selectValues.gender) {
        formData.append("gender", selectValues.gender.value);
      }
      formData.append("dobe", BSToADConvert(values.dobn) || "");
      formData.append("dobn", values.dobn || "");
      formData.append("phone", values.phone);
      formData.append("reg_date_n", NPNowDate);
      formData.append("citiz_no", values.citiz_no);
      if (selectValues.maj_subj) {
        formData.append("maj_subj", selectValues.maj_subj.value);
      }
      if (selectValues.dorm_facility) {
        formData.append("dorm_facility", selectValues.dorm_facility?.value === "2" ? "0" : "1");
      }
      if (selectValues.bus_facility) {
        formData.append("bus_facility", selectValues.bus_facility === "2" ? "0" : "1");
      }
      formData.append("email", values.email || "");
      if (selectValues.bloodgroup) {
        formData.append("bloodgroup", selectValues.bloodgroup?.label);
      }
      if (selectValues.religion) {
        formData.append("religion", selectValues.religion?.label);
      }
      if (selectValues.caste) {
        formData.append("caste", selectValues.caste.value);
      }
      if (selectValues.sub_caste) {
        formData.append("sub_caste", selectValues.sub_caste.value);
      }
      formData.append("nationality", values.nationality);
      formData.append("fath_ph", values.fath_ph);
      formData.append("moth_ph", values.moth_ph);
      if (values.remarks) {
        formData.append("remarks", values.remarks);
      }
      formData.append("program", selectValues.program.value);
      formData.append("acdmc_year", selectValues.acdmc_year.value);
      formData.append("year_semester", selectValues.year_semester.value);
      formData.append("sec", selectValues.sec.value);
      formData.append("shift", selectValues.shift.value);
      formData.append("medium", selectValues.medium.value);
      formData.append("user", "1");
      if (values.reg_no) {
        formData.append("reg_no", values.reg_no);
      }
      if (values.symbol_no) {
        formData.append("symbol_no", values.symbol_no);
      }
      if (values.division) {
        formData.append("division", values.division);
      }
      formData.append("sms_mob_no", values.phone);
      if (values.passed_year) {
        formData.append("passed_year", values.passed_year);
      }
      if (selectValues.destination) {
        formData.append("destination", selectValues.destination.value);
      }
      if (values.obt_marks) {
        formData.append("obt_marks", values.obt_marks);
      }
      if (selectValues?.mother_qualification) {
        formData.append("mother_qualification", selectValues?.mother_qualification?.value);
      }
      if (selectValues?.father_qualification) {
        formData.append("father_qualification", selectValues?.father_qualification?.value);
      }

      try {
        const res = await postDataToTheServer(AppURL.AcademicsURL + "academics-student-add-details/", formData);
        if (res?.status === 201) {
          ToastSuccess(res?.message);
          formik.resetForm();
          setStep(1);
          setOnSelectValues({
            program: "",
            acdmc_year: "",
            year_semester: "",
            medium: "",
            sec: "",
            team: "",
            shift: "",
            maj_subj: "",
            province: "",
            district: "",
            municipality: "",
            marital_status: "",
            gender: "",
            bloodgroup: "",
            religion: "",
            caste: "",
            sub_caste: "",
            bus_facility: "",
            dorm_facility: "",
            father_qualification: "",
            mother_qualification: ""
          });
        } else if (res?.status === 400) {
          ToastWarning(res?.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const resetForm = () => {
    formik.resetForm();
  };


  return (
    <StudentAddFormData.Provider value={{ ...formik, selectValues, setOnSelectValues, customValues, setCustomValues, currentStep, setStep, resetForm }}>
      {children}
    </StudentAddFormData.Provider>
  );
};

export default StudentAddFormProvider;
