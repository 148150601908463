import React, { useEffect, useState } from "react";
import ReportHeading from "../../../../widgets/ReportHeading";
import SelectOptionBox from "../../../../widgets/SelectOptionBox";
import { ClearBtn, SearchBtn } from "../../../../widgets/AppBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AcademicsYearOptions,
  ExamTypeOptions,
  ProgramOptions,
  YearSemesterOptions,
} from "../../../../widgets/Options";
import { Grid } from "@mui/material";
import { useLanguage } from "../../../../widgets/LangContext";
import { SidebarReportClose } from "../../../../store/softSlice";
import AppURL from "../../../../widgets/AppURL";
import { ProgramWiseSemester } from "../../../../widgets/ProgramWiseSemester";
import { fetchDataFromApi } from "../../../../widgets/api";
import { useFormik } from "formik";
import { EMISMarksLedgerListView } from "../../../../store/emis_rptSlice";

const MarksLedgFormView = ({ isOptions }) => {
  const { language } = useLanguage();
  const dispatch = useDispatch();
  const CloseDispatch = () => {
    dispatch(SidebarReportClose({ action: false }));
  };
  const navigate = useNavigate();
  const { program_wise_year_sem_list } = useSelector((state) => state.emis_rpt);

  const [selectValues, setOnSelectValues] = useState({
    program: "",
    acdmc_year: "",
    year_semester: "",
    medium: "",
    sec: "",
    team: "",
  });

  const optionHandleSelect = (fieldName, data) => {
    handleChange(fieldName)(data.value);
    setOnSelectValues((prevValues) => ({
      ...prevValues,
      [fieldName]: data,
    }));
    if (fieldName === "program") {
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        year_semester: "",
      }));
    }
  };
  const ProgramOption = ProgramOptions(isOptions?.program);
  const AcademicsYearOption = AcademicsYearOptions(isOptions?.acdmc_year);
  const ExamOptions = ExamTypeOptions(isOptions?.exam_type);
  const fetchYearSemesterMajorSubList = async () => {
    if (selectValues?.program) {
      ProgramWiseSemester(selectValues?.program?.value, dispatch);
    }
  };

  useEffect(() => {
    fetchYearSemesterMajorSubList();
  }, [selectValues?.program]);

  const YearSemesterOption = YearSemesterOptions(program_wise_year_sem_list);
  const initialValues = {
    program: "",
    acdmc_year: "",
    year_semester: "",
  };
  // /emis-passout-report-page-list-view
  const { errors, touched, handleBlur, handleChange, isSubmitting, handleSubmit } = useFormik(
    {
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        const formData = new FormData();
        formData.append("acdmc_year", selectValues?.acdmc_year?.value || "0");
        formData.append("program", selectValues?.program?.value || "0");
        formData.append(
          "year_semester",
          selectValues?.year_semester?.value || "0"
        );
        await fetchDataFromApi(
          AppURL.EMISRPT +
            "emis-marks-ledger-report-list-view/?program=" +
            selectValues?.program?.value +
            "&&year_semester=" +
            selectValues?.year_semester?.value +
            "&&acdmc_year=" +
            selectValues?.acdmc_year?.value +
            "&&ex_type_id=" +
            selectValues?.ex_type_id?.value
        ).then((res) => {
          dispatch(EMISMarksLedgerListView(res));
          CloseDispatch();
          navigate("/marks-ledger-list-view");
        });
      },
    }
  );
  return (
    <div className="">
      <ReportHeading heading="Marks Ledger Report" />
      <div className="mt-4">
        <form onSubmit={handleSubmit}>
          <Grid container rowSpacing={1}>
            <Grid item xl={12} xs={12} md={12} sm={12} lg={12}>
              <SelectOptionBox
                label={language ? "Program" : "स्तर"}
                value={selectValues.program}
                options={ProgramOption}
                name="program"
                optionSelect={optionHandleSelect}
                handleBlur={handleBlur}
                touched={touched.program}
                errors={errors.program}
                required={true}
              />
            </Grid>
            <Grid item xl={12} xs={12} md={12} sm={12} lg={12}>
              <SelectOptionBox
                label={language ? "Academics Year" : "शैक्षिक वर्ष"}
                value={selectValues.acdmc_year}
                options={AcademicsYearOption}
                name="acdmc_year"
                optionSelect={optionHandleSelect}
                handleBlur={handleBlur}
                touched={touched.acdmc_year}
                errors={errors.acdmc_year}
                required={true}
              />
            </Grid>
            <Grid item xl={12} xs={12} md={12} sm={12} lg={12}>
              <SelectOptionBox
                label={language ? "Year/Semester" : "वर्ष/सेमेस्टर"}
                value={selectValues.year_semester}
                options={YearSemesterOption}
                name="year_semester"
                optionSelect={optionHandleSelect}
                handleBlur={handleBlur}
                touched={touched.year_semester}
                errors={errors.year_semester}
                required={false}
              />
            </Grid>
            <Grid item xl={12} xs={12} md={12} sm={12} lg={12}>
              <SelectOptionBox
                label={language ? "Exam Type" : "परीक्षा प्रकार"}
                value={selectValues.ex_type_id}
                options={ExamOptions}
                name="ex_type_id"
                optionSelect={optionHandleSelect}
                handleBlur={handleBlur}
                touched={touched.ex_type_id}
                errors={errors.ex_type_id}
                required={false}
              />
            </Grid>
          </Grid>
          <div className="flex justify-between mt-4">
            <ClearBtn type="button" onClick={CloseDispatch} />{" "}
            <SearchBtn type="submit" isSubmitting={isSubmitting} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default MarksLedgFormView;
