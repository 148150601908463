import { fetchDataFromApi } from './api';
import AppURL from './AppURL';
import { LevelAndFacultyWiseProgramSearchList, ProgramWiseYearSemesterList, ProgramWiseYearSemesterListMajorSubject } from '../store/emis_rptSlice';

export const ProgramWiseSemester = async (program_id, dispatch) => {
    if (program_id) {
        try {
            await fetchDataFromApi(AppURL.EMISRPT + "emis-report-program-wise-year-semester-list-view/?program_id=" + program_id).then((res) => {
                dispatch(ProgramWiseYearSemesterList(res?.year_semester));
            });
        } catch (error) {
            console.log(error);
        }
    }
}

export const ProgramWiseSemesterMajorSubject = async (program_id, dispatch) => {
    if (program_id) {
        try {
            await fetchDataFromApi(AppURL.AcademicsURL + "academic-student-year-semester-filter-list/?program_id=" + program_id).then((res) => {
                dispatch(ProgramWiseYearSemesterListMajorSubject(res));
            });
        } catch (error) {
            console.log(error);
        }
    }
}

export const LevelAndFacultySearchView = async (level_id, faculty_id, dispatch) => {
    let url = `${AppURL.EMISRPT}academics-level-and-faculty-search-list-view/?fac_id=${faculty_id}`;
    if (level_id) {
        url += `&level_id=${level_id}`;
    }
    try {
        await fetchDataFromApi(url).then((res) => {
            dispatch(LevelAndFacultyWiseProgramSearchList(res));
        });
    } catch (error) {
        console.log(error);
    }

}
