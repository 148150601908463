import React, { useContext, useEffect, useState } from 'react'

import { Layout } from 'antd';
import AppHeader from '../components/common/AppHeader';
import AppSidebar from '../components/common/AppSidebar';
import { SidebarMenuList } from '../store/softSlice';
import AppURL from '../widgets/AppURL';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataFromApi } from "../widgets/api";
import AppPageTitle from '../components/common/AppPageTitle';
import MainContent from '../components/common/MainContent';
import { LanguageProvider } from '../widgets/LangContext';
import { LoginContext } from '../widgets/AuthContext';
import Loading from '../widgets/Loading';
import WatermarkWrapper from '../widgets/TopWatermarks';
import NoticeBarDrawer from '../components/common/NoticeBar';
const { Content } = Layout;

const DefaultLayouts = () => {
    const dispatch = useDispatch();
    const [onSidebarMenu, setOnSidebarMenu] = useState([]);
    const [onLicense, setOnLicense] = useState('');
    const title = useSelector((state) => state?.software?.title);
    const [isLoading, setLoading] = useState(true);
    const [onIconsButton, setIconsButton] = useState("");
    const user_id = localStorage.getItem('user_id');
    const { authUser, setOnAuthUser } = useContext(LoginContext);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        const SidebarMenuDetails = async () => {
            if (authUser === true) {
                setLoading(true);
                try {
                    await fetchDataFromApi(AppURL.ApplicationURL + 'menu-details-list-view/?user_id=' + user_id).then((res) => {
                        setOnSidebarMenu(res?.data);
                        dispatch(SidebarMenuList(res));
                        setOnLicense(res?.license);
                        setLoading(false);
                    });
                } catch (error) {
                    console.log(error);
                    setLoading(false);
                }
            }
        }
        SidebarMenuDetails();
    }, [title, authUser]);

    return (
        <LanguageProvider>
            <Layout>
                <AppSidebar onSidebarMenu={onSidebarMenu} />
                <Layout className='min-h-screen bg-slate100 dark:bg-slate900 ml-[80px]'>
                    <WatermarkWrapper>
                        <AppHeader setIsOpen={setIsOpen} />
                        <AppPageTitle title={title.title} onIconsButton={onIconsButton} />
                    </WatermarkWrapper>
                    <Content className='pl-7 mb-8 pr-7 mt-[10px]'>
                        <MainContent setIconsButton={setIconsButton} />
                    </Content>
                </Layout>
            </Layout>
            {
                isOpen && <NoticeBarDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
            }
            <Loading isSubmitting={isLoading} />
        </LanguageProvider>
    )
}

export default DefaultLayouts