import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/style/index.css';
import { Provider } from 'react-redux';
import { store } from './store/store';
import AuthContext from './widgets/AuthContext';
import VoucherEntryContext from './context/VoucherEntryContext';
import StudentAddFormProvider from './context/StudentAddMultiStepContext';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <VoucherEntryContext>
        <AuthContext>
          <StudentAddFormProvider>
            <App />
          </StudentAddFormProvider>
        </AuthContext>
      </VoucherEntryContext>
    </Provider>
  </React.StrictMode>
);
