import React, { Fragment, useEffect, useState } from 'react';
import ExamAnnualYearWiseFormView from '../../../../components/exam/report/exam_annual_year/ExamAnnualYearWiseFormView';
import AppURL from '../../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../../widgets/api';

const ExamAnnualYearWiseReportFormView = () => {
  const [onReportOptions, setOnReportOptions] = useState([]);
  const [isLoading, setOnLoading] = useState(false);
  const ExamReportOptionView = async () => {
    setOnLoading(true);
    try {
      await fetchDataFromApi(AppURL.EMISRPT + "exam-marks-emis-report-options-list-view/").then((res) => {
        setOnReportOptions(res);
        setOnLoading(false);
      });
    } catch (error) {
      setOnLoading(false);
      console.log(error);
    }
  }
  useEffect(() => {
    ExamReportOptionView();
  }, [])
  return (
    <Fragment>
      <ExamAnnualYearWiseFormView isLoading={isLoading} onReportOptions={onReportOptions} />
      
    </Fragment>
  )
}

export default ExamAnnualYearWiseReportFormView