import React, { useEffect, useState } from "react";
import { Drawer, Typography, IconButton } from "@material-tailwind/react";
import AppURL from "../../widgets/AppURL";
import { fetchDataFromApi } from "../../widgets/api";
const NoticeBarDrawer = ({ isOpen, setIsOpen }) => {
  const closeDrawer = () => setIsOpen(false);

  const [onAcaListData, setOnAcaListData] = useState([]);
  const url = AppURL.ApplicationURL + "app-notice-alert-list-view/";
  const fetchListView = async (urls) => {
    try {
      await fetchDataFromApi(urls).then((res) => {
        setOnAcaListData(res);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchListView(url);
    }
  }, []);
  return (
    <Drawer
      placement="right"
      open={isOpen}
      onClose={closeDrawer}
      className="p-4 bg-slate100"
      size={450}
    >
      <div className="mb-2 flex items-center justify-between">
        <Typography variant="h5" color="blue-gray" className="font-public-sans">
          Important Notice
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>
      <div className="relative overflow-y-auto h-[90%] space-y-1">
        {onAcaListData?.results?.map((notice, index) => (
          <div
            key={index}
            className="bg-white rounded-lg p-4 border border-gray-200"
          >
            <h6 className="text-md font-semibold text-gray-900">
              {notice?.acdmc_year_name && <span>{notice.acdmc_year_name}</span>}
              {notice?.program_name && <span> | {notice.program_name}</span>}
              {notice?.year_semester_name && (
                <span> | {notice.year_semester_name}</span>
              )}
            </h6>
            <p className="text-gray-800 mt-1">
              {notice?.title && (
                <span className="font-medium">{notice.title}</span>
              )}
              {notice?.last_date_ne && (
                <span className="ml-2 text-sm text-red-500">
                  ({notice.last_date_ne})
                </span>
              )}
            </p>
            <p className="text-gray-500 text-xs italic mt-1">
              {notice?.note && (
                <span className="font-medium">{notice.note}</span>
              )}
            </p>
            <div className="flex justify-between">
                <p className="text-sm text-gray-500 mt-2">
                  Penalty Fee:{" "}
                  <span className="text-red-600 font-semibold">
                    {notice.penalty_fee}
                  </span> / {notice?.department && <span>{notice.department}</span>}
                </p> 
              <button
                className={`py-0.5 px-3 text-[12px] ${
                  parseInt(notice.total_expired_days) <= 2
                    ? "animate-blink_danger"
                    : parseInt(notice.total_expired_days) <= 7
                    ? "animate-blink_Warning"
                    : "animate-blink_success"
                } text-white rounded-sm border-2 border-transparent font-public-sans transition-colors duration-300 ease-in-out`}
              >
                {notice.total_expired_days} Days Left
              </button>
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default NoticeBarDrawer;
