import { createSlice } from '@reduxjs/toolkit';

export const examSlice = createSlice({
    name: 'exam',
    initialState: {
        exam_sub_student_list: {},
        exam_marks_entry_std_details: {},
        class_wise_subj:{},
        subj_wise_marks_list:{},
        emis_options_select_list: {},
        emis_student_wise_subject_list: {}

    },
    reducers: {
        ExamStudentSubjectList: (state, action) => {
            state.exam_sub_student_list = action.payload;
        },
        MultiMarksEntryStdDetails: (state, action) => {
            state.exam_marks_entry_std_details = action.payload;
        },
        SubjectListViewClassWiseList: (state, action) => {
            state.class_wise_subj = action.payload;
        },
        SubjectWiseMarksListView: (state, action) => {
            state.subj_wise_marks_list = action.payload;
        },
        EMISOptionsSelectList: (state, action) => {
            state.emis_options_select_list = action.payload;
        },
        EMISStudentWiseSubjectList: (state, action) => {
            state.emis_student_wise_subject_list = action.payload;
        },
    }
});

// Action creators are generated for each case reducer function
export const { ExamStudentSubjectList, EMISStudentWiseSubjectList, EMISOptionsSelectList, SubjectWiseMarksListView, MultiMarksEntryStdDetails, SubjectListViewClassWiseList} = examSlice.actions
export default examSlice.reducer 