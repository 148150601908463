
class AppURL {
    static BaseURL = "https://demoapi.prefacetechnology.com.np/";
    static ApplicationURL = this.BaseURL + "nexapp-college-app/";
    static AcademicsURL = this.BaseURL + "nexapp-college-academics/";
    static AccountsURL = this.BaseURL + "nexapp-college-accounts/";
    static InventoryURL = this.BaseURL + "nexapp-college-inventory/";
    static HR_URL = this.BaseURL + "nexapp-college-hr/";
    static LibraryURL = this.BaseURL + "nexapp-college-library/";
    static ExamURL = this.BaseURL + "nexapp-college-exam/";
    static EMISRPT=this.BaseURL+"nexapp-college-emis-reports/";
    static StdLMSURL = this.BaseURL+"nexapp-college-student-lms/";
    static LMS = this.BaseURL+"nexapp-college-admin-lms/";
}
export default AppURL
