import React from 'react'

const InputTextBox = ({ errors, multiple, accept, placeholder, id, onInput,  inputRef, max, label, type, name, onKeyPress, onClick,onDoubleClick,
  title,
  value,
  handleChange,
  handleBlur,
  focus,
  touched,
  required,
  disabled,
}) => {
  const defaultOnInput = onInput || (() => {});

  return (
    <div className="relative">
      {label ? <div className='flex flex-row'><label className="block text-[12px] font-medium text-gray700 dark:text-gray-200 mb-1 font-public-sans" htmlFor={name}>{label}</label>{required ? <span className='text-red-500 pl-2'>*</span>:""}</div> : ""}
      <div className=''></div>
      <input
      id={id||name}
        type={type || "text"}
        onKeyPress={onKeyPress}
        onClick={onClick}
        placeholder={placeholder || ""}
        name={name}
        title={title}
        value={value} 
        className={`py-1 mb-0 px-3 text-[13px] placeholder:select-none rounded-[2.5px] placeholder:text-[11.5px] ring-gray-400 font-public-sans ring-[1px] placeholder:text-gray-400 placeholder:font-public-sans ${errors ||( errors && touched )? "ring-red-600" : ""} ${disabled? "bg-blue-gray-100 cursor-pointer":""} font-roboto w-full focus:outline-0 focus:ring-2  focus:ring-green-600`}
        required={required}
        autoComplete="off"
        autoCorrect='off'
        multiple={multiple||""}
        focus={focus}
        onDoubleClick={onDoubleClick}
        ref={inputRef}
        touched={touched ? "true" : ""}
        disabled={disabled || false}
        onWheel={(e) => e.preventDefault()}
        onBlur={handleBlur}
        onChange={handleChange}
        onInput={onInput||defaultOnInput}
        max={max||""}
        accept={accept ||""}
      />
      {errors && <div className='text-red-500 text-[12px] mt-0.5 font-public-sans'>{errors}</div>}
    </div>
  )
}

export default InputTextBox