import React, { Fragment } from 'react'
import TrialRptFormView from '../../../../components/account/report/trial_rpt/TrialRptFormView'

const AccountTrialReportFormView = () => {
  return (
    <Fragment>
        <TrialRptFormView />
    </Fragment>
  )
}

export default AccountTrialReportFormView