import React, { Fragment } from 'react'
import PurchaseReportForm from '../../../../components/inventory/purchase_rpt/PurchaseReportForm'

const PurchaseReportFormView = () => {
  return (
    <Fragment>
      <PurchaseReportForm />
    </Fragment>
  )
}

export default PurchaseReportFormView