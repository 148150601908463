import { Grid } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { useFormik } from 'formik';
import { useLanguage } from '../../../../widgets/LangContext';
import ReportHeading from '../../../../widgets/ReportHeading';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { SidebarReportClose } from '../../../../store/softSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AcademicsYearOptions, CasteOptions, DistrictOptions, ExamTypeOptions, FacultyOptions, GenderOptions, LevelOptions, MajorSubjectOptions, MunOptions, ProgramOptions, ProvinceOptions, StudentProfileOptions, YearListOptions, YearSemesterOptions } from '../../../../widgets/Options';
import { LevelAndFacultySearchView, ProgramWiseSemesterMajorSubject } from '../../../../widgets/ProgramWiseSemester';
import { useNavigate } from 'react-router-dom';
import { gender_lists } from '../../../../widgets/json';
import { fetchDataFromApi, postDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import InputTextBox from '../../../../widgets/InputTextBox';
import { AnnualYearWiseStudentReportList, AnnualYearWiseStudentReportOptionsList } from '../../../../store/emis_rptSlice';

const ExamAnnualYearWiseFormView = ({ onReportOptions, isLoading }) => {
  const { language } = useLanguage();
  const dispatch = useDispatch();
  const CloseDispatch = () => {
    dispatch(SidebarReportClose({ "action": false }));
  }
  const navigate = useNavigate();
  const GenderOption = GenderOptions(gender_lists);
  const { program_wise_year_sem_major_list, level_and_faculty_wise_program } = useSelector((state) => state.emis_rpt);

  const [selectValues, setOnSelectValues] = useState({
    program: "",
    acdmc_year: "",
    year_semester: "",
    maj_subj: "",
    gender: "",
    caste: "",
    std_profile: "",
    level_id: "",
    faculty_id: "",
    province: "",
    mun: "",
    district: "",
    exam_type: "",
    province: "",
    district: "",
  });
  const optionHandleSelect = (fieldName, data) => {
    if (data === null || data.value === "") {
      // If data is null or empty, clear the input value
      handleChange(fieldName)("");
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
    } else {
      // Otherwise, update the input value as usual
      handleChange(fieldName)(data.value);
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        [fieldName]: data,
      }));
    }
    if (fieldName === "program") {
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        year_semester: "",
        maj_subj: ""
      }));
    }
    if (fieldName === "province") {
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        mun: "",
        district: ""
      }));
    }
    if (fieldName === "district") {
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        mun: "",
      }));
    }
    if (fieldName === "level_id") {
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        program: "",
      }));
    }
    if (fieldName === "faculty_id") {
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        program: "",
      }));
    }
  };
  const ProgramOption = ProgramOptions(level_and_faculty_wise_program?.program);
  const FacultyOption = FacultyOptions(onReportOptions?.faculty);
  const LevelOption = LevelOptions(onReportOptions?.level);
  const CasteOption = CasteOptions(onReportOptions?.caste);
  const AcademicsYearOption = AcademicsYearOptions(onReportOptions?.academics_year);
  const ExamTypeOption = ExamTypeOptions(onReportOptions?.exam_type);

  const ProvinceOption = ProvinceOptions(onReportOptions?.province);

  const StudentProfileOption = StudentProfileOptions(onReportOptions?.std_profile);
  const fetchYearSemesterMajorSubList = async () => {
    if (selectValues?.program) {
      await ProgramWiseSemesterMajorSubject(selectValues?.program?.value, dispatch);
    }
    if (selectValues?.faculty_id && selectValues?.level_id) {
      await LevelAndFacultySearchView(selectValues?.level_id?.value, selectValues?.faculty_id?.value, dispatch);
    }
  }
  useEffect(() => {
    fetchYearSemesterMajorSubList();
  }, [selectValues?.program, selectValues?.faculty_id, selectValues?.level_id]);
  const [onCasteOptionsList, setOnCasteOptionsList] = useState([]);

  useEffect(() => {
    const getSubCasteList = async () => {
      if (selectValues?.province) {
        try {
          await fetchDataFromApi(AppURL.AcademicsURL + "academics-province-wise-district-list/?province_id=" + selectValues?.province?.value).then((res) => {
            setOnCasteOptionsList((prevValues) => ({
              ...prevValues,
              "district": res
            }));
          });
        } catch (error) {
          console.log(error);
        }
      }
      if (selectValues?.district) {
        try {
          await fetchDataFromApi(AppURL.AcademicsURL + "academics-district-wise-municiplity-list/?district_id=" + selectValues?.district?.value).then((res) => {
            setOnCasteOptionsList((prevValues) => ({
              ...prevValues,
              "mun": res
            }));
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
    getSubCasteList();
  }, [selectValues?.province, selectValues?.district]);

  const DistrictOption = DistrictOptions(onCasteOptionsList?.district);
  const MuniOptions = MunOptions(onCasteOptionsList?.mun);
  const YearListOption = YearListOptions(onReportOptions?.year_list);
  const MajorSubjectOption = MajorSubjectOptions(program_wise_year_sem_major_list?.major_subject);
  const initialValues = {
    program: "",
    acdmc_year: "",
    year_semester: "",
    maj_subj: "",
    province: "",
    district: "",
    mun: "",
    from_age: "",
    exam_type: "",
    to_age: "",
    ward_no: "",
    yearr: ""
  };
  const { errors, touched, values, handleBlur, handleChange, isSubmitting, handleSubmit } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        const formData = new FormData();
        formData.append("yearr", selectValues?.yearr?.label || "0");
        formData.append("acdmc_year", selectValues?.acdmc_year?.value || "0");
        formData.append("program", selectValues?.program?.value || "0");
        formData.append("gender", selectValues?.gender?.value || "0");
        formData.append("mun", selectValues?.mun?.value || "0");
        formData.append("year_semester", selectValues?.year_semester?.value || "0");
        formData.append("maj_subj", selectValues?.maj_subj?.value || "0");
        formData.append("caste_id", selectValues?.caste?.value || "0");
        formData.append("std_Profile_id", selectValues?.std_Profile_id?.value || "0");
        formData.append("exam_type", selectValues?.exam_type?.value || "0");
        formData.append("level", selectValues?.level_id?.value || "0");
        formData.append("faculty", selectValues?.faculty_id?.value || "0");
        formData.append("ward_no", values.ward_no || "0");
        formData.append("province", selectValues.province?.value || "0");
        formData.append("district", selectValues.district?.value || "0");
        await postDataToTheServer(AppURL.EMISRPT + "exam-marks-emis-report-search-list-view/", formData).then((res) => {
          dispatch(AnnualYearWiseStudentReportList(res));
          CloseDispatch();
          navigate("/annual-year-wiser-report-page-list-view");
          dispatch(AnnualYearWiseStudentReportOptionsList(
            {
              "year": selectValues?.program?.label,
              "program": selectValues?.program?.label,
              "acdmc_year": selectValues?.acdmc_year?.label,
              "gender": selectValues?.gender?.label,
              "mun": selectValues?.mun?.label,
              "caste": selectValues?.caste?.label,
              "level": selectValues?.level_id?.label,
              "faculty": selectValues?.faculty_id?.label,
              "std_Profile_id": selectValues?.std_Profile_id?.label,
              "year_semester": selectValues?.year_semester?.label,
              "from_age": values.from_age,
              "to_age": values.to_age,
              "maj_subj": selectValues?.maj_subj?.label,
              "ward_no": values.ward_no
            }
          ));
        });
      }
    });


  return (
    <Fragment>
      <ReportHeading heading="Exam Report (Annual Year)" />
      <form onSubmit={handleSubmit} className='mt-5'>
        <Grid container rowSpacing={0.5} columnSpacing={0.5}>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={"Years"} isLoading={isLoading} value={selectValues.yearr} clear={true} options={YearListOption} name="yearr" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.yearr} errors={errors.yearr} required={true} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={"Academics Year"} isLoading={isLoading} value={selectValues.acdmc_year} clear={true} options={AcademicsYearOption} name="acdmc_year" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.acdmc_year} errors={errors.acdmc_year} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={"Level"} isLoading={isLoading} value={selectValues.level_id} clear={true} options={LevelOption} name="level_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.level_id} errors={errors.level_id} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={"Faculty"} isLoading={isLoading} value={selectValues.faculty_id} clear={true} options={FacultyOption} name="faculty_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.faculty_id} errors={errors.faculty_id} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={"Program"} isLoading={isLoading} value={selectValues.program} clear={true} options={ProgramOption} name="program" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.program} errors={errors.program} required={false} />
          </Grid>

          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={"Exam Type"} isLoading={isLoading} value={selectValues.exam_type} clear={true} options={ExamTypeOption} name="exam_type" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.exam_type} errors={errors.exam_type} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={"Province"} value={selectValues.province} clear={true} options={ProvinceOption} name="province" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.province} errors={errors.province} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={"District"} value={selectValues.district} clear={true} options={DistrictOption} name="district" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.district} errors={errors.district} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={"Municipality"} value={selectValues.mun} clear={true} options={MuniOptions} name="mun" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.mun} errors={errors.mun} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={"Major Subject"} value={selectValues.maj_subj} clear={true} options={MajorSubjectOption} name="maj_subj" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.maj_subj} errors={errors.maj_subj} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={"Gender"} isLoading={isLoading} value={selectValues.gender} clear={true} options={GenderOption} name="gender" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.gender} errors={errors.gender} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={"Caste"} isLoading={isLoading} value={selectValues.caste} clear={true} options={CasteOption} name="caste" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.caste} errors={errors.caste} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <SelectOptionBox label={"Results"} isLoading={isLoading} value={selectValues.std_Profile_id} clear={true} options={StudentProfileOption} name="std_Profile_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.std_Profile_id} errors={errors.std_Profile_id} required={false} />
          </Grid>
          <Grid item xl={6} xs={6} md={6} sm={6} lg={6}>
            <div className='mt-0.5'>
              <Grid container rowSpacing={2} columnSpacing={0.5}>
                <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                  <InputTextBox
                    name="ward_no"
                    type="number"
                    label={"Ward No."}
                    value={values.ward_no}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.ward_no}
                    errors={errors.ward_no}
                    required={false}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>

        </Grid>
        <div className='flex justify-between mt-4'>
          <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn isSubmitting={isSubmitting} type="submit" />
        </div>
      </form>
    </Fragment>
  )
}

export default ExamAnnualYearWiseFormView