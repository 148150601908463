import React, { Fragment, useEffect, useState } from 'react'
import { CiEdit } from "react-icons/ci";
import { useFormik } from 'formik';
import { Grid } from "@mui/material";
import { fetchDataFromApi, putDataToTheServer } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { NepNowDateFormat } from '../../../widgets/DateFormat';
import { ToastSuccess } from '../../../widgets/ToastMessage';
import { ItemUnitListOptions, SubMasterOptions, YesNoOptions } from '../../../widgets/Options';
import ComponentHeading from '../../../widgets/ComponentHeading';
import DefaultSelectOptionBox from '../../../widgets/DefaultSelectBox';
import InputTextBox from '../../../widgets/InputTextBox';
import NepaliInputTextBox from '../../../widgets/NepaliInputTextBox';
import { yes_no_list } from '../../../widgets/json';
import { ClearBtn, UpdateBtn } from '../../../widgets/AppBtn';
import Container from '../../../widgets/Container';

const ItemEditFormView = ({ masterCategoryList, setOnProductEditID, onProductEditID }) => {
    const [EditProductDetails, setOnEditDetails] = useState({});
    const YesNoOption = YesNoOptions(yes_no_list);
    const fetchProductDetails = async () => {
        try {
            await fetchDataFromApi(AppURL.InventoryURL + "inventory-product-retrieve-update-view/" + onProductEditID).then((res) => {
                setOnEditDetails(res?.data);
                setCustomValues({
                    product_name_nep: res?.data?.product_name_nep || '',
                });
                setOnSelectValues({
                    product_master_id: res?.data?.product_master_id || "",
                    product_sub_master_id: res?.data?.product_sub_master_id || "",
                    active: res?.data?.active || "",
                    discable: res?.data?.discable === true ? 1 : 0 || "",
                    status: res?.data?.status === true ? 1 : 0,
                    with_vat: res?.data?.with_vat === false ? 0 : 1 || "",
                    purchase_unit_id: res?.data?.purchase_unit_id || "",
                    sales_unit_id: res?.data?.sales_unit_id
                });
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchProductDetails();
    }, [onProductEditID]);

    const [onSubMasterCategoryList, setOnSubMaterCategoryList] = useState([]);
    const [selectValues, setOnSelectValues] = useState({
        product_master_id: "",
        Active: "",
        discable: "0",
        status: "",
        with_vat: ""
    });

    const [customValues, setCustomValues] = useState({
        product_name_nep: "",
    });

    const handleNepaliInputChange = (fieldName, value) => {
        handleChange(fieldName)(value)
        setCustomValues((prevValues) => ({
            ...prevValues,
            [fieldName]: value,
        }));
    };

    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data.value,
        }));
    };

    const NepaliDate = NepNowDateFormat();
    const initialValues = {
        product_sub_master_id: "",
        purchase_unit_id: EditProductDetails?.data?.purchase_unit_id || "",
        sales_unit_id: EditProductDetails?.sales_unit_id || "",
        product_name: EditProductDetails?.product_name || "",
        product_name_nep: "",
        purchase_rate: EditProductDetails?.purchase_rate || "",
        sales_rate: EditProductDetails?.sales_rate || "",
        remarks: EditProductDetails?.remarks || "-",
        status: "",
        opening_stock: EditProductDetails?.opening_stock || "0",
        stock_lim_qty: EditProductDetails?.stock_lim_qty || "0",
        start_date: EditProductDetails?.start_date || "",
        discable: "",
        disc_perc: EditProductDetails?.disc_perc || 0,
        disc_amt: EditProductDetails?.disc_amt || 0,
        model_no: EditProductDetails?.model_no || "-",
        char_code: EditProductDetails?.char_code || "0",
        sizes: EditProductDetails?.sizes || "0",
        mat_type_id: "",
        with_vat: EditProductDetails?.with_vat || "",
    }

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("product_sub_master_id", selectValues?.product_sub_master_id);
                formData.append("purchase_unit_id", selectValues.purchase_unit_id);
                formData.append("sales_unit_id", values.sales_unit_id);
                formData.append("product_name", values.product_name);
                formData.append("product_name_nep", customValues.product_name_nep);
                formData.append("purchase_rate", values.purchase_rate);
                formData.append("sales_rate", values.sales_rate);
                formData.append("remarks", values.remarks);
                formData.append("status", selectValues?.status);
                formData.append("opening_stock", values.opening_stock);
                formData.append("stock_lim_qty", values.stock_lim_qty);
                formData.append("start_date", NepaliDate);
                formData.append("discable", selectValues?.discable);
                formData.append("disc_perc", values.disc_perc);
                formData.append("disc_amt", values.disc_amt);
                formData.append("model_no", values.model_no);
                formData.append("char_code", values.char_code);
                formData.append("sizes", values.sizes);
                formData.append("mat_type_id", 1);
                formData.append("with_vat", selectValues.with_vat);
                try {
                    await putDataToTheServer(AppURL.InventoryURL + "inventory-product-retrieve-update-view/" + onProductEditID, formData).then((res) => {
                        ToastSuccess(res?.message);
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });

    const MasterCategoryWiseSubMasterCategoryList = async () => {

        try {
            await fetchDataFromApi(AppURL.InventoryURL + "inventory-product-item-edit-options-list-view/").then((res) => {
                setOnSubMaterCategoryList(res);
            });
        } catch (error) {
            console.log(error);
        }


    }

    const SubMasterOption = SubMasterOptions(onSubMasterCategoryList?.sub_master);
    const ItemUnitListOption = ItemUnitListOptions(onSubMasterCategoryList?.unit_list);

    useEffect(() => {
        MasterCategoryWiseSubMasterCategoryList();
    }, []);

    return (
        <Fragment>
            <Container>
                <ComponentHeading icon={CiEdit} label="Edit Item" />
                <form onSubmit={handleSubmit}>
                    <Grid container columnSpacing={2} spacing={.5}>
                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <DefaultSelectOptionBox label="Item Category" value={selectValues.product_sub_master_id} name="product_sub_master_id" options={SubMasterOption} optionSelect={optionHandleSelect} handleBlur={handleBlur} touched={touched.product_sub_master_id} errors={errors.product_sub_master_id} required={true} disabled={false} />
                        </Grid>
                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <InputTextBox
                                label="Product Item Name"
                                type="text"
                                name="product_name"
                                value={values.product_name}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.product_name}
                                errors={errors.product_name}
                                required={true}
                                disabled={false} />
                        </Grid>
                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <NepaliInputTextBox
                                label="Product Item Name(Nepali)"
                                value={customValues.product_name_nep || ""}
                                name="product_name_nep"
                                onCustomValueChange={handleNepaliInputChange}
                                handleBlur={handleBlur}
                                touched={touched.product_name_nep}
                                errors={errors.product_name_nep}
                                required={true}
                                disabled={false} />
                        </Grid>
                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <InputTextBox
                                label="Purchase Rate"
                                name="purchase_rate" type="text"
                                value={values.purchase_rate}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.purchase_rate}
                                errors={errors.purchase_rate}
                                required={true}
                                disabled={false} />
                        </Grid>
                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <InputTextBox label="Sales Rate"
                                name="sales_rate" type="text"
                                value={values.sales_rate}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.sales_rate}
                                errors={errors.sales_rate}
                                required={true}
                                disabled={false} />
                        </Grid>
                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <DefaultSelectOptionBox label="Purchase Unit" value={selectValues.purchase_unit_id} name="purchase_unit_id" options={ItemUnitListOption} optionSelect={optionHandleSelect} handleBlur={handleBlur} touched={touched.purchase_unit_id} errors={errors.purchase_unit_id} required={true} disabled={false} />
                        </Grid>
                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <DefaultSelectOptionBox label="Sale Unit" value={selectValues.sales_unit_id} name="sales_unit_id" options={ItemUnitListOption} optionSelect={optionHandleSelect} handleBlur={handleBlur} touched={touched.sales_unit_id} errors={errors.sales_unit_id} required={true} disabled={false} />
                        </Grid>
                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <InputTextBox label="Opening Stock" type="text" name="opening_stock"
                                value={values.opening_stock}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.opening_stock}
                                errors={errors.opening_stock} required={true} disabled={false} />
                        </Grid>
                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <InputTextBox label="Stock Limit Qty" type="text" name="stock_lim_qty"
                                value={values.stock_lim_qty}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.stock_lim_qty}
                                errors={errors.stock_lim_qty} required={true} disabled={false} />
                        </Grid>
                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <DefaultSelectOptionBox label="With VAT" name="with_vat" value={selectValues.with_vat} options={YesNoOption} optionSelect={optionHandleSelect} handleBlur={handleBlur} touched={touched.with_vat} errors={errors.with_vat} required={true} disabled={false} />
                        </Grid>

                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <DefaultSelectOptionBox label="Discountable" name="discable" value={selectValues.discable} options={YesNoOption} optionSelect={optionHandleSelect} handleBlur={handleBlur} touched={touched.discable} errors={errors.discable} required={true} disabled={false} />
                        </Grid>
                        {
                            selectValues?.discable?.value === "1" ? <> <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                                <InputTextBox label="Discount %" type="text" name="disc_perc"
                                    value={values.disc_perc}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched.disc_perc}
                                    errors={errors.disc_perc} required={true} disabled={false} />
                            </Grid>
                                <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                                    <InputTextBox label="Discount Amt" name="disc_amt" type="text" value={values.disc_amt}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        touched={touched.disc_amt}
                                        errors={errors.disc_amt} required={true} disabled={false} />
                                </Grid></> : ""
                        }

                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <InputTextBox label="Model No" name="model_no" type="text" value={values.model_no}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.model_no}
                                errors={errors.model_no} required={true} disabled={false} />
                        </Grid>
                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <InputTextBox label="Product Code" type="text" name="char_code" value={values.char_code}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.char_code}
                                errors={errors.char_code} required={true} disabled={false} />
                        </Grid>

                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <InputTextBox label="Product sizes" name="sizes" type="text" value={values.sizes}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.sizes}
                                errors={errors.sizes} required={true} disabled={false} />
                        </Grid>
                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <InputTextBox label="Remarks" type="text" name="remarks" value={values.remarks}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.remarks}
                                errors={errors.remarks} required={false} disabled={false} />

                        </Grid>
                        <Grid item xl={3} xs={12} sm={12} md={3} lg={3}>
                            <DefaultSelectOptionBox label="Active" name="status" value={selectValues.status} options={YesNoOption} optionSelect={optionHandleSelect} handleBlur={handleBlur} touched={touched.status} errors={errors.status} required={true} disabled={false} />
                        </Grid>
                    </Grid>
                    <div className='flex justify-end gap-3 mt-4'> <ClearBtn onClick={async () => await setOnProductEditID(false)} /> <UpdateBtn disabled={isSubmitting} type="submit" /></div>
                </form>
            </Container>
        </Fragment>
    )
}

export default ItemEditFormView