import React from "react";
import TeacherDetailsReportsListView from "../../views/hr/report/staff_rpt/TeacherDetailsReportsListView";
import AcaCalenderCreateListView from "../../views/academics/create/AcaCalenderCreateListView";
import InventoryItemListCreateListView from "../../views/inventory/report/item_list/InventoryItemListCreateListView";

const EMISPassedListTranscriptPageListView = React.lazy(() =>
  import("../../views/exam/reports/transcript_emis/EMISPassedListTranscriptPageListView")
);
const StudentSearchReportListPageView = React.lazy(() =>
  import("../../views/academics/report/student_report/StudentSearchReportListPageView")
);
const AccountDayBookPageListView = React.lazy(() =>
  import("../../views/accounts/report/day_book/AccountDayBookPageListView")
);
const AccountLeaderPageReportView = React.lazy(() =>
  import("../../views/accounts/report/ledger_report/AccountLeaderPageReportView")
);
const AccountTransactionReportPageListView = React.lazy(() =>
  import("../../views/accounts/report/trans_rpt/AccountTransactionReportPageListView")
);
const LMSHomeworkListView = React.lazy(() =>
  import("../../views/lms/report/LMSHomeworkListView")
);
const StudentUsernamePasswordGenerateListVIew = React.lazy(() =>
  import("../../views/lms/create/std_user_pass/StudentUsernamePasswordGenerateListVIew")
);
const LMSNoticeCreateListView = React.lazy(() =>
  import("../../views/lms/create/notice/LMSNoticeCreateListView")
);
const TeacherUsernamePasswordCreateListView = React.lazy(() =>
  import("../../views/lms/create/teach_u_name_password/TeacherUsernamePasswordCreateListView")
);
const AcaDestinationCreateListView = React.lazy(() =>
  import("../../views/academics/setup/AcaDestinationCreateListView")
);
const PostalReceiveCreateListView = React.lazy(() =>
  import("../../views/application/create/postal_receive/PostalReceiveCreateListView")
);
const LibraryBookListReportView = React.lazy(() =>
  import("../../views/library/report/LibraryBookListReportView")
);
const PhotoUploadUpdateListView = React.lazy(() =>
  import("../../views/academics/create/PhotoUploadUpdateListView")
);
const IDCardAndVanCardListView = React.lazy(() =>
  import("../../views/academics/create/IDCardAndVanCardListView")
);
const ExamMarksReportListView = React.lazy(() =>
  import("../../views/exam/reports/marks_rpt/ExamMarksReportListView")
);
const AcademicsRecordsCreateListView = React.lazy(() =>
  import("../../views/academics/create/AcademicsRecordsCreateListView")
);
const MarksEntrySettingsCreateListView = React.lazy(() =>
  import("../../views/exam/setup/MarksEntrySettingsCreateListView")
);
const MarksLedgerFormView = React.lazy(() =>
  import("../../views/exam/reports/marks_ledger/MarksLedgerFormView")
);
const ExamMarksLedgerListView = React.lazy(() =>
  import("../../views/exam/reports/marks_ledger/ExamMarksLedgerListView")
);
const ComprehensiveProfileCreateListView = React.lazy(() =>
  import("../../views/exam/create/ComprehensiveProfileCreateListView")
);
const ExcelImportMarksCreateListView = React.lazy(() =>
  import("../../views/exam/create/ExcelImportMarksCreateListView")
);
const ResultsAnalysisReportCreateListView = React.lazy(() =>
  import("../../views/exam/reports/results_analysis/ResultsAnalysisReportCreateListView")
);
const CategoryMasterCreateListView = React.lazy(() =>
  import("../../views/accounts/setup/category_master/CategoryMasterCreateListView")
);
const AccItemMasterCreateListView = React.lazy(() =>
  import("../../views/accounts/setup/item_master/AccItemMasterCreateListView")
);
const VendorMasterCreateListView = React.lazy(() =>
  import("../../views/accounts/setup/vendor_master/VendorMasterCreateListView")
);
const OldStudentMigrateCreateListView = React.lazy(() =>
  import("../../views/academics/create/OldStudentMigrateCreateListView")
);
const AcademicsStudentVanReportCreateListView = React.lazy(() =>
  import("../../views/academics/report/AcademicsStudentVanReportCreateListView")
);
const AdvancedVoucherReportListView = React.lazy(() =>
  import("../../views/accounts/report/advan_voucher_rpt/AdvancedVoucherReportListView")
);
const AccountStudentReportsListView = React.lazy(() =>
  import("../../views/accounts/report/std_ac_rpt/AccountStudentReportsListView")
);
const AcademicsTransferandCharacterListView = React.lazy(() =>
  import("../../views/academics/create/AcademicsTransferandCharacterListView")
);
const CharacterTransferPrintReportListView = React.lazy(() =>
  import("../../views/academics/report/charac_rpt/CharacterTransferPrintReportListView")
);
const CardCharacterPrintedReportCreateListView = React.lazy(() =>
  import("../../views/academics/report/CardCharacterPrintedReportCreateListView")
);
const AcaCardValidDateCreateListView = React.lazy(() =>
  import("../../views/academics/setup/AcaCardValidDateCreateListView")
);
const StudentSearchCreateListView = React.lazy(() =>
  import("../../views/academics/report/StudentSearchCreateListView")
);
const UsersLogsCreateListView = React.lazy(() =>
  import("../../views/application/reports/UsersLogsCreateListView")
);
const NoticeSetupCreateListView = React.lazy(() =>
  import("../../views/application/create/notice_setup/NoticeofRevisionCreateListView")
);
const NoticeofRevisionCreateListView = React.lazy(() =>
  import("../../views/application/create/notice_setup/NoticeofRevisionCreateListView")
);
const SystemInformationCreateListView = React.lazy(() =>
  import("../../views/application/create/device/SystemInformationCreateListView")
);
const ExamProgramWiseSubjectsListView = React.lazy(() =>
  import("../../views/academics/report/ExamProgramWiseSubjectsListView")
);
const ChangeStudentStatusCreateListView = React.lazy(() =>
  import("../../views/academics/create/ChangeStudentStatusCreateListView")
);
const PassedStudentPercentagesCreateListView = React.lazy(() =>
  import("../../views/exam/reports/passed_std_rpt/PassedStudentPercentagesCreateListView")
);
const RollGenerateCreateListView = React.lazy(() =>
  import("../../views/academics/create/RollGenerateCreateListView")
);
const MarksYearCorrectionCreateListView = React.lazy(() =>
  import("../../views/exam/create/MarksYearCorrectionCreateListView")
);

const StudentFullDetailsGetView = React.lazy(() =>
  import("../../views/academics/report/StudentFullDetailsGetView")
);
const StudentIDWiseEditDetailsView = React.lazy(() =>
  import("../../views/academics/report/StudentIDWiseEditDetailsView")
);
const AssignSubjectRemoveListView = React.lazy(() =>
  import("../../views/exam/create/AssignSubjectRemoveListView")
);
const EMISDashboardCreateListView = React.lazy(() =>
  import("../../views/exam/create/EMISDashboardCreateListView")
);
const UGCStatisticalReportsCreateListView = React.lazy(() =>
  import("../../views/exam/reports/ugc_rpt/UGCStatisticalReportsCreateListView")
);
const PurchaseAssetsCreateListView = React.lazy(() =>
  import("../../views/accounts/create/PurchaseAssetsCreateListView")
);
const CardDesignCreateListView = React.lazy(() =>
  import("../../views/academics/setup/CardDesignCreateListView")
);
const AcademicsStudentDetailsView = React.lazy(() =>
  import("../../views/academics/report/AcademicsStudentDetailsView")
);
const OrganizationStruListView = React.lazy(() =>
  import("../../views/application/create/org/OrganizationStruListView")
);
const DefaultDashboardView = React.lazy(() =>
  import("../../views/application/create/dashboard/DefaultDashboardView")
);
const AppAuthorCreateListView = React.lazy(() =>
  import("../../views/application/create/author/AppAuthorCreateListView")
);
const AcademicsUpgradeStudentListView = React.lazy(() =>
  import("../../views/academics/create/AcademicsUpgradeStudentListView")
);
const AcaEnthinicityCreateListView = React.lazy(() =>
  import("../../views/academics/setup/AcaEnthinicityCreateListView")
);
const AcaCasteCreateListView = React.lazy(() =>
  import("../../views/academics/setup/AcaCasteCreateListView")
);
const HREmployeeListView = React.lazy(() =>
  import("../../views/hr/report/staff_list/HREmployeeListView")
);
const CommonSettingCreateListView = React.lazy(() =>
  import(
    "../../views/accounts/setup/common_setting/CommonSettingCreateListView"
  )
);
const PostalDispatchCreateListView = React.lazy(() =>
  import(
    "../../views/application/create/postal_dispatch/create/PostalDispatchCreateListView"
  )
);
const AcaSingleBookReportListView = React.lazy(() =>
  import(
    "../../views/library/report/single_book_rpt/AcaSingleBookReportListView"
  )
);
const AcaStudentProfileCreateListView = React.lazy(() =>
  import("../../views/academics/setup/AcaStudentProfileCreateListView")
);
const AnnualYearStudentReportPageListView = React.lazy(() =>
  import(
    "../../views/exam/reports/annual_year_std_rpt/AnnualYearStudentReportPageListView"
  )
);

const AnnualYearWiseReportPageListView = React.lazy(() =>
  import(
    "../../views/exam/reports/annual_exam_rpt/AnnualYearWiseReportPageListView"
  )
);
const ExamAdmissionReportPageList = React.lazy(() =>
  import("../../views/exam/reports/admission_rpt/ExamAdmissionReportPageList")
);
const SubLedgerTypeCreateListView = React.lazy(() =>
  import("../../views/accounts/setup/sub_ledg_type/SubLedgerTypeCreateListView")
);
const AcaRegNoAndSymbolNoUpdateListView = React.lazy(() =>
  import("../../views/academics/create/AcaRegNoAndSymbolNoUpdateListView")
);
const AcademicsStudentEditDetailsView = React.lazy(() =>
  import("../../views/academics/report/AcademicsStudentEditDetailsView")
);
const SubMenuCreateListView = React.lazy(() =>
  import("../../views/application/create/menu/SubMenuCreateListView")
);
const SubSubMenuCreateListView = React.lazy(() =>
  import("../../views/application/create/menu/SubSubMenuCreateListView")
);
const AuthorRoleListView = React.lazy(() =>
  import("../../views/application/create/author_role/AuthorRoleListView")
);
const AuthorMenuListView = React.lazy(() =>
  import("../../views/application/create/author_role/AuthorMenuListView")
);
const MenuDetailsListView = React.lazy(() =>
  import("../../views/application/create/author_role/MenuDetailsListView")
);
const AcaLevelCreateListView = React.lazy(() =>
  import("../../views/academics/setup/AcaLevelCreateListView")
);
const AcaFacultyCreateListView = React.lazy(() =>
  import("../../views/academics/setup/AcaFacultyCreateListView")
);
const AcaProgramCreateListView = React.lazy(() =>
  import("../../views/academics/setup/AcaProgramCreateListView")
);
const AcaYearSemesterCreateListView = React.lazy(() =>
  import("../../views/academics/setup/AcaYearSemesterCreateListView")
);
const AcaAcademicYearCreateListView = React.lazy(() =>
  import("../../views/academics/setup/AcaAcademicYearCreateListView")
);
const AcaSectionCreateListView = React.lazy(() =>
  import("../../views/academics/setup/AcaSectionCreateListView")
);
const AcaTeamCreateListView = React.lazy(() =>
  import("../../views/academics/setup/AcaTeamCreateListView")
);
const AcaShiftCreateListView = React.lazy(() =>
  import("../../views/academics/setup/AcaShiftCreateListView")
);
const AcaShiftCreateView = React.lazy(() =>
  import("../../components/academics/setup/shift/AcaShiftCreateView")
);
const AcaMajorSubjectCreateListView = React.lazy(() =>
  import("../../views/academics/setup/AcaMajorSubjectCreateListView")
);
const AccountGeneralLedgerListView = React.lazy(() =>
  import(
    "../../views/accounts/setup/general_ledger/AccountGeneralLedgerListView"
  )
);
const AccountSubLedgerListView = React.lazy(() =>
  import("../../views/accounts/setup/sub_ledg/AccountSubLedgerListView")
);
const AccountVoucherEntryCreateListView = React.lazy(() =>
  import("../../views/accounts/create/AccountVoucherEntryCreateListView")
);
const AccountFiscalYearCreateListView = React.lazy(() =>
  import(
    "../../views/accounts/setup/fiscal_year/AccountFiscalYearCreateListView"
  )
);
const AccountParticularListDetailsView = React.lazy(() =>
  import(
    "../../views/accounts/setup/particular/AccountParticularListDetailsView"
  )
);
const AccountTrialReportListView = React.lazy(() =>
  import("../../views/accounts/report/trial_rpt/AccountTrialReportListView")
);
const AccountSheetRptPageListView = React.lazy(() =>
  import(
    "../../views/accounts/report/balance_sheet/AccountSheetRptPageListView"
  )
);
const AccountProfitLossReportPageView = React.lazy(() =>
  import(
    "../../views/accounts/report/profit_loss_rpt/AccountProfitLossReportPageView"
  )
);
const AccountVoucherReportListView = React.lazy(() =>
  import("../../views/accounts/report/voucher_rpt/AccountVoucherReportListView")
);
const InventoryAddItemView = React.lazy(() =>
  import("../../views/inventory/setup/addItem/InventoryAddItemView")
);
const InventoryItemCategoryAddListView = React.lazy(() =>
  import(
    "../../views/inventory/setup/itemCategory/InventoryItemCategoryAddListView"
  )
);
const InventoryCategoryMasterAddListView = React.lazy(() =>
  import(
    "../../views/inventory/setup/masterCategory/InventoryCategoryMasterAddListView"
  )
);
const InventoryUnitItemAddView = React.lazy(() =>
  import("../../views/inventory/setup/unit/InventoryUnitItemAddView")
);
const InventoryStoreAddListView = React.lazy(() =>
  import("../../views/inventory/setup/itemStore/InventoryStoreAddListView")
);
const InventoryIssueItemView = React.lazy(() =>
  import("../../views/inventory/create/issueItem/InventoryIssueItemView")
);
const InventoryPurchaseItemAddView = React.lazy(() =>
  import("../../views/inventory/create/purchase/InventoryPurchaseItemAddView")
);
const InventoryItemSuppilerAddListView = React.lazy(() =>
  import(
    "../../views/inventory/setup/itemSuppiler/InventoryItemSuppilerAddListView"
  )
);
const InventorySalesProductView = React.lazy(() =>
  import("../../views/inventory/create/sales/InventorySalesProductView")
);
const InventoryReturnIssueItemView = React.lazy(() =>
  import(
    "../../views/inventory/create/return_issue_item/InventoryReturnIssueItemView"
  )
);
const InventorySalesPageReportListView = React.lazy(() =>
  import(
    "../../views/inventory/report/sales_rpt/InventorySalesPageReportListView"
  )
);
const PurchaseReportPageView = React.lazy(() =>
  import("../../views/inventory/report/purchase_rpt/PurchaseReportPageView")
);
const IssueRptListView = React.lazy(() =>
  import("../../components/inventory/issue_rpt/IssueRptListView")
);
const StockReportPageView = React.lazy(() =>
  import("../../views/inventory/report/stock_rpt/StockReportPageView")
);
const LibraryBookAddView = React.lazy(() =>
  import("../../views/library/create/LibraryBookAddView")
);
const LibraryBookAuthorCreateListView = React.lazy(() =>
  import("../../views/library/setup/LibraryBookAuthorCreateListView")
);
const LibraryBookPublicationListView = React.lazy(() =>
  import("../../views/library/setup/LibraryBookPublicationListView")
);
const LibraryBookKindCreateListView = React.lazy(() =>
  import("../../views/library/setup/LibraryBookKindCreateListView")
);
const LibraryBookIssueReturnView = React.lazy(() =>
  import("../../views/library/create/LibraryBookIssueReturnView")
);
const HRDesignationCreateListView = React.lazy(() =>
  import("../../views/hr/setup/HRDesignationCreateListView")
);
const HREmployeeCreateListView = React.lazy(() =>
  import("../../views/hr/create/HREmployeeCreateListView")
);
const HRStaffPayrollCreateListView = React.lazy(() =>
  import("../../views/hr/create/HRStaffPayrollCreateListView")
);
const ExamSubjectGroupCreateListView = React.lazy(() =>
  import("../../views/exam/setup/ExamSubjectGroupCreateListView")
);
const ExamSubjectCategoryCreateListView = React.lazy(() =>
  import("../../views/exam/setup/ExamSubjectCategoryCreateListView")
);
const ExamTypeCreateListView = React.lazy(() =>
  import("../../views/exam/setup/ExamTypeCreateListView")
);
const AcaSubjectCreateListView = React.lazy(() =>
  import("../../views/exam/setup/AcaSubjectCreateListView")
);
const ExamClassWiseSubjectListViewPage = React.lazy(() =>
  import("../../views/exam/reports/subj_list/ExamClassWiseSubjectListViewPage")
);
const ExamSubjectAssignCreateListView = React.lazy(() =>
  import("../../views/exam/create/ExamSubjectAssignCreateListView")
);
const ExamMultiMarksEntryCreateView = React.lazy(() =>
  import("../../views/exam/create/ExamMultiMarksEntryCreateView")
);
const EMISPassoutReportListView = React.lazy(() =>
  import("../../views/exam/reports/passout/EMISPassoutReportListView")
);
const LibrarySearchInformationRptPageListView = React.lazy(() =>
  import(
    "../../views/library/report/search_info/LibrarySearchInformationRptPageListView"
  )
);
const LibraryBookIssueReturnReportPageView = React.lazy(() =>
  import(
    "../../views/library/report/issue_rtn_rpt/LibraryBookIssueReturnReportPageView"
  )
);
const EMISEnrollmentsListPageView = React.lazy(() =>
  import("../../views/exam/reports/enrollments/EMISEnrollmentsListPageView")
);
const EMISGraduateOfYearPageListView = React.lazy(() =>
  import(
    "../../views/exam/reports/graduate_of_year/EMISGraduateOfYearPageListView"
  )
);

const MenuCreateListView = React.lazy(() =>
  import("../../views/application/create/menu/MenuCreateListView")
);
const AcaAddStudentView = React.lazy(() =>
  import("../../views/academics/create/AcaAddStudentView")
);
const AcademicsStudentListView = React.lazy(() =>
  import("../../views/academics/report/AcademicsStudentListView")
);

export const ImportComponent = (route) => {
  switch (route) {
    case "MenuCreateListView":
      return MenuCreateListView;
    case "SubMenuCreateListView":
      return SubMenuCreateListView;
    case "SubSubMenuCreateListView":
      return SubSubMenuCreateListView;
    case "AuthorRoleListView":
      return AuthorRoleListView;
    case "AuthorMenuListView":
      return AuthorMenuListView;
    case "MenuDetailsListView":
      return MenuDetailsListView;
    case "AcaLevelCreateListView":
      return AcaLevelCreateListView;
    case "AcaFacultyCreateListView":
      return AcaFacultyCreateListView;
    case "AcaProgramCreateListView":
      return AcaProgramCreateListView;
    case "AcaYearSemesterCreateListView":
      return AcaYearSemesterCreateListView;
    case "AcaAcademicYearCreateListView":
      return AcaAcademicYearCreateListView;
    case "AcaSectionCreateListView":
      return AcaSectionCreateListView;
    case "AcaTeamCreateListView":
      return AcaTeamCreateListView;
    case "AcaShiftCreateListView":
      return AcaShiftCreateListView;
    case "AcaShiftCreateView":
      return AcaShiftCreateView;
    case "AcaMajorSubjectCreateListView":
      return AcaMajorSubjectCreateListView;
    case "AcaAddStudentView":
      return AcaAddStudentView;
    case "AcademicsStudentListView":
      return AcademicsStudentListView;
    case "AccountGeneralLedgerListView":
      return AccountGeneralLedgerListView;
    case "AccountSubLedgerCreateView":
      return AccountSubLedgerListView;
    case "AccountVoucherEntryCreateListView":
      return AccountVoucherEntryCreateListView;
    case "AccountFiscalYearCreateListView":
      return AccountFiscalYearCreateListView;
    case "AccountParticularListDetailsView":
      return AccountParticularListDetailsView;
    case "AccountTrialReportListView":
      return AccountTrialReportListView;
    case "AccountSheetRptPageListView":
      return AccountSheetRptPageListView;
    case "AccountProfitLossReportPageView":
      return AccountProfitLossReportPageView;
    case "AccountVoucherReportListView":
      return AccountVoucherReportListView;
    case "InventoryAddItemView":
      return InventoryAddItemView;
    case "InventoryItemCategoryAddListView":
      return InventoryItemCategoryAddListView;
    case "InventoryCategoryMasterAddListView":
      return InventoryCategoryMasterAddListView;
    case "InventoryUnitItemAddView":
      return InventoryUnitItemAddView;
    case "InventoryStoreAddListView":
      return InventoryStoreAddListView;
    case "InventoryIssueItemView":
      return InventoryIssueItemView;
    case "InventoryPurchaseItemAddView":
      return InventoryPurchaseItemAddView;
    case "InventoryItemSuppilerAddListView":
      return InventoryItemSuppilerAddListView;
    case "InventorySalesProductView":
      return InventorySalesProductView;
    case "InventoryReturnIssueItemView":
      return InventoryReturnIssueItemView;
    case "InventorySalesPageReportListView":
      return InventorySalesPageReportListView;
    case "PurchaseReportPageView":
      return PurchaseReportPageView;
    case "IssueRptListView":
      return IssueRptListView;
    case "StockReportPageView":
      return StockReportPageView;
    case "LibraryBookAddView":
      return LibraryBookAddView;
    case "LibraryBookAuthorCreateListView":
      return LibraryBookAuthorCreateListView;
    case "LibraryBookPublicationListView":
      return LibraryBookPublicationListView;
    case "LibraryBookKindCreateListView":
      return LibraryBookKindCreateListView;
    case "LibraryBookIssueReturnView":
      return LibraryBookIssueReturnView;
    case "HRDesignationCreateListView":
      return HRDesignationCreateListView;
    case "HREmployeeCreateListView":
      return HREmployeeCreateListView;
    case "HRStaffPayrollCreateListView":
      return HRStaffPayrollCreateListView;
    case "ExamSubjectGroupCreateListView":
      return ExamSubjectGroupCreateListView;
    case "ExamSubjectCategoryCreateListView":
      return ExamSubjectCategoryCreateListView;
    case "ExamTypeCreateListView":
      return ExamTypeCreateListView;
    case "AcaSubjectCreateListView":
      return AcaSubjectCreateListView;
    case "ExamClassWiseSubjectListViewPage":
      return ExamClassWiseSubjectListViewPage;
    case "ExamSubjectAssignCreateListView":
      return ExamSubjectAssignCreateListView;
    case "ExamMultiMarksEntryCreateView":
      return ExamMultiMarksEntryCreateView;
    case "EMISPassoutReportListView":
      return EMISPassoutReportListView;
    case "LibrarySearchInformationRptPageListView":
      return LibrarySearchInformationRptPageListView;
    case "LibraryBookIssueReturnReportPageView":
      return LibraryBookIssueReturnReportPageView;
    case "EMISEnrollmentsListPageView":
      return EMISEnrollmentsListPageView;
    case "EMISGraduateOfYearPageListView":
      return EMISGraduateOfYearPageListView;
    case "AcademicsStudentDetailsView":
      return AcademicsStudentDetailsView;
    case "OrganizationStruListView":
      return OrganizationStruListView;
    case "DefaultDashboardView":
      return DefaultDashboardView;
    case "AppAuthorCreateListView":
      return AppAuthorCreateListView;
    case "AcademicsUpgradeStudentListView":
      return AcademicsUpgradeStudentListView;
    case "AcaEnthinicityCreateListView":
      return AcaEnthinicityCreateListView;
    case "AcaCasteCreateListView":
      return AcaCasteCreateListView;
    case "HREmployeeListView":
      return HREmployeeListView;
    case "CommonSettingCreateListView":
      return CommonSettingCreateListView;
    case "PostalDispatchCreateListView":
      return PostalDispatchCreateListView;
    case "AcaSingleBookReportListView":
      return AcaSingleBookReportListView;
    case "AcaStudentProfileCreateListView":
      return AcaStudentProfileCreateListView;
    case "AnnualYearStudentReportPageListView":
      return AnnualYearStudentReportPageListView;
    case "AnnualYearWiseReportPageListView":
      return AnnualYearWiseReportPageListView;
    case "ExamAdmissionReportPageList":
      return ExamAdmissionReportPageList;
    case "AcaRegNoAndSymbolNoUpdateListView":
      return AcaRegNoAndSymbolNoUpdateListView;
    case "AcademicsStudentEditDetailsView":
      return AcademicsStudentEditDetailsView;
    case "SubLedgerTypeCreateListView":
      return SubLedgerTypeCreateListView;
    case "StudentUsernamePasswordGenerateListVIew":
      return StudentUsernamePasswordGenerateListVIew;
    case "EMISPassedListTranscriptPageListView":
      return EMISPassedListTranscriptPageListView;
    case "StudentSearchReportListPageView":
      return StudentSearchReportListPageView;
    case "AccountDayBookPageListView":
      return AccountDayBookPageListView;
    case "AccountLeaderPageReportView":
      return AccountLeaderPageReportView;
    case "AccountTransactionReportPageListView":
      return AccountTransactionReportPageListView;
    case "LMSHomeworkListView":
      return LMSHomeworkListView;
    case "LMSNoticeCreateListView":
      return LMSNoticeCreateListView;
    case "TeacherUsernamePasswordCreateListView":
      return TeacherUsernamePasswordCreateListView;
    case "AcaDestinationCreateListView":
      return AcaDestinationCreateListView;
    case "PostalReceiveCreateListView":
      return PostalReceiveCreateListView;
    case "LibraryBookListReportView":
      return LibraryBookListReportView;
    case "PhotoUploadUpdateListView":
      return PhotoUploadUpdateListView;
    case "IDCardAndVanCardListView":
      return IDCardAndVanCardListView;
    case 'ExamMarksReportListView':
      return ExamMarksReportListView;
    case 'AcademicsRecordsCreateListView':
      return AcademicsRecordsCreateListView;
    case 'MarksEntrySettingsCreateListView':
      return MarksEntrySettingsCreateListView;
    case 'MarksLedgerFormView':
      return MarksLedgerFormView;
    case 'ExamMarksLedgerListView':
      return ExamMarksLedgerListView;
    case 'ComprehensiveProfileCreateListView':
      return ComprehensiveProfileCreateListView;
    case 'ExcelImportMarksCreateListView':
      return ExcelImportMarksCreateListView;
    case 'ResultsAnalysisReportCreateListView':
      return ResultsAnalysisReportCreateListView;
    case 'CategoryMasterCreateListView':
      return CategoryMasterCreateListView;
    case 'AccItemMasterCreateListView':
      return AccItemMasterCreateListView;
    case 'VendorMasterCreateListView':
      return VendorMasterCreateListView;
    case 'OldStudentMigrateCreateListView':
      return OldStudentMigrateCreateListView;
    case 'AdvancedVoucherReportListView':
      return AdvancedVoucherReportListView;
    case 'AcademicsStudentVanReportCreateListView':
      return AcademicsStudentVanReportCreateListView;
    case 'AccountStudentReportsListView':
      return AccountStudentReportsListView;
    case 'AcademicsTransferandCharacterListView':
      return AcademicsTransferandCharacterListView;
    case 'CharacterTransferPrintReportListView':
      return CharacterTransferPrintReportListView;
    case 'CardCharacterPrintedReportCreateListView':
      return CardCharacterPrintedReportCreateListView;
    case 'AcaCardValidDateCreateListView':
      return AcaCardValidDateCreateListView;
    case 'StudentFullDetailsGetView':
      return StudentFullDetailsGetView;
    case 'StudentSearchCreateListView':
      return StudentSearchCreateListView;
    case 'UsersLogsCreateListView':
      return UsersLogsCreateListView;
    case 'NoticeSetupCreateListView':
      return NoticeSetupCreateListView;
    case 'NoticeofRevisionCreateListView':
      return NoticeofRevisionCreateListView;
    case 'SystemInformationCreateListView':
      return SystemInformationCreateListView;
    case 'ExamProgramWiseSubjectsListView':
      return ExamProgramWiseSubjectsListView;
    case 'ChangeStudentStatusCreateListView':
      return ChangeStudentStatusCreateListView;
    case 'PassedStudentPercentagesCreateListView':
      return PassedStudentPercentagesCreateListView;
    case 'RollGenerateCreateListView':
      return RollGenerateCreateListView;
    case 'MarksYearCorrectionCreateListView':
      return MarksYearCorrectionCreateListView;
    case 'StudentIDWiseEditDetailsView':
      return StudentIDWiseEditDetailsView;
    case 'AssignSubjectRemoveListView':
      return AssignSubjectRemoveListView;
    case 'EMISDashboardCreateListView':
      return EMISDashboardCreateListView;
    case 'UGCStatisticalReportsCreateListView':
      return UGCStatisticalReportsCreateListView;
    case 'VendorMasterCreateListView':
      return VendorMasterCreateListView;
    case 'PurchaseAssetsCreateListView':
      return PurchaseAssetsCreateListView;
    case 'CardDesignCreateListView':
      return CardDesignCreateListView;
    case 'TeacherDetailsReportsListView':
      return TeacherDetailsReportsListView;
    case 'AcaCalenderCreateListView':
      return AcaCalenderCreateListView;
    case 'InventoryItemListCreateListView':
      return InventoryItemListCreateListView;
    default:
      return null;
  }
};
