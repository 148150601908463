import React, { Fragment } from "react";
import ComponentHeading from "../../../../widgets/ComponentHeading";
import { PiChalkboardTeacherThin } from "react-icons/pi";

const TeacherListDetails = ({ onEmployeeList }) => {
  return (
    <Fragment>
      <ComponentHeading
        icon={PiChalkboardTeacherThin}
        label={"Teacher Details List"}
      />
      <div className="relative overflow-x-auto">
        <table className="text-sm text-left rtl:text-right text-slate100 whitespace-nowrap scroll-x-auto dark:text-slate400 rounded w-full">
          <thead className="text-[11px] text-slate700 bg-slate200  capitalize border-b dark:bg-slate900 dark:text-slate400 font-public-sans">
            <tr>
              <th
                scope="col"
                align="center"
                className="px-2 py-1 border dark:border-slate600 w-10"
              >
                {"S.N."}
              </th>
              <th scope="col" className="px-2 py-1 border dark:border-slate600">
                {"Emp. Name"}
              </th>
              <th scope="col" className="px-2 py-1 border dark:border-slate600">
                {"Citizenship No."}
              </th>
              <th scope="col" className="px-2 py-1 border dark:border-slate600">
                {"Gender"}
              </th>
              <th scope="col" className="px-2 py-1 border dark:border-slate600">
                {"Qualification"}
              </th>
              <th scope="col" className="px-2 py-1 border dark:border-slate600">
                {"Types of Service (Full Time/Part Time)"}
              </th>

              <th scope="col" className="px-2 py-1 border dark:border-slate600">
                {"Subject"}
              </th>
              <th scope="col" className="px-2 py-1 border dark:border-slate600">
                {"Faculty"}
              </th>
              <th scope="col" className="px-2 py-1 border dark:border-slate600">
                {"No. of Hours Teach"}
              </th>
            </tr>
          </thead>
          <tbody className="text-slate-800">
            {Array.isArray(onEmployeeList?.data) &&
              onEmployeeList?.data?.map((item, index) => (
                <tr
                  key={index}
                  className="odd:bg-white text-[11px] text-slate700 dark:text-slate400 odd:dark:bg-slate800 even:bg-slate100 even:dark:bg-slate700 border-b font-public-sans dark:border-slate700"
                >
                  <td
                    align="center"
                    className="px-2 border dark:border-slate600 py-1"
                  >
                    {index + 1}.
                  </td>
                  <td className="px-2 border dark:border-slate600 capitalize">
                    {`${item?.first_name || ""} ${item.middle_name || ""}  ${
                      item.last_name || ""
                    }`}
                  </td>
                  <td
                    align="left"
                    className="px-2 border dark:border-slate600 "
                  >
                    {item?.citiz_no}
                  </td>
                  <td
                    align="left"
                    className="px-2 border dark:border-slate600 "
                  >
                    {parseInt(item?.gender) === 1 ? "Male" : "Female"}
                  </td>
                  <td
                    align="left"
                    className="px-2 border dark:border-slate600 "
                  >
                    {item?.qualification}
                  </td>
                  <td
                    align="left"
                    className="px-2 border dark:border-slate600 "
                  >
                    {parseInt(item?.part_full) === 1
                      ? "Part Time"
                      : "Full Time"}
                  </td>
                  <td
                    align="left"
                    className="px-2 border dark:border-slate600 "
                  >
                    {item?.subj}
                  </td>
                  <td className="px-2 border dark:border-slate600 ">
                    {item?.faculty}
                  </td>
                  <td className="px-2 border dark:border-slate600 ">
                    {item?.no_of_teach_hr}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default TeacherListDetails;
