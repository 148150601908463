import { createSlice } from '@reduxjs/toolkit';

export const librarySlice = createSlice({
    name: 'library',
    initialState: {
        library_issue_return_rpt: [],
        library_search_list_rpt:[],
        library_book_history_rpt: []
    },
    reducers: {
        LibraryIssueReturnReport: (state, action) => {
            state.library_issue_return_rpt = action.payload;
        },
        LibrarySearchInformationListView:(state, action)=>{
            state.library_search_list_rpt = action.payload;
        },
        LibraryBookHistoryListView:(state, action)=>{
            state.library_book_history_rpt = action.payload;
        },
    }
});

// Action creators are generated for each case reducer function
export const { LibraryIssueReturnReport, LibraryBookHistoryListView, LibrarySearchInformationListView } = librarySlice.actions
export default librarySlice.reducer