import React, { useEffect, useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Sider from "antd/es/layout/Sider";
import PropTypes from "prop-types";
import { List, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import BrandLogo from "./widgets/sidebar/BrandLogo";
import MenuItem from "./widgets/sidebar/MenuItem";
import SidebarDrawer from "./widgets/sidebar/SidebarDrawer";
import { MenuDrawer } from "./widgets/sidebar/utils/MenuDrawer";
import { MenuLink } from "./widgets/sidebar/utils/MenuLink";
import { ImportMenuIcon } from "../../widgets/import_com/ImportMenuIcon";
import { useDispatch, useSelector } from "react-redux";
import ImportDrawerCom from "../../widgets/import_com/ImportDrawerCom";
import { SidebarReportClose } from "../../store/softSlice";

const AppSidebar = (props) => {
    const { window } = props;
    const [openDrawerId, setOpenDrawerId] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const { onSidebarMenu } = props;
    const toggleDrawer = (drawerId) => () => {
        setOpenDrawerId((prevId) => (prevId === drawerId ? null : drawerId));
    };
    let [onReportForm, setOnReportForm] = useState(false);
    let [onComponentName, setOnComponentName] = useState("");
    const dispatch = useDispatch()
    const handleMenuClick = (menu) => {
        if (openDrawerId === menu?.menu_id) {
            setOpenDrawerId(null);
        } else {
            setOpenDrawerId(menu?.menu_id);
            localStorage.setItem("active_menu", menu?.menu_id);
            setSearchTerm("");
            dispatch(SidebarReportClose({ "action": false }));
        }
    };
    const active_menu = localStorage.getItem("active_menu") || "";
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const Components = ImportDrawerCom(onComponentName ? onComponentName : null);
    const { sidebarclose } = useSelector((state) => state?.software)
    useEffect(() => {
        setOnReportForm(sidebarclose?.action);
    }, [sidebarclose])
    const container =
        window !== undefined ? () => window().document.body : undefined;
    return (
        <React.Fragment>
            <Sider className="!overflow-auto !h-screen !fixed !z-[9999999999] !left-0 !top-0 !bottom-0"
                width={80}
                breakpoint="lg"
            >
                <div className="z-50 left-0 fixed top-0 overflow-x-auto dark:bg-slate900 overflow-y-hidden bg-white border dark:border-slate800 border-slate200 h-full">
                    <div className="flex-shrink-0">
                        <ul className="nav nav-pills flex flex-col" style={{ width: "80px" }}>
                            {onSidebarMenu?.length>0 && <BrandLogo />}
                            {Array.isArray(onSidebarMenu) &&
                                onSidebarMenu?.map((menu, index) => {
                                    const MenuIcon = ImportMenuIcon(menu?.icon)
                                    return (

                                        <React.Fragment key={index}>
                                            <MenuItem onClick={() => handleMenuClick(menu)} menu_title={menu?.name} menu_icon={MenuIcon} active_menu={menu?.menu_id?.toString() === active_menu ? true : false} />
                                            <SwipeableDrawer
                                                container={container}
                                                anchor="left"
                                                open={openDrawerId === menu?.menu_id}
                                                onClose={toggleDrawer(menu?.menu_id)}
                                                onOpen={toggleDrawer(menu?.menu_id)}
                                                disableSwipeToOpen={false}
                                                ModalProps={{
                                                    keepMounted: true,
                                                }}
                                            >
                                                <Paper className="h-full dark:bg-slate900 w-[1260px]">
                                                    <List>
                                                        <div className="w-full font-public-sans">
                                                            <div className="ml-[90px] overflow-y-hidden overflow-x-hidden">
                                                                <div className="p-4 w-sidebarWidth">
                                                                    <div className="p-4">
                                                                        <input name="Search" placeholder="Search....." type="text" autoComplete="false" onChange={handleSearchChange} required className="block ring-[1px] w-full outline-none pl-3 rounded-md border-0 py-1.5 text-slate900 placeholder:text-sm placeholder:font-light shadow-sm pr-3 ring-inset ring-slate300 placeholder:text-gray-400 font-public-sans focus:outline-none focus:ring-customPrimary focus:ring-[1px] sm:text-sm sm:leading-6 dark:text-slate400 dark:ring-slate700 dark:bg-slate800" />
                                                                    </div>
                                                                    <div className="grid md:grid-cols-2 sm:grid-cols-1 sm:grid xl:grid-cols-3">
                                                                        {menu?.submenus?.map((submenu) => (
                                                                            <div className="lg:border-r md:border-r sm:mt-2 dark:border-slate800 ps-4" key={submenu?.sub_m_id}>
                                                                                <ul>
                                                                                    <p className="font-semibold mb-3 dark:text-slate500">{submenu?.name}</p>
                                                                                    {submenu?.subsubmenus && (
                                                                                        <div>
                                                                                            {submenu?.subsubmenus?.filter(subsubmenu =>
                                                                                                subsubmenu?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) &&
                                                                                                subsubmenu?.showmenu === true
                                                                                            )?.map((subsubmenu) => {
                                                                                                return (
                                                                                                    <li className="mb-3" key={subsubmenu?.sub_sub_m_id} onClick={() => setOpenDrawerId(null)}>
                                                                                                        {subsubmenu?.sidedrawer === false &&
                                                                                                            <Link to={subsubmenu?.link}><MenuLink label={subsubmenu?.name} /></Link>
                                                                                                        }
                                                                                                        {subsubmenu?.sidedrawer === true &&
                                                                                                            <MenuDrawer onClick={() => { setOnReportForm(true); setOnComponentName(subsubmenu?.component); }} label={subsubmenu?.name} />
                                                                                                        }
                                                                                                    </li>
                                                                                                );
                                                                                            })
                                                                                            }
                                                                                        </div>
                                                                                    )}
                                                                                </ul>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </List>
                                                </Paper>
                                            </SwipeableDrawer>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <SwipeableDrawer
                        container={container} className="!dark:bg-slate900 !bg-slate200"
                        anchor="left"
                        open={onReportForm}
                        onOpen={() => setOnReportForm(onReportForm)}
                        onClose={() => setOnReportForm(null)} >
                        <SidebarDrawer onReportForm={onReportForm} Components={Components} />
                    </SwipeableDrawer>
                </div>
            </Sider>

        </React.Fragment>
    );
}

AppSidebar.propTypes = {
    window: PropTypes.func,
};

export default AppSidebar;
