import React, { Fragment } from 'react';
import ImageLazyLoading from '../../../../widgets/ImageLazyLoading';
import Logo from '../../../../assets/img/logo/logo.png';
import { useSelector } from 'react-redux';
import AppURL from '../../../../widgets/AppURL';
const BrandLogo = () => {
  const { menu_list } = useSelector((state) => state?.software);
  const Org_Details = menu_list?.structure;

  return (
    <Fragment>
      <div className={`flex-col py-2 ${menu_list?.soft_ugc === 0 ? "mb-3" : "mb-0"}`}>
        <div className='flex justify-center'>
          {
            menu_list?.soft_ugc === 0 && <ImageLazyLoading className="px-3" width={80} src={Logo} />
          }
          {
            menu_list?.soft_ugc === 1 && <ImageLazyLoading className="px-3" width={80} src={AppURL.BaseURL + Org_Details?.logo} />
          }
        </div>
        {menu_list?.soft_ugc === 0 && <div className='flex items-center align-middle text-center w-full'>
          <h2 className='text-center w-full font-public-sans font-bold text-[16px] text-customPrimary'>NEXAPP</h2>
        </div>}
      </div>
    </Fragment>
  )
}

export default BrandLogo