import { useLanguage } from "./LangContext";

export const MenuOptions = [
    { value: "1", label: "Application" },
    { value: "2", label: "Academics" },
    { value: "3", label: "Account" },
    { value: "4", label: "Inventory" },
    { value: "6", label: "Library" },
    { value: "7", label: "HR" },
    { value: "5", label: "Exam" },
    { value: "8", label: "LMS" },

];
export const BloodGroupOptions = [
    { value: "O-", label: "O-" },
    { value: "O+", label: "O+" },
    { value: "A-", label: "A-" },
    { value: "A+", label: "A+" },
    { value: "B-", label: "B-" },
    { value: "B+", label: "B+" },
    { value: "AB-", label: "AB-" },
    { value: "AB+", label: "AB+" },
];

export const ReligionsOptions = [
    { value: "Hinduism", label: "Hinduism" },
    { value: "Buddhism", label: "Buddhism" },
    { value: "Christianity", label: "Christianity" },
    { value: "Jainism", label: "Jainism" },
    { value: "Judaism", label: "Judaism" },
    { value: "Sikhism", label: "Sikhism" },
];
export const OneOrTwoWayOptions = [
    { value: "1", label: "One Way" },
    { value: "2", label: "Two Way" },
];

export const PrintNonPrintOptions = [
    { value: "0", label: "Not Printed" },
    { value: "1", label: "Printed" },
];
export const CardOptions = [
    { value: "1", label: "Character/Transfer" },
    { value: "3", label: "ID Card" },
    { value: "2", label: "Van Card" },
];
export const ProgramOptions = (programs) => {
    const { language } = useLanguage();
    if (programs && Array?.isArray(programs)) {
        return programs?.map((program, index) => ({
            value: program?.programid?.toString(),
            label: program?.programid + ". " + program?.programtitle,
        }));
    } else {
        return [];
    }
}
export const YearListOptions = (yearsList) => {
    if (yearsList && Array?.isArray(yearsList)) {
        return yearsList?.map((yearr, index) => ({
            value: yearr?.sn?.toString(),
            label: yearr?.yearr,
        }));
    } else {
        return [];
    }
}
export const NepYearListOptions = (yearsList) => {
    if (yearsList && Array?.isArray(yearsList)) {
        return yearsList?.map((yearr, index) => ({
            value: yearr?.sn?.toString(),
            label: yearr?.yearr_ne,
        }));
    } else {
        return [];
    }
}
export const OptionalGroupOptions = (items)=>{
    if (items && Array.isArray(items)) {
        return items?.map((item, index) => ({
            value: item?.sn?.toString(),
            label: item?.sn + (". ") + item?.optional_grp_name 
        }));
    } else {
        return [];
    }
}

export const SubjectCategoryOptions = (items)=>{
    if (items && Array.isArray(items)) {
        return items?.map((item, index) => ({
            value: item?.sn?.toString(),
            label: item?.sn + (". ") + item?.subj_cat_name 
        }));
    } else {
        return [];
    }
}

export const DestinationOptions = (items)=>{
    if (items && Array.isArray(items)) {
        return items?.map((item, index) => ({
            value: item?.sn?.toString(),
            label: item?.sn + (". ") + item?.dest_name 
        }));
    } else {
        return [];
    }
}

export const LevelOptions = (levels) => {
    const { language } = useLanguage();
    if (levels && Array?.isArray(levels)) {
        return levels?.map((level, index) => ({
            value: level?.levelid?.toString(),
            label:index + 1 + ". " + level?.levelname,
        }));
    } else {
        return [];
    }
}

export const FacultyOptions = (faculties) => {
    const { language } = useLanguage();
    if (faculties && Array?.isArray(faculties)) {
        return faculties?.map((faculty, index) => ({
            value: faculty?.facid?.toString(),
            label: index + 1 + ". " + faculty?.facname,

        }));
    } else {
        return [];
    }
}

export const YesNoOptions = (yes_no) => {
    const { language } = useLanguage();
    if (yes_no && Array.isArray(yes_no)) {
        return yes_no.map((item, index) => ({
            value: item.value.toString(),
            label: item.label,
        }));
    } else {
        return [];
    }
};

export const ExamTypeOptions = (exam_types) => {
    const { language } = useLanguage();
    if (exam_types && Array?.isArray(exam_types)) {
        return exam_types?.map((item, index) => ({
            value: item?.ex_type_id?.toString(),
            label: index + 1 + ". " + item?.ex_type_name,
            fm: item?.ex_type_fm,
            p_fm: item?.ex_mrg_perc,
            pm:item?.ex_type_pm
        }));
    } else {
        return [];
    }
}

export const SubMenuOptions = (sub_menu) => {
    if (sub_menu && Array?.isArray(sub_menu)) {
        return sub_menu?.map((item, index) => ({
            value: item?.sub_m_id?.toString(),
            label: item?.sub_m_id + ". " + item?.name + "(" + item?.name_nep + ")",
        }));
    } else {
        return [];
    }
}

export const DesignationOptions = (dest) => {
    const { language } = useLanguage();
    // General Ledger Options
    if (dest && Array?.isArray(dest)) {
        return dest?.map((item, index) => ({
            value: item?.desig_id?.toString(),
            label: language ? item?.desig_name : item?.desti_name_nep // Use the current language to choose the label
        }));
    } else {
        return [];
    }
}

export const SubLedgerTypeOptions = (options) => {
    const { language } = useLanguage();
    // General Ledger Options
    if (options && Array?.isArray(options)) {
        return options?.map((item, index) => ({
            value: item?.sn?.toString(),
            label: language ? item?.sub_ledger_type_name : item?.sub_ledger_type_name_nep // Use the current language to choose the label

        }));
    } else {
        return [];
    }
}
export const BranchOptions = [
    { value: "1", label: "College" },
];
export const BackRegularOptions = [
    { value: "0", label: "Regular" },
    { value: "1", label: "Back" },
];
export const ActiveInactiveOptions = (active_inactive_list) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language

    if (active_inactive_list && Array.isArray(active_inactive_list)) {
        return active_inactive_list.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.label_nep // Use the current language to choose the label
        }));
    } else {
        return [];
    }
};

export const AcademicsYearOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array?.isArray(items)) {
        return items?.map((item, index) => ({
            value: item?.acdmcyearid?.toString(),
            label: index + 1 + ". " + item?.acdmcyear,
        }));
    } else {
        return [];
    }
}

export const MediumOptions = (lanugage_medium) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language

    if (lanugage_medium && Array.isArray(lanugage_medium)) {
        return lanugage_medium.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.label_nep // Use the current language to choose the label
        }));
    } else {
        return [];
    }
};

export const SectionOptions = (sections) => {
    const { language } = useLanguage()
    if (sections && Array?.isArray(sections)) {
        return sections?.map((sec, index) => ({
            value: sec?.sectionid?.toString(),
            label:index + 1 + ". " + sec?.sectionname,
        }));
    } else {
        return [];
    }
}

export const TeamOptions = (teams) => {
    const { language } = useLanguage();
    if (teams && Array?.isArray(teams)) {
        return teams?.map((team, index) => ({
            value: team?.teamid?.toString(),
            label: index + 1 + ". " + team?.teamname,

        }));
    } else {
        return [];
    }
}

export const ShiftsOptions = (shifts) => {
    const { language } = useLanguage();
    if (shifts && Array?.isArray(shifts)) {
        return shifts?.map((shift, index) => ({
            value: shift?.shiftid?.toString(),
            label: language ? index + 1 + ". " + shift?.shiftname : index + 1 + ". " + shift?.shiftnamenep,
        }));
    } else {
        return [];
    }
}


export const YearSemesterOptions = (year_sem) => {
    // General Ledger 
    if (year_sem && Array?.isArray(year_sem)) {
        return year_sem?.map((item, index) => ({
            value: item?.yearsemesterid?.toString(),
            label:index + 1 + ". " + item?.yearsemestername,
        }));
    } else {
        return [];
    }
}


export const MajorSubjectOptions = (major_subject) => {
    // General Ledger Options
    if (major_subject && Array?.isArray(major_subject)) {
        return major_subject?.map((ms_data, index) => ({
            value: ms_data?.sn?.toString(),
            label: ms_data?.sn + ". " + ms_data?.msubjectname,
        }));
    } else {
        return [];
    }
}


export const SubjectOptions = (subjects) => {
    // General Ledger Options
    if (subjects && Array?.isArray(subjects)) {
        return subjects?.map((subj, index) => ({
            value: subj?.sn?.toString(),
            label: subj?.sn + ". " + subj?.subj_name,
        }));
    } else {
        return [];
    }
}
export const MarriedOptions = (married_status_list) => {
    const { language } = useLanguage();
    if (married_status_list && Array.isArray(married_status_list)) {
        return married_status_list.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.label_nep
        }));
    } else {
        return [];
    }
};

export const EmployeeOptions = (emps) => {
    if (emps && Array?.isArray(emps)) {
        return emps?.map((emp, index) => ({
            value: emp?.member_sn,
            label: `${emp?.member_sn?emp?.member_sn:""}. ${emp?.first_name?emp?.first_name:""} ${emp?.middle_name?emp?.middle_name:""} ${emp?.last_name?emp?.last_name:""}`,
            branch_id: emp?.branch_id,
            emp_sn: emp?.emp_sn,
            designation: emp?.designation,
            grade_amt: emp?.grade_amt,
            grade_count: emp?.grade_count,
            initail_salary: emp?.initail_salary,
            pf_amount: emp?.pf_amount,
            pf_status: emp?.pf_status,
            pf_thap_perc: emp?.pf_thap_perc,
            ss_tax: emp?.ss_tax,
            tax_amt: emp?.tax_amt,
            allowance: emp?.allowance||0,
            
        }));
    } else {
        return [];
    }
}

export const GenderOptions = (gender_list) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language

    if (gender_list && Array.isArray(gender_list)) {
        return gender_list.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.label_nep // Use the current language to choose the label
        }));
    } else {
        return [];
    }
};

export const MonthOptions = [
    { value: "01", label: "Baishakh" },
    { value: "02", label: "Jestha" },
    { value: "03", label: "Ashadh" },
    { value: "04", label: "Shrawan" },
    { value: "05", label: "Bhadra" },
    { value: "06", label: "Ashwin" },
    { value: "07", label: "Kartik" },
    { value: "08", label: "Mangsir" },
    { value: "09", label: "Poush" },
    { value: "10", label: "Magh" },
    { value: "11", label: "Falgun" },
    { value: "12", label: "Chaitra" },
];



export const GeneralLedgerTypeOptions = (itels) => {
    if (itels && Array.isArray(itels)) {
        return itels.map((item, index) => ({
            value: item.value.toString(),
            label: item.label
        }));
    } else {
        return [];
    }
};

export const CasteOptions = (castes) => {
    if (castes && Array?.isArray(castes)) {
        return castes?.map((item, index) => ({
            value: item?.caste_id?.toString(),
            label: item?.caste_id + 1 + ". " + item?.caste_name,
        }));
    } else {
        return [];
    }
}
export const BoardOptions = (boards) => {
    if (boards && Array?.isArray(boards)) {
        return boards?.map((item, index) => ({
            value: item?.sn?.toString(),
            label:index + 1 + ". " + item?.board_name,
        }));
    } else {
        return [];
    }
}

export const ProvinceOptions = (province) => {
    if (province && Array.isArray(province)) {
        return province.map((item, index) => ({
            value: item?.province_id.toString(),
            label: index + 1 + ". " + item?.province_name,
        }));
    } else {
        return [];
    }
}

export const DistrictOptions = (districtData) => {
    if (districtData && Array?.isArray(districtData)) {
        return districtData?.map((district, index) => ({
            value: district?.district_id?.toString(),
            label: index + 1 + ". " + district?.district_name,
        }));
    } else {
        return [];
    }
}

export const MunOptions = (munData) => {

    // General Ledger Options
    if (munData && Array?.isArray(munData)) {
        return munData?.map((mun, index) => ({
            value: mun?.id?.toString(),
            label: index + 1 + ". " + mun?.mun_name,
        }));
    } else {
        return [];
    }
}

export const SubCasteOptions = (onSubCasteData) => {
    // General Ledger Options
    if (onSubCasteData && Array?.isArray(onSubCasteData)) {
        return onSubCasteData?.map((sub_caste, index) => ({
            value: sub_caste?.sub_caste_id?.toString(),
            label: `${sub_caste?.sub_caste_id + (". ") + sub_caste?.sub_caste_name}`,
        }));
    } else {
        return [];
    }
}

export const QualificationsOptions = (items) => {
    // General Ledger Options
    if (items && Array?.isArray(items)) {
        return items?.map((item, index) => ({
            value: item?.sn?.toString(),
            label: `${item?.sn + (". ") + item?.qualification_name}`,
        }));
    } else {
        return [];
    }
}

export const TrasactionOptions = (trans) => {
    if (trans && Array.isArray(trans)) {
        return trans.map((item, index) => ({
            value: item.value.toString(),
            label: item.label,
        }));
    } else {
        return [];
    }
};

export const AcGeneralLedgOptions = (ledg_list) => {
    if (ledg_list && Array?.isArray(ledg_list)) {
        return ledg_list?.map((item, index) => ({
            value: item?.glid?.toString(),
            label:index+1 + ". " + item?.gl_name
        }));
    } else {
        return [];
    }
}


export const SubLedgersOptions = (ledger) => {
    if (ledger && Array.isArray(ledger)) {
        return ledger?.map((ledger, index) => ({
            value: ledger?.sglsn?.toString(),
            label: `${ledger?.sglsn + (". ") + ledger?.sub_ledg_name}`,
            userShow: ledger?.mem_cate_id
        }))
    }
}

export const ParticularOptions = (particulars) => {
    if (particulars && Array?.isArray(particulars)) {
        return particulars?.map((item, index) => ({
            value: item?.particular_id?.toString(),
            label: item?.particular_id + ". " + item?.particular_name,
            assets_dep_pers: item?.assets_dep_pers,
            is_debit: item?.is_debit,
        }));
    } else {
        return [];
    }
}
export const ParticularAssetsItemsOptions = (particulars) => {
    if (particulars && Array?.isArray(particulars)) {
        return particulars?.map((item, index) => ({
            value: item?.particular_id?.toString(),
            label: item?.particular_id + ". " + item?.particular_name,
            is_debit: item?.is_debit,
        }));
    } else {
        return [];
    }
}
export const DebitCreaditOptions = (debit_list) => {
    if (debit_list && Array.isArray(debit_list)) {
        return debit_list.map((item, index) => ({
            value: item.value.toString(),
            label: item.label
        }));
    } else {
        return [];
    }
};

export const FiscalYearOptions = (fiscal_year) => {
    if (fiscal_year && Array?.isArray(fiscal_year)) {
        return fiscal_year?.map((item, index) => ({
            value: item?.fiscal_year_id?.toString(),
            label: item?.fiscal_year_id + ". " + item?.fiscal_year_name,
        }));
    } else {
        return [];
    }
}

export const MasterCategoryOptions = (category) => {
    // Master Category Options
    if (category && Array?.isArray(category)) {
        return category?.map((cate, index) => ({
            value: cate?.product_master_id?.toString(),
            label: cate?.product_master_id + ". " + cate?.product_master_name,
        }));
    } else {
        return [];
    }
}

export const SubMasterOptions = (subMaster) => {
    if (subMaster && Array?.isArray(subMaster)) {
        return subMaster?.map((item, index) => ({
            value: item?.product_sub_master_id?.toString(),
            label:item?.product_sub_master_id + ". " + item?.product_sub_master_name,
        }));
    } else {
        return [];
    }
}

export const ItemUnitListOptions = (units) => {
    if (units && Array?.isArray(units)) {
        return units?.map((item, index) => ({
            value: item?.unit_id?.toString(),
            label: item?.unit_id + ". " + item?.short_name,
        }));
    } else {
        return [];
    }
}

export const StudentListOptions = (students) => {
    if (students && Array.isArray(students)) {
        return students.map((item, index) => ({
            value: item.member_sn.toString(),
            label: `${item?.member_sn}. ${item?.first_name} ${item?.middle_name ? item?.middle_name : ""} ${item?.last_name}`
        }));
    } else {
        return [];
    }
};

export const StudentSearchListOptions = (students) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language
    if (students && Array.isArray(students)) {
        return students?.map((item, index) => ({
            value: item?.member_sn?.toString(),
            label: `${item?.member_sn}. ${item?.first_name} ${item?.middle_name ? item?.middle_name : ""} ${item?.last_name}(${item?.phone})`,
            name: `${item?.first_name} ${item?.middle_name ? item?.middle_name : ""} ${item?.last_name}` 
          
        }));
    } else {
        return [];
    }
};
export const ACStudentSearchListOptions = (students) => {
    if (students && Array.isArray(students)) {
        return students?.map((item, index) => ({
            value: item?.member_sn?.toString(),
            label: `${item?.member_sn}. ${item?.first_name} ${item?.middle_name ? item?.middle_name : ""} ${item?.last_name} ${parseInt(item?.bus_facility)===(1||2)?` | Van: ${parseInt(item?.bus_facility)===1?`${item?.way} ${item?.destination_name} (Rs ${item?.destination_amount})` :parseInt(item?.bus_facility)===2? item?.destination_name+" Cancel":"" }`:""} | Phone No.: ${item?.phone} ${item?.old_van?`| OLD VAN: ${item?.old_van}`:""}`,
            name: `${item?.first_name} ${item?.middle_name ? item?.middle_name : ""} ${item?.last_name}`,
            library_fine_amt: item?.library_fine_amt
        }));
    } else {
        return [];
    }
};

export const ProductOptionsListOptions = (products) => {
    const { language } = useLanguage();

    if (products && Array?.isArray(products)) {
        return products?.map((product, index) => ({
            value: product?.product?.product_id,
            label: `${language ? product?.product?.product_id + ". " + product?.product?.product_name : product?.product?.product_id + ". " + product?.product?.product_name_nep}`,
            price: product?.product?.purchase_rate,
            available: product?.available_quantity
        }));
    } else {
        return [];
    }
}


export const ProductSuppilerOptions = (suppiler) => {
    const { language } = useLanguage();
    // General Ledger Options
    if (suppiler && Array?.isArray(suppiler)) {
        return suppiler?.map((item, index) => ({
            value: item?.sup_id?.toString(),
            label: `${language ? index + 1 + ". " + item?.sup_name : index + 1 + ". " + item?.sup_name_nep}`,
        }));
    } else {
        return [];
    }
}


export const ProductStoreOptions = (store) => {
    const { language } = useLanguage();
    if (store && Array?.isArray(store)) {
        return store?.map((item, index) => ({
            value: item?.store_id?.toString(),
            label: `${language ? item?.store_id + ". " + item?.store_name : item?.store_id + ". " + item?.store_name_nep}`,
        }));
    } else {
        return [];
    }
}



export const PurchaseStatusOptions = (items) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language

    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.nep_label // Use the current language to choose the label
        }));
    } else {
        return [];
    }
};

export const ProductSellingOptionsListOptions = (products) => {
    const { language } = useLanguage();

    if (products && Array?.isArray(products)) {
        return products?.map((item, index) => ({
            value: item?.product?.product_id,
            label: `${language ? item?.product?.product_id + ". " + item?.product?.product_name : item?.product_id + ". " + item?.product?.product_name_nep}`,
            price: item?.product?.sales_rate,
            available: item?.available_quantity
        }));
    } else {
        return [];
    }
}

export const StockReportOptions = [
    { value: "0", label: "Total Purchase Report" },
    { value: "1", label: "Total Sales Report" },
    { value: "2", label: "Total Issue Report" },
    { value: "3", label: "Total Available Report" },
];

export const BookAuthorOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items?.map((item, index) => ({
            value: item?.author_id?.toString(),
            label: language ? item?.author_id + (". ") + item?.author_name : item?.author_id + (". ") + item?.author_name_nep
        }));
    } else {
        return [];
    }
};

export const BookPublicationOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item?.pub_id?.toString(),
            label: language ? (item?.pub_id + (". ") + item?.pub_name) : (item?.pub_id + (". ") + item?.pub_name_nep)
        }));
    } else {
        return [];
    }
};

export const BookCategoryOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item?.sn?.toString(),
            label: language ? item?.sn + (". ") + item?.book_cate_name : item?.sn + (". ") + item?.book_cate_name_nep
        }));
    } else {
        return [];
    }
};


export const StudentProfileOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item?.sn?.toString(),
            label: language ? item?.sn + (". ") + item?.profile_name : item?.sn + (". ") + item?.profile_name_nep
        }));
    } else {
        return [];
    }
};
export const BookKindOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item.value?.toString(),
            label: language ? item.label : item.label_nep
        }));
    } else {
        return [];
    }
};

export const BookTypeOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item.value?.toString(),
            label: language ? item.label : item.label_nep
        }));
    } else {
        return [];
    }
};


export const IssuedForOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item.value?.toString(),
            label: language ? item.label : item.label_nep
        }));
    } else {
        return [];
    }
};


export const TirekoNatirekoOptions = [
    { value: "0", label: "All" },
    { value: "1", label: "Paid" },
    { value: "2", label: "Unpaid" },
];
export const CashBankOptions = [
    { value: "0", label: "Cash" },
    { value: "1", label: "Bank" },
    { value: "2", label: "Payable" },
];

export const PartFullTimeOptions = [
    { value: "1", label: "Part Time" },
    { value: "2", label: "Full Time" },
];
export const TeachNoTeachOptions = [
    { value: "1", label: "Teachning Staff" },
    { value: "2", label: "Non Teaching Staff" },
];
export const StaffTypeOptions = [
    { value: "1", label: "Permanent" },
    { value: "2", label: "Temporary" },
    { value: "3", label: "Contract" },
    { value: "4", label: "Part Time" },
];
