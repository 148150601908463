import React from 'react';
import { Paper } from "@mui/material";

const SidebarDrawer = ({ onReportForm, Components }) => {
    return (
        <Paper className="h-full dark:!bg-slate900 !bg-slate100">
            <div className="p-6 pl-8 w-[400px] ml-20">
                <div>
                    {onReportForm && Components}
                </div>
            </div>
        </Paper>
    )
}

export default SidebarDrawer