import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import { LoginContext } from './AuthContext';

const RequireAuth = () => {
    const location = useLocation();
    const access_token = localStorage.getItem("access_token");
    const { authUser, setOnAuthUser } = useContext(LoginContext);

    useEffect(() => {
      if (!access_token) {
        setOnAuthUser(false);
      } else {
        try {
          const decode_token = jwtDecode(access_token);
          setOnAuthUser(decode_token?.token_type === 'access');
        } catch (error) {
          console.error("Token decoding failed:", error);
          setOnAuthUser(false);
        }
      }
    }, [access_token, setOnAuthUser]);
    return (
      authUser ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />
    );
};

export default RequireAuth;
