import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SidebarReportClose } from '../../../store/softSlice';
import { fetchDataFromApi } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { useFormik } from 'formik';
import DefaultSelectOptionBox from '../../../widgets/DefaultSelectBox';
import { ClearBtn, SearchBtn } from '../../../widgets/AppBtn';
import { ItemIssueRptList } from '../../../store/inventorySlice';
import ReportHeading from '../../../widgets/ReportHeading';
import { FiscalYearOptions } from '../../../widgets/Options';
import InputNumberBox from '../../../widgets/InputNumberBox';
import { FormatDate, NepaliACDateFormat } from '../../../widgets/DateFormat';
import { ToastError } from '../../../widgets/ToastMessage';


const IssueRptForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }

    const [onManageFiscalYear, setOnFiscalYear] = useState([]);
    const fetchDataBalanceSheet = async () => {
        try {
            await fetchDataFromApi(AppURL.AccountsURL + "account-fiscal-year-date-list-view/").then((res) => {
                setOnFiscalYear(res);
                // Filter out the data where is_current_fiscal is true
                const currentFiscalYearData = res?.data?.filter(item => item?.is_current_fiscal === true);
                // Assuming currentFiscalYearData contains only one item, set the state accordingly
                if (currentFiscalYearData?.length > 0) {
                    setOnSelectValues({ fiscal_year_name: currentFiscalYearData[0]?.fiscal_year_id });
                } else {
                    // Handle case where no current fiscal year data is found
                    setOnSelectValues({ fiscal_year_name: "0" });
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
    const FiscalYearOption = FiscalYearOptions(onManageFiscalYear?.data);

    useEffect(() => {
        fetchDataBalanceSheet();
    }, []);

    const [selectValues, setOnSelectValues] = useState({
        fiscal_year_name: ""
    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data.value,
        }));
    };

    const [selectedFiscalYear, setOnSelectedFiscalYear] = useState([]);
    const [selectedDate, setOnSelectedDate] = useState({
        from_date: "",
        to_date: ""
    });
    useEffect(() => {
        const FilterFiscalYear = onManageFiscalYear?.data?.find((fiscalYear) => fiscalYear?.fiscal_year_id?.toString() === selectValues?.fiscal_year_name ? selectValues?.fiscal_year_name : 0);
        setOnSelectedFiscalYear(FilterFiscalYear);
    }, [onManageFiscalYear, selectValues.fiscal_year_name]);

    const FromDateNPFormat = NepaliACDateFormat(selectedFiscalYear?.from_date_n || "2011/11/11");
    const DefaultToDateFormat = NepaliACDateFormat(selectedFiscalYear?.to_date_n || "2011/11/11");

    const initialValues = {
        fiscal_year_name: selectValues?.fiscal_year_name || "",
        from_date: FromDateNPFormat,
        to_date: DefaultToDateFormat,
        branch_id: "",
    }
    const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                try {
                    if (values?.from_date === '2011.11.11') {
                        ToastError("Reselect Your Fiscal Year")
                    } else if (values.from_date >= FromDateNPFormat && values.from_date <= DefaultToDateFormat && values?.to_date <= DefaultToDateFormat && values?.to_date >= FromDateNPFormat) {
                        await fetchDataFromApi(AppURL.InventoryURL + `inventory-product-issue-report-list-view/?FromDateN=${selectedDate?.from_date}&&ToDateN=${selectedDate?.to_date}`).then((res) => {
                            dispatch(ItemIssueRptList(res));
                            CloseDispatch();
                            navigate("/inventory-issue-report-page-list-view");
                        });
                    } else {
                        ToastError("Please Check Your Fiscal Date")
                    }

                } catch (error) {
                    console.log(error);
                }
            },
        });

    useEffect(() => {
        setOnSelectedDate({
            from_date: FormatDate(values.from_date) || "",
            to_date: FormatDate(values.to_date) || "",
        });
    }, [values.from_date, values.to_date]);

    return (
        <div className=''>
            <ReportHeading heading="Product Issue Report" />
            <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <DefaultSelectOptionBox name="fiscal_year_name" label="Fiscal Year" options={FiscalYearOption} value={selectValues.fiscal_year_name} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.fiscal_year_name} errors={errors.fiscal_year_name} required={true} />
                        </Grid>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <InputNumberBox name="from_date" value={values.from_date} disabled={false} handleChange={handleChange} mask="9999.99.99" required={true} label={"From Date"} />
                        </Grid>

                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <InputNumberBox name="to_date" value={values.to_date} disabled={false} handleChange={handleChange} mask="9999.99.99" required={true} label={"To Date"} />
                        </Grid>
                    </Grid>
                    <div className='flex justify-between mt-4'>
                        <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default IssueRptForm