import { createSlice } from '@reduxjs/toolkit';

export const emis_rptSlice = createSlice({
    name: 'lms',
    initialState: {
        lms_username_password_list: {},
       
    },
    reducers: {
        LMS_UsernamePasswordStudentList: (state, action) => {
            state.lms_username_password_list = action.payload;
        },
        
    }
});

export const { LMS_UsernamePasswordStudentList } = emis_rptSlice.actions
export default emis_rptSlice.reducer