import React, { Fragment } from 'react';
import { FaListUl } from 'react-icons/fa6';
import { EditPenBtn } from '../../../widgets/AppBtn';
import { NextBtn, PrevBtn } from '../../../widgets/AppBtn';
import ComponentHeading from '../../../widgets/ComponentHeading';
import Container from '../../../widgets/Container';

const ItemListView = ({ onProductList, FetchProductCategoryList, setOnProductEditID }) => {
    return (
        <Fragment>
            <Container>
                <ComponentHeading icon={FaListUl} label="List Product Item" />
                <div className="relative overflow-x-auto">
                    <div className="relative overflow-x-auto">
                        <table className="w-full text-sm text-left rtl:text-right text-slate700 dark:text-gray-400 whitespace-nowrap">
                            <thead className="text-[12px] font-public-sans px-6 text-slate600 capitalize bg-slate300 border-b border-t dark:border-slate700 dark:bg-slate700 dark:text-slate400">
                                <tr>
                                    <th scope="col" className="px-2 py-1 border border-slate400 dark:border-slate600 w-12">
                                        S.N.
                                    </th>
                                    <th scope="col" className="px-2 py-1 border border-slate400 dark:border-slate600">
                                        Product Name
                                    </th>
                                    <th scope="col" className="px-2 py-1 border border-slate400 dark:border-slate600">
                                        Product Code
                                    </th>
                                    <th scope="col" className="px-2 py-1 border border-slate400 dark:border-slate600">
                                        Category
                                    </th>
                                    <th scope="col" className="px-2 py-1 border border-slate400 dark:border-slate600">
                                        Purchase Unit
                                    </th>
                                    <th scope="col" className="px-2 py-1 border border-slate400 dark:border-slate600">
                                        Sales Unit
                                    </th>
                                    <th scope="col" className="px-2 py-1 border border-slate400 dark:border-slate600">
                                        Stock Limit QTY
                                    </th>
                                    <th scope="col" className="px-2 py-1 border border-slate400 dark:border-slate600">
                                        Purchase Price
                                    </th>
                                    <th scope="col" className="px-2 py-1 border border-slate400 dark:border-slate600">
                                        Sales Price
                                    </th>
                                    <th scope="col" className="px-2 py-1 border border-slate400 dark:border-slate600">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Array.isArray(onProductList?.results) && onProductList?.results?.map((product, index) => (
                                        <tr key={index} className="bg-white font-normal text-gray600 text-[12px] border-b font-public-sans dark:bg-slate800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <td align='center' className="px-2 py-0.5 border border-slate300 dark:border-gray-700">
                                                {index + 1}.                      
                                            </td>
                                            <td className="px-2 py-0.5 border font-medium border-slate300 dark:border-slate700">
                                                {product?.product_name}
                                            </td>
                                            <td className="px-2 py-0.5 border border-slate300 dark:border-slate700">
                                                {product?.char_code}
                                            </td>
                                            <td className="px-2 py-0.5 border border-slate300 dark:border-slate700">
                                                {product?.product_sub_master}
                                            </td>
                                            <td className="px-2 py-0.5 border border-slate300 dark:border-slate700">
                                                {product?.purchase_unit}
                                            </td>
                                            <td className="px-2 py-0.5 border border-slate300 dark:border-slate700">
                                                {product?.sales_unit}
                                            </td>
                                            <td className="px-2 py-0.5 border border-slate300 dark:border-slate700">
                                                {product?.stock_lim_qty}
                                            </td>
                                            <td className="px-2 py-0.5 border border-slate300 dark:border-slate700">
                                                {product?.purchase_rate}
                                            </td>
                                            <td className="px-2 py-0.5 border border-slate300 dark:border-slate700">
                                                {product?.sales_rate}
                                            </td>
                                            <td className="px-2 py-0.5 border border-slate300 dark:border-slate700">
                                                <EditPenBtn wh={6} onClick={async () => {
                                                    await setOnProductEditID(product?.product_id);
                                                }} />
                                            </td>
                                        </tr>

                                    ))
                                }

                            </tbody>
                        </table>
                    </div>

                </div>
            </Container>
            <div className='flex justify-end gap-3 mt-4'>
                <PrevBtn disabled={onProductList?.previous === null ? true : false} onClick={() => {
                    if (onProductList?.previous) {
                        FetchProductCategoryList(onProductList?.previous)
                    }
                }} />
                <NextBtn disabled={onProductList?.next === null ? true : false} onClick={() => {
                    if (onProductList?.next) {
                        FetchProductCategoryList(onProductList?.next)
                    }
                }} />
            </div>
        </Fragment>
    )
}

export default ItemListView