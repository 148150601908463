import React, { Fragment, useState } from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { Button } from "@material-tailwind/react";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import NPFlag from "../../assets/img/icon/np.png";
import USAFlag from "../../assets/img/icon/usa.png";
import { FaLock } from "react-icons/fa";
import { IoMdHelpCircle } from "react-icons/io";
import { IoLogOutSharp } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { useLanguage } from "../../widgets/LangContext";
import DarkTheme from "../../widgets/DarkTheme";
import { useNavigate } from "react-router-dom";
import PasswordChangeModel from "./widgets/password/PasswordChangeModel";
import { useSelector } from "react-redux";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { GrPowerReset } from "react-icons/gr";

const AppHeader = ({ onstructureName, setIsOpen }) => {
  const navigate = useNavigate();

  const fullname = localStorage.getItem("fullname") || "";
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };
  const { menu_list } = useSelector((state) => state?.software);
  const [colorTheme, toggleTheme] = DarkTheme();
  const [darkSide, setDarkSide] = useState(colorTheme === "dark");
  const toggleDarkMode = () => {
    toggleTheme();
    setDarkSide((prevDarkSide) => !prevDarkSide);
  };
  const { language, changeLanguage } = useLanguage();
  const handleLanguageChange = () => {
    changeLanguage((language) => !language);
    language === true
      ? localStorage.setItem("Lang", "Nep")
      : localStorage.setItem("Lang", "Eng");
  };

  const [changePassword, setOnChangePassword] = useState(false);
  return (
    <Fragment>
      <PasswordChangeModel
        setOnChangePassword={setOnChangePassword}
        changePassword={changePassword}
      />
      <div className="sticky top-0 left-0 z-50 bg-slate100 border-t flex justify-between px-7 pt-2 flex-row border-slate200 dark:bg-slate900 dark:border-slate600">
        <h3 className="font-semibold text-green-600 my-auto font-public-sans">
          {menu_list?.np_date}
        </h3>

        <div className="flex">
          <div className="relative me-3">
            <div
              onClick={() => setIsOpen(true)}
              className="relative inline-flex items-center justify-center w-7 h-7 rounded-full bg-slate200 cursor-pointer dark:bg-slate600"
            >
              <span className="font-sm text-slate800 border-slate700 dark:text-slate300">
                <MdOutlineNotificationsActive />
              </span>
              {menu_list?.total_notice > 0 && (
                <span className="absolute text-[10px] -top-1 -right-1 inline-flex items-center justify-center h-3 px-1 font-light text-white bg-red-600 rounded-full">
                  {menu_list?.total_notice}
                </span>
              )}
            </div>
          </div>

          <div className="me-3">
            <div className="relative inline-flex items-center justify-center w-7 h-7 overflow-hidden rounded-full bg-slate200 cursor-pointer dark:bg-slate600">
              <span className="font-sm text-slate800 border-slate700 dark:text-slate300">
                {" "}
                <DarkModeSwitch
                  checked={darkSide}
                  onChange={toggleDarkMode}
                  size={15}
                />
              </span>
            </div>
          </div>
          <div className="relative text-left hidden sm:block">
            <div className="relative me-5">
              <div
                onClick={handleLanguageChange}
                className="relative inline-flex select-none items-center justify-center w-7 h-7 overflow-hidden rounded-full bg-slate200 cursor-pointer dark:bg-slate600"
              >
                {language ? (
                  <img src={NPFlag} width={15} alt="Nepali Flag" />
                ) : (
                  <img src={USAFlag} height={15} width={15} alt="Nepali Flag" />
                )}
              </div>
            </div>
          </div>
          <Menu placement="bottom-end">
            <MenuHandler>
              <Button className="p-0 bg-transparent shadow-none capitalize font-public-sans text-md text-slate600 dark:text-slate400 -mt-1 hover:shadow-none">
                <h6 className="underline underline-offset-2 flex gap-2 text-green-600">{fullname}</h6>
              </Button>
            </MenuHandler>
            <MenuList className="px-1 dark:bg-slate800 border dark:border-slate700 ">
            <MenuItem
                className="py-2"
                onClick={() => setOnChangePassword(true)}
              >
                <div className="flex gap-2">
                  <GrPowerReset className="my-auto size-4 text-teal-700" />
                  <span className="font-public-sans my-auto text-[12px]">
                    Reset Password
                  </span>
                </div>
              </MenuItem>
              <MenuItem
                className="py-2"
                onClick={() => setOnChangePassword(true)}
              >
                <div className="flex gap-2 ">
                  <FaLock className="my-auto text-yellow-500" />
                  <span className="font-public-sans my-auto text-[12px]">
                    Change Password
                  </span>
                </div>
              </MenuItem>
              <MenuItem className="py-1.5">
                <div className="flex gap-2 ">
                  <CgProfile className="my-auto text-[18px] text-green-500" />
                  <span className="text-[12px] font-public-sans my-auto">
                    Profile
                  </span>
                </div>
              </MenuItem>
              <MenuItem className="py-1.5">
                <div className="flex gap-2 ">
                  <IoMdHelpCircle className="my-auto text-[18px] text-blue-500" />
                  <span className="text-[12px] font-public-sans my-auto">
                    Help
                  </span>
                </div>
              </MenuItem>
              <div className="mt-2 border-b dark:border-b-slate700"></div>
              <MenuItem onClick={logout} className="py-1.5 mt-2">
                <div className="flex gap-2 ">
                  <IoLogOutSharp className="my-auto text-[18px] text-red-500" />
                  <span className="text-[12px] font-public-sans my-auto">
                    Logout
                  </span>
                </div>
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>
    </Fragment>
  );
};

export default AppHeader;
