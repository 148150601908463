import React, { Fragment } from 'react'
import { InputMask } from "primereact/inputmask";

const InputNumberBox = ({ label, name, handleChange, value, handleBlur, required, mask, placeholder, disabled,touched, errors }) => {
 
  return (
    <Fragment>
      <div className='block'>
        {label ? <div className='flex flex-row'><label className="block text-[12px] font-medium text-slate700 dark:text-gray-200 mb-1 font-public-sans" htmlFor={name}>{label}</label>{required ? <span className='text-red-500 pl-2'>*</span> : ""}</div> : ""}
        <InputMask onChange={handleChange} onBlur={handleBlur}  className={`px-3 py-[5px] text-[12px] w-full !ring-gray-400 ring-[1px] ${disabled?"dark:bg-slate200":"bg-white"} rounded-[3px] placeholder:text-[12px] focus:!ring-green-600 focus:ring-[2px] focus:!outline-none font-public-sans ${errors ||( errors && touched )? "!ring-red-600" : ""}`} value={value} name={name} mask={mask} placeholder={placeholder} required={required} disabled={disabled} autoComplete='off' />

        {errors && <div className='text-red-500 text-[12px] mt-0.5 font-public-sans'>{errors}</div>}

      </div>
    </Fragment>
  )
}

export default InputNumberBox