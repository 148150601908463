import { createSlice } from '@reduxjs/toolkit';

export const accountSlice = createSlice({
    name: 'accounts',
    initialState: {
        general_ledger_list: {},
        sub_ledg_list: {},
        particular_list: {},
        contra_voucher_list: [],
        ContraVoucherInfo: {},
        voucher_list_rpt: [],
        std_fee_duesing_ledger: {},
        billing_list: {},
        student_billing_details: {},
        account_day_book_ii_list: {},
        account_day_book_ii_date:{},
        account_transaction_rpt_list:{},
        account_rpt_date_list: {},
        account_rpt_from_to_date_list: {},
        account_trial_balance_rpt_list: [],
        account_voucher_rpt_list_view:[]
    },
    reducers: {
        GL_Ledger_List: (state, action) => {
            state.general_ledger_list = action.payload;
        },
        Sub_Ledger_List: (state, action) => {
            state.sub_ledg_list = action.payload;
        },
        Particular_List_AC: (state, action) => {
            state.particular_list = action.payload;
        },
        ContraVoucherInfo: (state, action) => {
            state.contra_voucher_info = action.payload;
        },
        ContraVoucherLedgerList: (state, action) => {
            const newData = action.payload;
            state.contra_voucher_list = [...state.contra_voucher_list, newData];
        },
        VoucherListViewReport: (state, action) => {
            state.voucher_list_rpt = action.payload;
        },
        StdFeeDuesingListView: (state, action) => {
            state.std_fee_duesing_ledger = action.payload
        },
        BillingListView: (state, action) => {
            state.billing_list = action.payload
        },
        StudentBillingDetails: (state, action) => {
            state.student_billing_details = action.payload
        },
        resetContraVoucherList: (state) => {
            state.contra_voucher_list = []; // Reset to an empty array
            state.contra_voucher_info = []
        },
        Account_ledger_Report_Select_: (state, action) => {
            state.account_ledger_report_select = action.payload
        },
        Account_Day_BookII_ListView: (state, action) => {
            state.account_day_book_ii_list = action.payload
        },
        Account_Day_BookII_Date: (state, action) => {
            state.account_day_book_ii_date = action.payload
        },
        Account_Transaction_report_list: (state, action) => {
            state.account_transaction_rpt_list = action.payload
        },
        Account_Report_From_To_Date: (state, action) => {
            state.account_rpt_from_to_date_list = action.payload
        },
        AccountTrialBalanceRptListView:(state, action)=>{
            state.account_trial_balance_rpt_list = action.payload;
        },
        AccountDateListRptListView:(state, action)=>{
            state.account_rpt_date_list = action.payload;
        },
        AccountVoucherRptListView:(state, action)=>{
            state.account_voucher_rpt_list_view = action.payload;
        }, 
        AccountAdvancedVoucherRptListView:(state, action)=>{
            state.account_advanced_voucher_rpt_list = action.payload;
        }
    }
});

export const { GL_Ledger_List, AccountDateListRptListView, AccountAdvancedVoucherRptListView, AccountVoucherRptListView, VoucherListViewReport, AccountTrialBalanceRptListView, Account_Report_From_To_Date, Account_Transaction_report_list, Account_Day_BookII_Date, Account_Day_BookII_ListView, Account_ledger_Report_Select_, StudentBillingDetails, resetContraVoucherList, StdFeeDuesingListView, BillingListView, ContraVoucherLedgerList, ContraVoucherInfo, Sub_Ledger_List, Particular_List_AC } = accountSlice.actions
export default accountSlice.reducer