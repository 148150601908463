import React, { Fragment } from 'react'
import IssueRptForm from '../../../../components/inventory/issue_rpt/IssueRptForm'

const IssueReportFormView = () => {
  return (
    <Fragment>
      <IssueRptForm />
    </Fragment>
  )
}

export default IssueReportFormView