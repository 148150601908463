import React, { Fragment, useEffect, useState } from 'react';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import SearchInfoFormRptView from '../../../../components/library/report/search_and_info_rpt/SearchInfoFormRptView';

const LibrarySearchAndInfoFormView = () => {
    const [onLibraryOptions, setOnLibraryOptions] = useState([]);
    const fetchLibrarySearchRptList = async()=>{
        try{
            await fetchDataFromApi(AppURL.LibraryURL+"library-book-report-search-options-list-view/").then((res)=>{
                setOnLibraryOptions(res);
            });
        }catch(error){
            console.log(error);
        }
    }
    useEffect(()=>{
        fetchLibrarySearchRptList();
    },[])
    return (
        <Fragment>
            <SearchInfoFormRptView onLibraryOptions={onLibraryOptions} />
        </Fragment>
    )
}

export default LibrarySearchAndInfoFormView