import React, { Fragment } from 'react'
import Select from "react-select";
import { SelectErrorTheme, SelectSuccessTheme } from './ThemeSelectBox';
import { IoAddCircle } from 'react-icons/io5';
import { IoSearchSharp } from "react-icons/io5";

const SelectOptionBox = ({ name, isLoading, required, clear,menuIsOpen, menuPlacement, disabled, add_btn, onClick, focus,search_btn,
    label, placeholder,
    value,
    optionSelect,
    handleBlur,
    touched,
    errors, options }) => {

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: "silver",
            border: state.isFocused ? "2px solid green" : errors || (errors && touched)?"1.5px solid red": "1px solid silver",

            height: "30px",
            fontFamily: 'Public Sans, sans-serif',
            minHeight: "30px",
            boxShadow: state.isFocused ? null : null,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: "30px",
            bottom: "3.5px",
        }),
        input: (provided, state) => ({
            ...provided,
            fontSize: "14px",
            fontWeight: 400,
            fontFamily: 'Public Sans, sans-serif'
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            marginTop: "0px",
            fontSize: '12px', // Adjust the font size as needed
            fontFamily: 'Public Sans, sans-serif',
            color: "gray",
            opacity: ".5",
            userSelect: "none",
            overflow:"hidden"

        }),
        option: (provided, state) => ({
            ...provided,
            zIndex: "99999",
            position: "sticky",
            paddingLeft: '10px', // Adjust the padding as needed
            paddingRight: '6px', // Adjust the padding as needed
            paddingTop: '6px', // Adjust the padding as needed
            paddingBottom: '6px', // Adjust the padding as needed
            fontSize: '12px', // Adjust the font size as needed
            fontFamily: 'Public Sans, sans-serif',

        }),
    };
    return (
        <Fragment>
            {label ? <div className='flex flex-row'><label className="block text-[12px] font-medium text-gray700 mb-1 dark:text-gray-200 font-public-sans" htmlFor={name}>{label}</label>{!add_btn && !search_btn &&required ? <span className='text-red-500 pl-2'>*</span> : ""} {add_btn?<IoAddCircle onClick={onClick} fontSize={20} className='text-customPrimary pl-1 cursor-pointer' />:""} {search_btn?<IoSearchSharp onClick={onClick} fontSize={20} className='text-customPrimary pl-1 cursor-pointer' />:""}</div> : ""}
            <Select
                options={options}
                menuIsOpen={menuIsOpen}
                openMenuOnFocus={focus}
                isClearable={clear}
                onChange={(selectedOption) => {
                    optionSelect(name, selectedOption); // Use handleYearChange directly
                }}
                theme={
                   errors ||(errors &&touched)
                        ? SelectErrorTheme
                        : SelectSuccessTheme
                }
                value={value}
                menuPlacement={menuPlacement||'bottom'}
                isSearchable={true}
                isDisabled={disabled || false}
                isLoading={isLoading||false}
                className="text-[13px] mt-0"
                handleBlur={handleBlur}
                styles={{
                    ...customStyles,
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), 
                }}
                menuPortalTarget={document.body} 
                placeholder={placeholder || ""} required={required} />
            {errors &&
                <div className='text-red-500 text-[12px] font-public-sans'>{errors}
                </div>}
        </Fragment>


    )
}

export default SelectOptionBox