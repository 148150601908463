import { useState, useEffect } from "react";

const LocationComponent = ({location, setLocation}) => {
  const [error, setError] = useState(null);
  
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setError("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              setError("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              setError("The request to get user location timed out.");
              break;
            default:
              setError("An unknown error occurred.");
          }
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <div className="text-[12px] z-10 fixed font-public-sans text-transparent w-full text-center">
      {location ? (
        <p>Latitude: <i className="font-light">{location.latitude}</i>, Longitude: <i className="font-light">{location.longitude}</i></p>
      ) : (
        <p>{error || "Requesting location..."}</p>
      )}
    </div>
  );
};

export default LocationComponent;
